import { TextField as TF } from '@mui/material';

interface Props {
  label: string;
  type?: string;
  required?: boolean;
  readOnly?: boolean;
  value: string;
  multiline?: boolean;
  rows?: number;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  error?: boolean;
  helperText?: string;
}

const TextField = ({ label, required, type, value, onChange, multiline, rows, readOnly, error, helperText }: Props) => {
  return (
    <TF
      margin="normal"
      label={label}
      type={type}
      required={required}
      value={value}
      onChange={onChange}
      disabled={readOnly}
      fullWidth
      multiline={multiline}
      rows={rows}
      error={error}
      helperText={helperText}
    />
  );
};

export default TextField;
