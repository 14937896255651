import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import i18n from './i18n';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material';

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
const theme = createTheme({
  palette: {
    primary: {
      light: '#FFFAF8',
      main: '#305769',
      dark: '#182c35',
      contrastText: '#fff',
    },
    secondary: {
      light: '#757ce8',
      main: '#F2DAB6',
      dark: '#002884',
      contrastText: '#fff',
    },
  },
});
i18n();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
