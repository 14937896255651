import { useState } from 'react';
import OrderFileService from './order-files.service';
import { BtnDanger, BtnPrimary, Gallery } from '../common/ui-component';
import { useTranslation } from 'react-i18next';
import { DropzoneArea } from 'react-mui-dropzone';
import { isSuccess } from '../utils/remote-data';
import { OrderFileType } from './order-file-type.type';

interface Props {
  customerId: number;
  type: OrderFileType;
  onSuccess: (files: MyFile[]) => void;
  onCancel: () => void;
}

export interface MyFile {
  title: string;
  file: File;
  url: string;
}

const UploadOrderFiles = ({ customerId, type, onSuccess, onCancel }: Props) => {
  const [uploadPhotos] = OrderFileService.useUploadPicturesByCustomer();
  const [triedFiles, setTriedFiles] = useState<
    {
      title: string;
      file: File;
      url: string;
    }[]
  >([]);

  const { t } = useTranslation('order');

  const handlePhotos = (files: File | File[]) => {
    if (Array.isArray(files)) {
      setTriedFiles(
        files.map((x) => ({
          title: x.name,
          file: x,
          url: URL.createObjectURL(x),
        })),
      );
    } else {
      setTriedFiles([{ title: files.name, file: files, url: URL.createObjectURL(files) }]);
    }
  };

  const handleSendClick = async () => {
    const result = await uploadPhotos({
      customerId: customerId,
      type: type,
      files: triedFiles.map((x) => x.file),
    });

    if (isSuccess(result)) {
      onSuccess(triedFiles);
    }
  };

  return (
    <>
      <DropzoneArea onChange={handlePhotos} showPreviews={false} showPreviewsInDropzone={false} />
      <div style={{ marginTop: '1rem' }}>
        <Gallery photos={triedFiles} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          paddingTop: '1rem',
        }}
      >
        <BtnDanger onClick={onCancel}>{t('close')}</BtnDanger>
        <BtnPrimary sx={{ marginLeft: '1rem' }} onClick={handleSendClick}>
          {t('send')}
        </BtnPrimary>
      </div>
    </>
  );
};

export default UploadOrderFiles;
