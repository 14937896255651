import { RemoteData } from '../../../utils/remote-data';
import RemoteDataContent from '../layouts/RemoteDataContent';
import SuccessToast from './SuccessToast';

type Props<T> = { value: RemoteData<T, unknown>; onClick: (v: T) => void };

const SuccessRemoteDataToast = <T,>({ value, onClick }: Props<T>) => (
  <RemoteDataContent<T>
    value={value}
    whenSuccess={(v) => <SuccessToast value={v} onClick={() => onClick(v)} />}
    whenNotAsked={() => <></>}
  />
);

export default SuccessRemoteDataToast;
