import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

interface Props<T> {
  value: T;
  label: string;
  toOption: (t: T) => { id: string | number; value: T; displayText: string };
  items: T[];
  onChange: (t: T | undefined) => void;
  readonly?: boolean;
  required?: boolean;
}

const DropdownField = <T,>({ value, label, toOption, items, onChange, readonly, required }: Props<T>) => {
  const handleChange = (
    event:
      | (Event & {
          target: {
            value: string | number | readonly string[] | undefined;
            name: string;
          };
        })
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selected = items.find((item) => toOption(item).id === event.target.value);

    onChange(selected);
  };

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel required={required} id="demo-simple-select-label">
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value || value === 0 ? toOption(value).id : ''}
        label={label}
        onChange={handleChange}
        readOnly={readonly}
        disabled={readonly}
        required={required}
      >
        {items.map((option: T) => {
          const opt = toOption(option);
          return (
            <MenuItem key={opt.id} value={opt.id}>
              {opt.displayText}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default DropdownField;
