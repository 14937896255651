import axios from 'axios';
import useService from '../common/useService';
import { AuthPayload } from '../authentication';

const useGet2faQrCode = () => useService(() => axios.post<string>(`/2fa/generate`));

const useValidate2faCode = () => useService((body) => axios.post<AuthPayload>(`/2fa/authenticate`, body));

const useTurnOn2fa = () => useService((body) => axios.post(`/2fa/turn-on`, body));

const AuthService = {
  useGet2faQrCode,
  useValidate2faCode,
  useTurnOn2fa,
};

export default AuthService;
