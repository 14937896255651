const LOGIN_EN = {
  email: 'Email',
  logout: 'Logout',
  password: 'Password',
  signIn: 'Sign in',
  successLogin: 'Successful login, you will be redirected soon',
  secondFactorTitle: '2FA QrCode',
  secondFactorCodeTitle: '2FA Code',
  secondFactorText: 'Scan this QRCode with the Google Authenticator App and then click the next button',
  twoFactorAuthenticationCode: 'Enter code',
  next: 'Next',
  back: 'Back',
};

export default LOGIN_EN;
