import { jwtDecode } from 'jwt-decode';
import { TokenPayload } from '../login/token-payload.model';

const hasExpired = (token: string | null): boolean =>
  !!token && jwtDecode<{ exp: number }>(token).exp < Math.round(new Date().getTime() / 1000);

const isTwoFactorAuthenticated = (token: string | null): boolean =>
  !!token && jwtDecode<{ isTwoFactorAuthenticated: boolean }>(token).isTwoFactorAuthenticated;

const decode = (token: string | null): TokenPayload | false => !!token && jwtDecode(token);

const JwtTokenHelper = {
  hasExpired,
  isTwoFactorAuthenticated,
  decode,
};

export default JwtTokenHelper;
