const EMAIL_TRACKING_EN = {
  close: 'Close',
  emailTracking: 'Email Tracking',
  orderId: 'OrderId',
  data: 'Data',
  trackingNumber: 'Tracking Number',
  version: 'Version',
  created: 'created',
  pharmacyName: 'Pharmacy',
};

export default EMAIL_TRACKING_EN;
