import { Table as MTable, TableRow as MTableRow } from '@mui/material';
export { TableBody, TableCell, TableContainer, TableHead } from '@mui/material';

export const Table = ({ children }: { children: React.ReactElement | React.ReactElement[] }) => {
  return <MTable sx={{ minWidth: 650 }}>{children}</MTable>;
};

export const TableRow = ({ children }: { children: React.ReactElement | React.ReactElement[] }) => (
  <MTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>{children}</MTableRow>
);
