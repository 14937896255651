import axios from 'axios';
import { Paginated } from '../common/Paginated';
import { PageProps } from '../common/ui-component/data-table';
import useService from '../common/useService';
import { trackingEmailEntity } from './tracking.email.model';

const useGetEmails = () =>
  useService((params: PageProps) =>
    axios.get<Paginated<trackingEmailEntity>>(`/tracking-email`, {
      params,
    }),
  );
const useSendEmail = () =>
  useService(
    ({ orderId, version, pharmacyName }: { orderId: number | number; version: number; pharmacyName: string }) =>
      axios.post<Paginated<trackingEmailEntity>>(`/tracking-email/${orderId}/${version}/${pharmacyName}/send`),
  );

const TrackingEmailService = {
  useGetEmails,
  useSendEmail,
};

export default TrackingEmailService;
