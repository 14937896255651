import { Divider, Grid, Paper, Box } from '@mui/material';
import { Subtitle } from '../typography';
import SectionContent from './SectionContent';

const Sub = ({
  title,
  children,
}: {
  title?: string | undefined;
  children: JSX.Element | JSX.Element[] | string | undefined;
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <SectionContent>
        <Subtitle>{title}</Subtitle>
      </SectionContent>
      <Grid container rowSpacing={1} sx={{ pt: 2 }}>
        {children}
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
    </Box>
  );
};

const Actions = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>{children}</Box>;
};

const Section = ({
  title,
  actions,
  children,
}: {
  title: string | JSX.Element;
  actions?: JSX.Element;
  children: JSX.Element | JSX.Element[] | string | undefined;
}) => (
  <Paper sx={{ padding: 2, marginBottom: 4 }} elevation={2}>
    <Grid container>
      <Grid item xs={6}>
        {typeof title === 'string' ? <Subtitle>{title}</Subtitle> : title}
      </Grid>
      <Grid item xs={6}>
        {actions ? actions : undefined}
      </Grid>
    </Grid>
    <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
    <Grid container rowSpacing={1}>
      {children}
    </Grid>
  </Paper>
);

Section.Actions = Actions;
Section.Sub = Sub;

export default Section;
