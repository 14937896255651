import { Popover } from '@mui/material';
import React, { Children, useState } from 'react';

const Multiple = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <div>
      {Children.map(children, (c) => (
        <div>{c}</div>
      ))}
    </div>
  );
};

const Conditional = ({ children, predicate }: { children: JSX.Element | string; predicate: boolean }): JSX.Element => {
  if (predicate) return <>{children}</>;
  return <></>;
};

const Tooltips = ({
  children,
  value,
}: {
  children: JSX.Element;
  value: string | JSX.Element | JSX.Element[] | undefined;
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };
  return (
    <>
      {React.cloneElement(<span>{children}</span>, {
        onMouseEnter: handlePopoverOpen,
        onMouseLeave: handlePopoverClose,
      })}
      {value && (
        <Popover
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div style={{ padding: '5px' }}>{value}</div>
        </Popover>
      )}
    </>
  );
};

Tooltips.Multiple = Multiple;
Tooltips.Conditional = Conditional;

export default Tooltips;
