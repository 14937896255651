import { Box } from '@mui/material';
import { DateHelper } from '../../../../utils/date';
import Result from '../../../../utils/result/result';
import Link from '../../Link';
import { Label, Paragraph } from '../../typography';
import Gender from '../Gender';
import YesNo from '../YesNo';
import { ReactNode, Children } from 'react';
import { DateTime } from 'luxon';

interface Props {
  label: string | JSX.Element | JSX.Element[];
  children: string | number | JSX.Element | JSX.Element[];
  underlineLabel?: boolean;
}
export const Info = ({ label, children, underlineLabel }: Props) => (
  <Box
    sx={{
      'a:not(:first-of-type)': {
        borderLeft: '1px solid #000',
        paddingLeft: 1,
      },
      a: { paddingRight: 1 },
    }}
  >
    <Label underline={underlineLabel}>
      <>{label}</>
    </Label>
    {['string', 'number'].includes(typeof children) ? <Paragraph>{children || '-'}</Paragraph> : children || '-'}
  </Box>
);

type GenderProps = {
  children: number;
};

const GenderInfo = ({ children }: GenderProps) => (
  <Paragraph>
    <Gender>{children}</Gender>
  </Paragraph>
);

type YesNoProps = {
  children: boolean;
};

const YesNoInfo = ({ children }: YesNoProps) => (
  <Paragraph>
    <YesNo>{children}</YesNo>
  </Paragraph>
);

type PharmacyProps = {
  children: number | null;
};

const Pharmacy = ({ children }: PharmacyProps) => {
  const chemistryCode = process.env.REACT_APP_CHEMISTRYRX_PHARMACY_ID;
  const curexaCode = process.env.REACT_APP_CUREXA_PHARMACY_ID;
  const pharmacy = () => {
    switch (children?.toString()) {
      case curexaCode:
        return 'Curexa';
      case chemistryCode:
        return 'Chemistry';
      default:
        return '--';
    }
  };
  return <Paragraph>{pharmacy()}</Paragraph>;
};

type DateTimeProps = {
  children: string;
};

const DateTimeInfo = ({ children }: DateTimeProps) => {
  return (
    <Paragraph>
      {Result.fold<string, string, string>(
        (x: string) => x,
        (y: string) => '--',
      )(DateHelper.dateTime(children))}
    </Paragraph>
  );
};

type EmailProps = {
  children: string;
};

const Email = ({ children }: EmailProps) => {
  return (
    <Link href={`mailto:${children}`}>
      <Paragraph>{children}</Paragraph>
    </Link>
  );
};

const Phone = ({ children }: EmailProps) => {
  return (
    <Link href={`tel:${children}`}>
      <Paragraph>{children}</Paragraph>
    </Link>
  );
};

const MultipleLine = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>{children}</Box>;
};

const isTrue = (node: ReactNode) => {
  if (typeof node === 'boolean') return false;
  if (typeof node === 'number') return false;
  if (typeof node === 'string') return false;
  if (node && 'type' in node) {
    return node.type === True;
  }

  return false;
};

const isFalse = (node: ReactNode) => {
  if (typeof node === 'boolean') return false;
  if (typeof node === 'number') return false;
  if (typeof node === 'string') return false;
  if (node && 'type' in node) {
    return node.type === False;
  }

  return false;
};

const True = ({ children }: { children: JSX.Element | JSX.Element[] }) => <>{children}</>;
const False = ({ children }: { children: JSX.Element | JSX.Element[] }) => <>{children}</>;

const Conditional = ({ conditional, children }: { conditional: boolean; children: JSX.Element | JSX.Element[] }) => {
  const true_ = Children.toArray(children).filter(isTrue)[0];
  const false_ = Children.toArray(children).filter(isFalse)[0];
  return <>{conditional ? true_ : false_}</>;
};

const DaysRemaining = ({ from, to }: { from: DateTime; to: DateTime }) => {
  const diff = from.plus({ years: 1 }).diff(to, 'days').toObject().days;
  if (!diff) return '--';

  return <>{Math.round(diff).toString()}</>;
};

const Splitter = ({ children }: { children: JSX.Element | JSX.Element[] }) => (
  <Box
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      'div:not(:last-of-type)': {
        borderRight: '1px solid #000',
        marginRight: 1,
      },
    }}
  >
    {children}
  </Box>
);

const SplitterItem = ({ children }: { children: JSX.Element | JSX.Element[] }) => <div>{children}</div>;

Splitter.Item = SplitterItem;

Conditional.True = True;
Conditional.False = False;

Info.Email = Email;
Info.Phone = Phone;
Info.Gender = GenderInfo;
Info.YesNo = YesNoInfo;
Info.Pharmacy = Pharmacy;
Info.DateTime = DateTimeInfo;
Info.MultipleLine = MultipleLine;
Info.Conditional = Conditional;
Info.DaysRemaining = DaysRemaining;
Info.Splitter = Splitter;

export default Info;
