import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedForm, TextInput } from '../../common/form-controller';
import { map, withDefault } from '../../utils/remote-data';
import { BtnDanger, BtnPrimary, FormRow, FormSection } from '../../common/ui-component';
import SubscriptionsService from '../subscriptions.service';
import { DateTime } from 'luxon';
import DateTimeInput from '../../common/form-controller/DateTimeInput';
import { SubscriptionModel } from '../../models';

interface Props {
  subscription: SubscriptionModel | undefined;
  onSuccess: (subscription: SubscriptionModel) => void;
  close: () => void;
}

const NextPaymentDateForm = ({ subscription, onSuccess, close }: Props) => {
  const { t } = useTranslation(['subscription', 'common']);
  const [updateSubscription, rSubscription] = SubscriptionsService.useUpdateSubscription();

  const onSubmit = useCallback(
    (subscriptionModel: SubscriptionModel, id: number) => {
      updateSubscription({
        id,
        subscription: {
          ...subscriptionModel,
          nextPaymentDate: subscriptionModel.nextPaymentDate
            ? DateTime.fromISO(subscriptionModel.nextPaymentDate).toFormat('yyyy-MM-dd HH:mm:ss')
            : subscriptionModel.nextPaymentDate,
        },
      });
    },
    [updateSubscription],
  );

  useEffect(() => {
    const nothing = () => {};
    const rFn = map((s: SubscriptionModel) => () => onSuccess(s))(rSubscription);
    const fn = withDefault(nothing)(rFn);
    fn();
  }, [rSubscription, onSuccess]);

  return !subscription ? (
    <>
      <p>{t('common:weird')}</p>
      <BtnDanger sx={{ m: 0 }} onClick={close}>
        {t('subscription:close')}
      </BtnDanger>
    </>
  ) : (
    <ConnectedForm<SubscriptionModel>
      defaultValues={{
        id: subscription?.id,
        nextPaymentDate: subscription.nextPaymentDate
          ? DateTime.fromSQL(subscription.nextPaymentDate).toISO()
          : subscription.nextPaymentDate,
      }}
      onSubmit={(formValue) => onSubmit(formValue, subscription.id)}
    >
      <FormSection title={t('subscription:nextPaymentDateDetails')}>
        <FormRow>
          <TextInput readOnly name="id" label={t('subscription:subscriptionId')} />
          <DateTimeInput name="nextPaymentDate" />
        </FormRow>
      </FormSection>
      <BtnPrimary sx={{ m: 0 }} type="submit">
        {t('subscription:send')}
      </BtnPrimary>
    </ConnectedForm>
  );
};

export default NextPaymentDateForm;
