const CUSTOMER_EN = {
  address1: 'Address line 1',
  address2: 'Address line 2',
  avatar: 'Avatar',
  billingAddress: 'Billing Address',
  city: 'City',
  country: 'Country',
  customer: 'Customer',
  customerId: 'Customer Id',
  customers: 'Customers',
  dateCreated: 'Date Created',
  dateCreatedWithTime: 'Date Created (with time)',
  dateOfBirth: 'Date Of Birth',
  details: 'Details',
  editDateOfBirth: 'Edit Date Of Birth',
  email: 'Email',
  est365Rev: 'Estimated 365 revenue',
  firstName: 'First Name',
  firstname: 'FirstName',
  gender: 'Gender',
  id: 'id',
  info: 'Information',
  isPayingCustomer: 'Is Paying Customer',
  lastName: 'Last Name',
  marketing: 'Marketing',
  medicalCaseId: 'Medical case Id',
  missingMarketingData: 'No marketing data for this customer',
  name: 'Name',
  patientId: 'Patient Id',
  phone: 'Phone',
  postcode: 'Postcode',
  segmentScore: 'Segment score',
  shippingAddress: 'Shipping Address',
  startDate: 'Start Date',
  state: 'State',
  status: 'Status',
  subscriptionID: 'Subscription ID',
  subscriptions: 'Subscriptions',
  syncCustomerWithWoo: 'Sync Customer with Woo',
  type: 'Type',
  updated: 'Updated at',
  username: 'Username',
  utmCampaign: 'UTM Campaign',
  utmContent: 'UTM Content',
  utmMedium: 'UTM Medium',
  utmSource: 'UTM Source',
  utmTerm: 'UTM Term',
  wooCommerceId: 'WooCommerce Id',
  woocommerceCreate: 'Woocommerce Created At',
  woocommerceUpdated: 'Woocommerce Updated At',
};

export default CUSTOMER_EN;
