import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Icons } from '../..';
import { default as OriginalLink } from '../../Link';
import { Box } from '../../layouts';

export function Link(path: string) {
  return (params: GridRenderCellParams) => {
    if (!params.value) return <>-</>;
    return (
      <OriginalLink to={`${path}/${params.value}`}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {params.value}
          <Icons.Launch></Icons.Launch>
        </Box>
      </OriginalLink>
    );
  };
}
