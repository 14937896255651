const SEARCH_EN = {
  customer: 'Customer',
  customerId: 'Customer ID',
  woocommerceId: 'WooCommerce',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  orderId: 'Order ID',
  orders: 'Orders',
  search: 'Search',
  searchUsing: 'Search using',
  subscriptionId: 'Subscription ID',
  subscriptions: 'Subscriptions',
  medicalCaseId: 'Medical Case Id',
  medicalCase: 'Medical Case',
};

export default SEARCH_EN;
