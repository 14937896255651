import { DateTime } from 'luxon';

import { err, ok, Result } from '../result/result';

export const _databaseFormat = 'yyyy-MM-dd HH:mm:ss';
export const _databaseDateOnlyFormat = 'yyyy-MM-dd';

const suffix0 = (x: number) => (x <= 9 ? `0${x}` : x);

function convertToUTC(_date: Date) {
  const utcDate = new Date(
    Date.UTC(
      _date.getFullYear(),
      _date.getMonth(),
      _date.getDate(),
      _date.getHours(),
      _date.getMinutes(),
      _date.getSeconds(),
    ),
  );
  return utcDate;
}

const onlyDate = (date: string | Date): Result<string, string> => {
  const format = (_date: Date): Result<string, string> => {
    if (isNaN(_date.getTime())) return err(`Cannot parse the date from ${_date}`);

    _date = convertToUTC(_date);

    const yymmdd = [_date.getFullYear(), _date.getMonth() + 1, _date.getDate()].map(suffix0).join('-');

    if (yymmdd === '') return err(`Cannot parse the date from ${_date}`);

    return ok(yymmdd);
  };

  if (!date || date === '') return err('No date');

  if (date instanceof Date) {
    return format(date);
  } else if (typeof date === 'string') {
    return format(new Date(date));
  }

  return err('date is not instanceof Date');
};

const dateTime = (date: string | Date): Result<string, string> => {
  const format = (_date: Date): Result<string, string> => {
    if (isNaN(_date.getTime())) return err(`Cannot parse the date from ${_date}`);

    const d = _date.toISOString();
    if (d === '') return err(`Cannot parse the date from ${_date}`);

    _date = convertToUTC(_date);

    const yyyyMMdd = [_date.getFullYear(), _date.getMonth() + 1, _date.getDate()].map(suffix0).join('-');

    const hhmmss = [_date.getHours(), _date.getMinutes(), _date.getSeconds()].map(suffix0).join(':');

    return ok(`${yyyyMMdd} ${hhmmss}`);
  };

  if (!date || date === '') return err('No date');

  if (date instanceof Date) {
    return format(date);
  } else if (typeof date === 'string') {
    return format(new Date(date));
  }

  return err('date is not instanceof Date');
};

const onlyDateOfBirth = (date: string | Date): Result<string, string> => {
  const format = (_date: Date): Result<string, string> => {
    if (isNaN(_date.getTime())) return err(`Cannot parse the date from ${_date}`);
    const yymmdd = [_date.getUTCFullYear(), _date.getUTCMonth() + 1, _date.getUTCDate()].map(suffix0).join('-');
    console.log(_date.getTimezoneOffset());
    if (yymmdd === '') return err(`Cannot parse the date from ${_date}`);

    return ok(yymmdd);
  };

  if (!date || date === '') return err('No date');

  if (date instanceof Date) {
    return format(date);
  } else if (typeof date === 'string') {
    return format(new Date(date));
  }

  return err('date is not instanceof Date');
};

export interface DurationObjectUnits {
  years?: number | undefined;
  months?: number | undefined;
  days?: number | undefined;
}

const isOlderThan = (birthdate: DateTime, duration: DurationObjectUnits): boolean => {
  const oneYearBefore = DateTime.now().minus(duration);

  return birthdate <= oneYearBefore.startOf('day');
};

const isFirstAnniversary = (date: DateTime): boolean => {
  const oneYearBefore = DateTime.now().minus({ years: 1 });
  return date >= oneYearBefore.startOf('day') && date <= oneYearBefore.endOf('day');
};

const isOlderThanOneYear = (date: DateTime): boolean => {
  return isOlderThan(date, { years: 1 });
};

/***
 *  Replace the time of passed parameters with the current time
 *
 *  Explanation why we do that.
 *
 *  Because our Datepicker keep time of default value and our server is UTC
 *
 *  We need to do this, to make sure stuff trigering at the right time.
 *
 *  For exemple: TODAY = 2022-01-04T:23:00:00 timezone -8, it's 2022-01-05T:07:00:00 UTC.
 *
 *  So our user want to trigger something for today, he gonna choose the fouth.
 *
 *  that's why the current time it's important. So the date we gonna sent to server will be the UTC one
 */
const trickDatetime = (datetime_: DateTime) => {
  const date = datetime_.toISODate();
  const time = DateTime.now().toISOTime({ includePrefix: true });
  return DateTime.fromISO(`${date}${time}`);
};

const Helper = {
  onlyDate,
  dateTime,
  onlyDateOfBirth,
  isFirstAnniversary,
  isOlderThanOneYear,
  trickDatetime,
};

export default Helper;
