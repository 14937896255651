import { useTranslation } from 'react-i18next';
import { TextInput } from '../form-controller';
import { Form, Section } from '../ui-component';
import SubFormSection from '../ui-component/layouts/form/SubFormSection';
import PhoneInput from '../form-controller/PhoneInput';

interface Props {
  title: string;
  name: string;
}
const FormAddress = ({ name, title }: Props) => {
  const { t } = useTranslation('address');

  return (
    <Section title={title}>
      <SubFormSection>
        <Form.Section title="">
          <Form.Section.Row>
            <TextInput required name={`${name}.address1`} label={t('address_1')} />
            <TextInput name={`${name}.address2`} label={t('address_2')} />
          </Form.Section.Row>
          <Form.Section.Row>
            <TextInput required name={`${name}.city`} label={t('city')} />
            <TextInput required name={`${name}.country`} label={t('country')} />
            <TextInput required name={`${name}.state`} label={t('state')} />
          </Form.Section.Row>
          <Form.Section.Row>
            <TextInput required name={`${name}.postcode`} label={t('postcode')} />
            <PhoneInput required name={`${name}.phone`} label={t('phone')} />
          </Form.Section.Row>
        </Form.Section>
      </SubFormSection>
    </Section>
  );
};

export default FormAddress;
