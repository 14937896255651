import { useTranslation } from 'react-i18next';
import { Info, Section, SectionContent } from '../common/ui-component';
import { CustomerAddressModel } from '../models';

const Address = ({ title, address }: { title: string; address: CustomerAddressModel }) => {
  const { t } = useTranslation('customer');
  return (
    <Section title={title}>
      <SectionContent>
        <Info label={t('address1')}>{address.address1}</Info>
        <Info label={t('address2')}>{address.address2 || ''}</Info>
      </SectionContent>
      <SectionContent>
        <Info label={t('city')}>{address.city}</Info>
        <Info label={t('country')}>{address.country || ''}</Info>
        <Info label={t('state')}>{address.state}</Info>
        <Info label={t('postcode')}>{address.postcode}</Info>
      </SectionContent>
      <SectionContent>
        <Info label={t('phone')}>{address.phone || ''}</Info>
      </SectionContent>
    </Section>
  );
};

export default Address;
