import { Button, ButtonProps, SxProps, Theme } from '@mui/material';
import { MouseEventHandler } from 'react';

interface Props {
  children: React.ReactNode | string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: MouseEventHandler | undefined;
  sx?: SxProps<Theme> | undefined;
  disabled?: boolean;
}

const BtnPrimary = ({ children, type, onClick, sx, disabled, ...rest }: Props & ButtonProps) => {
  return (
    <Button
      sx={sx}
      fullWidth
      type={type || 'button'}
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default BtnPrimary;
