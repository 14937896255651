const MEDICAL_CASE_EN = {
  active: 'Active',
  address1: 'Address 1',
  address2: 'Address 2',
  addressToLong: 'Address is too long',
  annualHealthUpdate: 'Annual Health Update',
  caseId: 'Case Id',
  caseIdList: 'Case Id List',
  caseInformation: 'Case Information',
  caseStatus: 'Case Status',
  city: 'City',
  clinician: 'Clinician',
  completed: 'Completed',
  confirmOrderFileDelete: 'Confirm Delete File {{id}}',
  createCase: 'Create case',
  created: 'Created',
  createdWithTime: 'Created (with time)',
  customer: 'Customer',
  dailySupply: 'Frequency',
  dateOfBirth: 'Date Of Birth',
  daysRemaining: 'Days until new cases',
  description: 'Description',
  driverLicense: 'Driver License',
  editHealthUpdateDueDate: 'Edit Health Update Due Date',
  editLastHufSubmission: 'Edit Last HUF Submission',
  email: 'Email',
  id: 'Id',
  firstName: 'First Name',
  forceNewCase: 'Force Create New Case',
  gender: 'Gender',
  hairPictures: 'Hair Pictures',
  hasCaseId: 'Has case id',
  hasDriverLicenseId: 'Has driver license id',
  hasMedicalQuestionFilled: 'Has Medical Questions Filled',
  hasPatientId: 'Has Patient Id',
  hasPhotos: 'Has Photos',
  hasPrescriptions: 'Has Prescriptions',
  healthUpdateDueDate: 'Health Update Due Date',
  healthUpdateDueDateWithTime: 'Health Update Due Date (with time)',
  history: 'History',
  idRequired: 'ID Required',
  isChargeable: 'Is Chargeable',
  isActive: 'Is Active',
  lastAnnualRenewal: 'Last Annual Renewal',
  lastCaseId: 'Last Case Id',
  lastEncounter: 'Last Encounter',
  lastEncountered: 'Last Encountered Created',
  lastEncounteredWithTime: 'Last Encountered Created (with time)',
  lastHufSubmission: 'Last HUF Submission',
  lastHufSubmissionWithTime: 'Last HUF Submission (with time)',
  lastName: 'Lastname',
  lastPrescriptionSigned: 'Last Prescription Signed',
  lastPrescriptionSignedWithTime: 'Last Prescription Signed (with time)',
  linkedDeadLetters: 'Linked dead letters',
  medicalCase: 'Medical Case',
  medicalCaseErrors: 'Medical case errors',
  medicalCases: 'Medical Cases',
  medicalInformation: 'Medical Information',
  medicalInformationUpdated: 'Medical Information Updated',
  medicalQuestion: 'Medical Question',
  missingAddressData: 'Data in address is missing',
  missingDob: 'Missing Date Of Birth',
  missingDriverLicenseId: 'Missing Driver License Id',
  missingDriverLicensePhoto: 'Missing Driver License Photo',
  missingHairPhotos: 'Missing Hair Photos',
  missingMdiFile: 'Photo not uploaded in MDI',
  missingMedicalInformation: 'Missing Medical Information',
  missingOrders: 'Missing orders',
  missingPatientId: 'Missing patient id',
  missingPhoneNumber: 'Phone number is missing',
  allProductsAreIdle: 'All products are idle',
  newCase: 'New Case',
  noActiveSubscriptions: 'No active subscriptions',
  notIdle: 'Not Idle',
  orderIds: 'Order Ids',
  orderInfo: 'Order Information',
  patientId: 'Patient Id',
  pharmacy: 'Pharmacy',
  phoneNumber: 'Phone Number',
  photos: 'Photos',
  postcode: 'Postcode',
  pregnancy: 'Pregnancy',
  prescriptionInformation: 'Prescription',
  prescriptions: 'Prescriptions',
  products: 'Products',
  proveVerified: 'Prove Verified',
  quantity: 'Qty',
  reasonForEncounter: 'Reason For Encounter',
  save: 'Save',
  sendToCreatedQueue: 'Send to Created Queue',
  signed: 'Signed',
  sku: 'Sku',
  skus: 'Skus',
  state: 'State',
  status: 'Status',
  subscriptionId: 'Sub. Id',
  subscriptionIds: 'Sub. Ids',
  subscriptionStatus: 'Subscription Status',
  syncCustomer: 'Sync Customer',
  syncDriverLicense: 'Sync Driver License',
  syncMedicalCase: 'Sync Medical Case',
  syncMedicalQuestion: 'Sync Medical Question',
  syncOrder: 'Sync Order',
  syncPhotos: 'Sync Photos',
  updated: 'Updated',
  updatedWithTime: 'Updated (with time)',
  value: 'Value',
  videoRequired: 'Video Required',
  casesAllowed: 'Cases Allowed',
  hasActiveSub: 'Has Active Sub',
};

export default MEDICAL_CASE_EN;
