const QUESTIONS_EN = {
  allergies: 'Allergies',
  allergiesDetails: 'Allergies details',
  medications: 'Medications',
  medicationsDetails: 'Medical details',
  medicalProblems: 'Medical problems',
  medicalProblemsDetails: 'Medical problems details',
  pregnant: 'Pregnant',
  pregnantInfo: 'Pregnant info',
  takenFinasteride: 'Taken finasteride',
  takenFinasterideDetails: 'Taken finasteride details',
  takenFinasterideInfo: 'Taken finasteride info',
  topicalMinoxidil: 'Topical minoxidil',
  topicalMinoxidilDetails: 'Topical minoxidil details',
  depressionAnxiety: 'Depression, Anxiety',
  depressionAnxietyDetails: 'Depression Anxiety details',
  kidneyHeartLungs: 'Kidney, Heart, Lungs',
  kidneyHeartLungsDetails: 'Kidney, Heart, Lungs details',
  sexualPerformance: 'Sexual performace',
  sexualPerformanceDetails: 'Sexual performace details',
  additionalHistory: 'Additional history',
  howDidYouHearAboutUs: 'How did you hear about us',
};

export default QUESTIONS_EN;
