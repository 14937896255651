import { getGridStringOperators } from '@mui/x-data-grid';
import { getGridBooleanOperators } from '@mui/x-data-grid';

const getGridArrayHasValueOperators = () => [
  {
    ...getGridBooleanOperators()[0],
    label: 'is',
    value: 'arrayContains',
  },
];

const getGridArrayOperators = () => [
  {
    ...getGridStringOperators()[0],
    label: 'contains',
    value: 'contains',
  },
];

const getGridNullOperators = () => [
  {
    ...getGridBooleanOperators()[0],
    label: 'is',
    value: 'null',
  },
];

const getGridObjectOperators = () => [
  {
    ...getGridBooleanOperators()[0],
    label: 'is',
    value: 'object',
  },
];

const FilterOperator = {
  getGridObjectOperators,
  getGridNullOperators,
  getGridArrayHasValueOperators,
  getGridArrayOperators,
};

export default FilterOperator;
