import axios from 'axios';
import { SearchParams } from '../common/ui-component/data-table';
import useService from '../common/useService';
import { OrderStatus } from './order-status.model';

export interface OrderStatusStats {
  total: number;
}

const useGetStats = () =>
  useService((params: SearchParams) => axios.get<OrderStatusStats[]>(`/order-status/stats`, { params }));

const useGetOrderStatuses = () => useService((id: string) => axios.get<OrderStatus[]>(`/order-status/${id}`));

const OrdersService = {
  useGetStats,
  useGetOrderStatuses,
};

export default OrdersService;
