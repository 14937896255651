import axios from 'axios';
import useService from '../common/useService';
import { ConversionCustomerModel } from '../models';

const useGetConversionCustomer = () =>
  useService((id: string) =>
    axios.get<ConversionCustomerModel>(`${process.env.REACT_APP_ANALYTICS_URL}/conversion-customer/${id}`),
  );

const CustomerAnalyticsService = {
  useGetConversionCustomer,
};

export default CustomerAnalyticsService;
