import axios from 'axios';
import useService from '../../common/useService';
import { SyncDeadLetterResponse } from './sync-dead-letter.model';

const useSyncCase = () => useService(() => axios.post<SyncDeadLetterResponse>(`sync/case`));
const useSyncPatient = () => useService(() => axios.post<SyncDeadLetterResponse>(`sync/patient`));

const SyncDeadLetterService = {
  useSyncCase,
  useSyncPatient,
};

export default SyncDeadLetterService;
