export default function Bomb() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="24"
      height="24"
      viewBox="0 0 1280.000000 774.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>Created by potrace 1.15, written by Peter Selinger 2001-2017</metadata>
      <g transform="translate(0.000000,774.000000) scale(0.100000,-0.100000)" fill="#FFFFFF" stroke="none">
        {/* eslint-disable-next-line max-len */}
        <path d="M7645 7729 c-465 -62 -852 -258 -1062 -537 -32 -42 -71 -102 -87 -133 l-30 -57 -95 75 c-238 185 -521 374 -634 422 l-47 20 -361 -414 -361 -414 -47 23 c-68 35 -300 120 -424 156 -531 153 -1107 181 -1645 79 -892 -169 -1693 -688 -2218 -1435 -739 -1054 -837 -2442 -254 -3589 340 -669 876 -1205 1545 -1545 829 -422 1795 -494 2685 -200 395 130 752 324 1100 599 133 104 448 424 554 561 373 483 605 1006 700 1580 36 219 46 343 46 593 0 433 -70 825 -217 1221 -50 136 -186 420 -257 540 -46 78 -47 79 -28 100 180 199 702 808 702 818 0 19 -66 123 -139 218 -72 95 -355 381 -473 479 l-77 64 26 51 c178 351 597 595 1133 657 129 14 412 7 529 -15 535 -99 876 -377 983 -803 33 -127 32 -411 0 -562 -76 -352 -254 -729 -532 -1131 -438 -632 -690 -1191 -787 -1745 -14 -78 -17 -157 -17 -355 0 -228 2 -266 22 -360 77 -351 244 -630 490 -815 548 -411 1274 -287 1622 278 37 60 85 158 104 214 1 1 98 -11 216 -27 118 -15 240 -31 270 -34 l55 -6 -70 19 c-126 33 -175 63 -175 106 0 60 117 159 357 302 186 111 187 113 37 39 -159 -78 -252 -112 -268 -96 -7 7 -5 21 9 48 20 38 319 344 530 541 l120 113 -116 -99 c-351 -298 -542 -414 -589 -358 -20 24 -16 108 10 202 27 95 128 353 210 534 33 73 60 136 60 139 0 3 -43 -78 -96 -179 -192 -371 -294 -521 -355 -521 -67 0 -75 255 -24 755 8 77 17 169 20 205 l4 65 -10 -60 c-5 -33 -23 -145 -40 -250 -71 -440 -134 -662 -193 -681 -31 -10 -68 40 -105 142 -37 100 -99 337 -132 506 -29 145 -36 125 -10 -28 54 -317 75 -608 50 -697 -14 -53 -48 -68 -95 -42 -72 39 -252 254 -416 498 -32 48 -61 87 -64 87 -6 0 -7 1 75 -125 111 -171 280 -456 327 -550 49 -100 58 -137 33 -142 -22 -5 -118 62 -234 164 l-100 87 133 -139 c222 -231 292 -339 256 -393 -23 -35 -59 -45 -169 -45 -119 0 -108 -2 205 -43 113 -14 207 -28 209 -30 3 -3 -19 -51 -47 -108 -143 -281 -391 -477 -687 -542 -107 -23 -320 -23 -430 0 -458 99 -795 461 -906 975 -30 138 -37 449 -15 620 71 539 315 1099 769 1769 242 356 367 589 472 879 147 403 153 804 16 1090 -168 350 -490 568 -976 660 -96 18 -467 26 -570 12z" />
        <path d="M9591 4924 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M9611 4814 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M9621 4764 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M9631 4714 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M11176 4705 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z" />
        <path d="M9641 4664 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M9651 4604 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M9661 4554 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M11082 4505 c-17 -37 -14 -45 4 -12 9 16 14 31 11 33 -2 2 -9 -7 -15 -21z" />
        <path d="M10986 4305 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z" />
        <path d="M10302 4145 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
        <path d="M9782 3910 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
      </g>
    </svg>
  );
}
