import { Checkbox as CB, FormControlLabel } from '@mui/material';

interface Props {
  label: string;
  type?: string;
  required?: boolean;
  readOnly?: boolean;
  value: boolean;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

const TextField = ({ label, required, type, value, onChange, readOnly }: Props) => {
  return (
    <FormControlLabel
      control={<CB checked={value} onChange={onChange} />}
      label={label}
      labelPlacement="start"
      disabled={readOnly}
    />
  );
};

export default TextField;
