import { AppBar, Box, Grid, IconButton, Menu, MenuItem, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { Children, ReactNode, useState } from 'react';
import { Subtitle, Title, Icons } from '..';

interface Props {
  title: string | JSX.Element;
  details?: string | JSX.Element | JSX.Element[];
  children?: ReactNode;
}

const Header = ({ title, children, details }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ position: 'relative' }}>
      <AppBar
        sx={{
          top: 'inherit',
          paddingLeft: 4,
          paddingRight: 4,
          height: '80px',
        }}
        color="inherit"
      >
        <Grid container>
          <Grid item xs={10} sm={5}>
            <Title>{title}</Title>
            <Subtitle>{details}</Subtitle>
          </Grid>
          {children && (
            <Grid item xs={2} sm={7}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                {isXS || Children.count(children) > 4 ? (
                  <>
                    <IconButton size="large" color="inherit" aria-label="open drawer" onClick={handleMenu}>
                      <Icons.Menu />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {Children.map(children, (child, index) => (
                        <MenuItem key={index} onClick={handleClose}>
                          {child}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                ) : (
                  children
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </AppBar>
      <Toolbar sx={{ height: '80px', marginBottom: '1rem' }} />
    </Box>
  );
};

export default Header;
