import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '../common/ui-component';
import SubscriptionList from './Subscriptions';
import SubscriptionsService from './subscriptions.service';

const ListPage = () => {
  const { t } = useTranslation('subscription');

  const [getSubscriptions, subscriptions] = SubscriptionsService.useSubscriptionsService();

  const _getSubscriptions = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (params: any) => {
      return getSubscriptions(params);
    },
    [getSubscriptions],
  );

  return (
    <>
      <Title>{t('subscriptions')}</Title>

      <SubscriptionList
        listId="subscription-listpage-table"
        getSubscriptions={_getSubscriptions}
        subscriptions={subscriptions}
      />
    </>
  );
};

export default ListPage;
