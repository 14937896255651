import axios from 'axios';
import { Paginated } from '../common/Paginated';
import useService from '../common/useService';
import { StuckOrderModel } from '../models';
import { PageProps } from '../common/ui-component/data-table/filters/backoffice-query';

const useGetStuckOrders = () =>
  useService((params: PageProps) => axios.get<Paginated<StuckOrderModel>>(`/stuck-order/`, { params }));

const StuckOrdersService = {
  useGetStuckOrders,
};

export default StuckOrdersService;
