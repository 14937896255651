import { Popover } from '@mui/material';
import React, { useState, Children, ReactNode } from 'react';

const Multiple = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <div>
      {Children.map(children, (c) => (
        <div>{c}</div>
      ))}
    </div>
  );
};

const isMultiple = (node: React.ReactNode): node is React.ReactElement => {
  if (typeof node === 'boolean') return false;
  if (typeof node === 'number') return false;
  if (typeof node === 'string') return false;
  if (node && 'type' in node) {
    return node.type === Multiple;
  }

  return false;
};

const Conditional = ({ children, predicate }: { children: JSX.Element | string; predicate: boolean }) => {
  if (predicate) return <>{children}</>;
  return <></>;
};

const isConditional = (node: React.ReactNode): node is React.ReactElement => {
  if (typeof node === 'boolean') return false;
  if (typeof node === 'number') return false;
  if (typeof node === 'string') return false;
  if (node && 'type' in node) {
    return node.type === Conditional;
  }

  return false;
};

const Single = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return <>{children}</>;
};

const isSingle = (node: React.ReactNode): node is React.ReactElement => {
  if (typeof node === 'boolean') return false;
  if (typeof node === 'number') return false;
  if (typeof node === 'string') return false;
  if (node && 'type' in node) {
    return node.type === Single;
  }

  return false;
};

const isTooltip = (x: React.ReactNode) => {
  return isConditional(x) || isSingle(x) || isMultiple(x);
};

const Content = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return <>{children}</>;
};

const isContent = (node: React.ReactNode): node is React.ReactElement => {
  if (typeof node === 'boolean') return false;
  if (typeof node === 'number') return false;
  if (typeof node === 'string') return false;
  if (node && 'type' in node) {
    return node.type === Content;
  }

  return false;
};

const Tooltips = ({ children }: { children: JSX.Element | JSX.Element[] | ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  const childrens = Children.toArray(children).filter((x) => isTooltip(x) || isContent(x));

  const tooltip = childrens.find((x) => {
    if (isConditional(x) && 'predicate' in x.props) {
      return x.props.predicate;
    }
    if (isTooltip(x)) return true;
    return false;
  });

  const element = childrens.filter((x) => isContent(x));

  return (
    <>
      {React.cloneElement(<span>{element}</span>, {
        onMouseEnter: handlePopoverOpen,
        onMouseLeave: handlePopoverClose,
      })}
      {tooltip ? (
        <Popover
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          {tooltip}
        </Popover>
      ) : undefined}
    </>
  );
};

Tooltips.Multiple = Multiple;
Tooltips.Conditional = Conditional;
Tooltips.Single = Single;
Tooltips.Content = Content;

export default Tooltips;
