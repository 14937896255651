import { useTranslation } from 'react-i18next';
import { Title } from '../common/ui-component';
import PharmacyService from './pharmacy.service';
import PharmacyList from './PharmacyList';

const ListPage = () => {
  const { t } = useTranslation('pharmacy');

  const [getOrders, orders] = PharmacyService.useGetOrdersV2();

  return (
    <>
      <Title>{t('pharmacyOrders')}</Title>

      <PharmacyList getOrders={getOrders} orders={orders} />
    </>
  );
};

export default ListPage;
