import { Controller, useFormContext } from 'react-hook-form';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { TextField } from '@mui/material';
import { DateTime } from 'luxon';

interface Props {
  name: string;
  readOnly?: boolean;
}

const DateTimeInput = ({ name, readOnly }: Props) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DateTimePicker
              renderInput={(props) => <TextField style={{ marginTop: '16px' }} {...props} />}
              value={DateTime.fromISO(value)}
              onChange={onChange}
              readOnly={readOnly}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};

export default DateTimeInput;
