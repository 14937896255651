const SUBSCRIPTION_EN = {
  billingDetails: 'Billing details',
  billingInterval: 'Billing interval',
  billingPeriod: 'Billing period',
  both: 'Both',
  caseHistory: 'Case History',
  category: 'Category',
  changeBillingInterval: 'Change billing interval',
  changeNextPaymentDate: 'Change next payment date',
  clinician: 'Clinician',
  close: 'Close',
  created: 'Created',
  customerId: 'Customer id',
  dateCreated: 'Subs. Created',
  dateCreatedWithTime: 'Subs. Created (with time)',
  customerName: 'Customer Name',
  email: 'Email',
  endDate: 'End Date',
  frequency: 'Frequency',
  id: 'id',
  lastOrderDate: 'Last Order Date',
  lastOrderDateWithTime: 'Last Order Date (with time)',
  lastPaymentDate: 'Last Payment',
  lastPaymentDateWithTime: 'Last Payment (with time)',
  mdi: 'MDI Details',
  mdiDetails: 'MDI Details',
  medicalCaseId: 'Medical case Id',
  nextOrderDate: 'Next Order Date',
  nextPaymentDate: 'Next Payment',
  nextPaymentDateWithTime: 'Next Payment (with time)',
  nextPaymentDateDetails: 'Next Payment Date details',
  numberOfProducts: '# of Products',
  orderIds: 'Linked orders',
  otc: 'OTC',
  parentOrderId: 'Parent Order ID',
  patientID: 'Patient ID',
  pharmacy: 'Pharmacy',
  prescriptions: 'Prescriptions',
  products: 'Products',
  qty: 'QTY',
  rx: 'Rx',
  send: 'Submit',
  sku: 'SKU',
  status: 'Status',
  statusHistory: 'Status History',
  subscriptionActions: 'Subscription Actions',
  subscriptionDetails: 'Subscription Details',
  subscriptionId: 'Subscription id',
  subscriptions: 'Subscriptions',
  subscription: 'Subscription',
  syncSubscriptionWithWoo: 'Sync Subscription with Woo',
  total: 'Total',
  updateNextPayementDateConfirmation: 'Update Next payement date',
  updateStatusConfirmation: 'Update Subscription Status',
  woocommerceId: 'WooCommerce ID',
  phone: 'phone',
};

export default SUBSCRIPTION_EN;
