import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';

type Props<T> = { value?: T; onClick?: (v?: T) => void };

const SuccessToast = <T,>({ value, onClick }: Props<T>) => {
  const { t } = useTranslation('common');
  return (
    <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Alert variant="filled" severity="success" onClick={() => (onClick ? onClick(value) : undefined)}>
        {t('httpSuccess')}
      </Alert>
    </Snackbar>
  );
};

export default SuccessToast;
