import jwtTokenHelper from './jwt-token.helper';
import { TokenPayload } from '../login/token-payload.model';

export type AuthPayload = { access_token: string | null; is_admin: boolean; refresh_token: string | null } | undefined;

type LoggedOut = { _tag: 'loggedOut' };
type LoggedIn = { _tag: 'loggedIn'; authPayload: AuthPayload };

export type AuthState = LoggedIn | LoggedOut;

export const loggedOut = (): AuthState => ({ _tag: 'loggedOut' });

export const loggedIn = (authPayload: AuthPayload): AuthState => ({
  _tag: 'loggedIn',
  authPayload: authPayload,
});

const fold =
  <R>(whenLoggedOut: () => R, whenLoggedIn: (user: AuthPayload) => R) =>
  (authState: AuthState) => {
    if (isLoggedOut(authState)) return whenLoggedOut();
    return whenLoggedIn(authState.authPayload);
  };

const _isLoggedIn = (ma: AuthState): ma is LoggedIn => ma._tag === 'loggedIn';

export const isLoggedIn: (rd: AuthState) => rd is LoggedIn = _isLoggedIn;

const _isAdminUser = (ma: AuthState): ma is LoggedIn => (ma as LoggedIn).authPayload?.is_admin === true;
export const isAdminUser: (rd: AuthState) => rd is LoggedIn = _isAdminUser;

const _isLoggedOut = (ma: AuthState): ma is LoggedOut => ma._tag === 'loggedOut';

const isLoggedOut: (rd: AuthState) => rd is LoggedOut = _isLoggedOut;

export const getUser = (authState: AuthState): TokenPayload | null | false =>
  fold(
    () => null,
    (authPayload) => !!authPayload && jwtTokenHelper.decode(authPayload.access_token),
  )(authState);

const Auth = {
  loggedOut,
  loggedIn,
  fold,
};

export default Auth;
