import { Box as MuiBox, SxProps } from '@mui/material';
import { CSSProperties } from '@mui/styles';

type Props = {
  children: React.ReactNode;
  sx?: SxProps;
  style?: CSSProperties;
};

const Box = ({ sx, children, style }: Props) => {
  return (
    <MuiBox style={style} sx={sx}>
      {children}
    </MuiBox>
  );
};

export default Box;
