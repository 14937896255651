import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { Authenticated, useAccessToken } from './authentication';
import { Customer, Customers } from './customer';
import { Order, OrderNavbar, Orders, OrdersStuck } from './orders';
import Login, { Logout, SecondFactor } from './login';
import { Navbar, Page } from './common/ui-component';
import { DeadLetters } from './dead-letter';
import DeadLetter from './dead-letter/DeadLetter.page';
import CaseFixer from './casefixer/CaseFixer';
import { PharmacyOrder, PharmacyOrders } from './pharmacy';
import Subscription from './subscriptions/DetailPage';
import PendingOrders from './pending-orders/PendingOrders';
import PendingOrder from './pending-orders/PendingOrder';
import HairYardList from './hair-yard/ListPage';
import { MedicalCase, MedicalCases } from './medical-case';
import { FeatureToggleHelper } from './utils/feature-toggle';
import ListPage from './subscriptions/ListPage';
import { useMemo } from 'react';
import Products from './product/Products';
import Search from './search/Search';
import { HealthUpdateTrackerDetailPage, HealthUpdateTrackerListPage } from './health-update-tracker';
import HiddenDetailPage from './hidden/DetailPage';
import { isAdminUser } from './authentication/auth';
import { MarketingPlatformListPage } from './marketing-platform';
import AnnualHealthUpdate from './medical-case/annual-health-update/ListPage';
import ProductRecommendationPage from './product-recommendation/ProductRecommendation.page';

export const LOGIN_ROUTE = '/login';
export const LOGOUT_ROUTE = '/logout';
export const CUSTOMERS_ROUTE = '/customers';
export const ORDERS_ROUTE = '/orders';
export const SUBSCRIPTIONS_ROUTE = '/subscriptions';
export const DEAD_LETTER_ROUTE = '/dead-letter';
export const CASE_ROUTE = '/case';
export const PHARMACY_ORDER_ROUTE = '/pharmacy-order';
export const PENDING_ORDER_ROUTE = '/pending-orders';
export const MEDICAL_CASE_ROUTE = '/medical-case';
export const MEDICAL_CASE_ANNUAL_HEALTH_UPDATE_PATH = 'annual-health-update';
export const PRODUCT_ROUTE = '/products';
export const SECOND_FACTOR_AUTH_ROUTE = '/2fa';
export const FAKE_ORDER_GENERATOR = '/fake-order-generator';
export const SEARCH_ROUTE = '/search';
export const HEALTH_UPDATE_TRACKER_ROUTE = '/health-update-tracker';
export const HAIR_YARD_ROUTE = '/hair-yard';
export const HIDDEN_ROUTE = '/hidden';
export const MARKETING_PLATFORM = '/marketing-platform';
export const PRODUCT_RECOMMENDATION_ROUTE = '/product-recommendation';

const ProtectedPage = () => {
  const { t } = useTranslation([
    'customer',
    'order',
    'subscription',
    'medical_case',
    'pharmacy',
    'deadLetter',
    'login',
    'pending_order',
    'product',
    'health_update_tracker',
    'hair_yard',
    'search',
    'marketing_platform',
    'product_recommendation',
  ]);

  const [user] = useAccessToken();

  const navigations = useMemo(
    () => [
      { label: t('search:search'), href: SEARCH_ROUTE },
      { label: t('customer:customers'), href: CUSTOMERS_ROUTE },
      { label: t('order:orders'), href: ORDERS_ROUTE, subNavigation: <OrderNavbar /> },
      { label: t('subscription:subscriptions'), href: SUBSCRIPTIONS_ROUTE },
      {
        label: t('medical_case:medicalCases'),
        href: MEDICAL_CASE_ROUTE,
        hidden: FeatureToggleHelper.isOff(process.env.REACT_APP_MEDICAL_CASE_TOGGLER),
      },
      { label: t('pharmacy:pharmacyOrders'), href: PHARMACY_ORDER_ROUTE },
      { label: t('health_update_tracker:healthUpdateTracker'), href: HEALTH_UPDATE_TRACKER_ROUTE },
      { label: t('hair_yard:hairYard'), href: HAIR_YARD_ROUTE },
      { label: t('pending_order:pendingOrders'), href: PENDING_ORDER_ROUTE },
      { label: t('product:products'), href: PRODUCT_ROUTE },
      { label: t('deadLetter:deadLetter'), href: DEAD_LETTER_ROUTE },
      { label: 'caseId', href: CASE_ROUTE },
      { label: t('marketing_platform:marketingPlatform'), href: MARKETING_PLATFORM, hidden: !isAdminUser(user) },
      {
        label: t('product_recommendation:productRecommendation'),
        href: PRODUCT_RECOMMENDATION_ROUTE,
        hidden: !isAdminUser(user),
      },

      {
        label: 'Fake Order Generator',
        href: FAKE_ORDER_GENERATOR,
        hidden: process.env.NODE_ENV !== 'development',
      },
    ],
    [t, user],
  );

  const location = useLocation();
  const logout = { label: t('login:logout'), href: LOGOUT_ROUTE + `?redirectTo=${location.pathname}` };

  const { pathname } = useLocation();

  return (
    <Authenticated
      element={
        <Page navbar={<Navbar currentRoute={`/${pathname.split('/')[1]}`} routes={navigations} logoutRoute={logout} />}>
          <Outlet />
        </Page>
      }
    />
  );
};

function Router() {
  return (
    <Routes>
      <Route path="/" element={<ProtectedPage />}>
        <Route index element={<Navigate to={SEARCH_ROUTE} replace />} />
      </Route>
      <Route path={LOGIN_ROUTE} element={<Login />} />

      <Route path={LOGOUT_ROUTE} element={<Logout />} />

      <Route path={SECOND_FACTOR_AUTH_ROUTE} element={<SecondFactor />} />

      <Route path={SEARCH_ROUTE} element={<ProtectedPage />}>
        <Route index element={<Search />} />
      </Route>

      <Route path={CUSTOMERS_ROUTE} element={<ProtectedPage />}>
        <Route index element={<Customers />} />
        <Route path=":id" element={<Customer />} />
      </Route>

      <Route path={ORDERS_ROUTE} element={<ProtectedPage />}>
        <Route index element={<Orders />} />
        <Route path=":id" element={<Order />} />
        <Route path=":id#replace" element={<Order />} />
        <Route path="stuck" element={<OrdersStuck />} />
      </Route>

      <Route path={SUBSCRIPTIONS_ROUTE} element={<ProtectedPage />}>
        <Route index element={<ListPage />} />
        <Route path=":id" element={<Subscription />} />
      </Route>

      <Route path={MEDICAL_CASE_ROUTE} element={<ProtectedPage />}>
        <Route index element={<MedicalCases />} />
        <Route path={MEDICAL_CASE_ANNUAL_HEALTH_UPDATE_PATH} element={<AnnualHealthUpdate />} />
        <Route path=":id" element={<MedicalCase />} />
      </Route>

      <Route path={PHARMACY_ORDER_ROUTE} element={<ProtectedPage />}>
        <Route index element={<PharmacyOrders />} />
        <Route path=":id">
          <Route index element={<PharmacyOrder />} />
        </Route>
      </Route>

      <Route path={PENDING_ORDER_ROUTE} element={<ProtectedPage />}>
        <Route index element={<PendingOrders />} />
        <Route path=":id" element={<PendingOrder />} />
      </Route>

      <Route path={PRODUCT_ROUTE} element={<ProtectedPage />}>
        <Route index element={<Products />} />
      </Route>

      <Route path={DEAD_LETTER_ROUTE} element={<ProtectedPage />}>
        <Route index element={<DeadLetters />} />
        <Route path=":id" element={<DeadLetter />} />
        <Route path=":id" element={<DeadLetter />} />
      </Route>

      <Route path={CASE_ROUTE} element={<ProtectedPage />}>
        <Route index element={<CaseFixer />} />
      </Route>

      <Route path={HAIR_YARD_ROUTE} element={<ProtectedPage />}>
        <Route index element={<HairYardList />} />
      </Route>

      <Route path={MARKETING_PLATFORM} element={<ProtectedPage />}>
        <Route index element={<MarketingPlatformListPage />} />
      </Route>

      <Route path={PRODUCT_RECOMMENDATION_ROUTE} element={<ProtectedPage />}>
        <Route index element={<ProductRecommendationPage />} />
      </Route>

      <Route path={HEALTH_UPDATE_TRACKER_ROUTE} element={<ProtectedPage />}>
        <Route index element={<HealthUpdateTrackerListPage />} />
        <Route path=":id" element={<HealthUpdateTrackerDetailPage />} />
      </Route>
      <Route path={HIDDEN_ROUTE} element={<ProtectedPage />}>
        <Route index element={<HiddenDetailPage />} />
      </Route>
    </Routes>
  );
}

export default Router;
