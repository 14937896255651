import { Chip } from '../common/ui-component';
import { PharmacyOrderStatusEnum } from './pharmacy-order.model';

const StatusChip = ({ status }: { status: PharmacyOrderStatusEnum | 'not-sent' }) => {
  const color = (() => {
    switch (status) {
      case 'not-sent':
        return 'error';
      case PharmacyOrderStatusEnum.NEW:
        return 'warning';
      case PharmacyOrderStatusEnum.IN_PROGRESS:
        return 'warning';
      case PharmacyOrderStatusEnum.OUT_FOR_DELIVERY:
        return 'success';
      case PharmacyOrderStatusEnum.COMPLETED:
        return 'success';
      case PharmacyOrderStatusEnum.CANCELLED:
        return 'success';
      case PharmacyOrderStatusEnum.ERROR:
        return 'error';
    }
  })();
  return <Chip text={status} color={color} />;
};

export default StatusChip;
