const PRODUCT_RECOMMENDATION_EN = {
  dateOfBirth: 'Date Of Birth',
  gender: 'Gender',
  productRecommendation: 'Product Recommendation',
  recommendation: 'Recommendation',
  userChosenPids: 'User Chosen Products',
  userPrefenceMethods: 'User Prefence Methods',
  send: 'Send',
  notAsked: 'Send for having first results',
};

export default PRODUCT_RECOMMENDATION_EN;
