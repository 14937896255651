import { DateHelper } from '../../utils/date';
import { List, ListItem, ListItemText } from '@mui/material';

const CommentList = ({ comments }: { comments: { from: string; text: string; createdDate: string }[] }) => {
  return (
    <List
      sx={{
        maxHeight: 420,
        overflowY: 'auto',
        width: '100%',
      }}
    >
      {comments.map((c) => (
        <ListItem key={c.createdDate}>
          <ListItemText
            primary={c.from}
            secondary={
              <span>
                <span>{`Text : ${c.text}`}</span>
                <br />
                <span>{`Date : ${DateHelper.dateTime(c.createdDate).value}`}</span>
              </span>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default CommentList;
