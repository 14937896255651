import { Tabs, Tab, SxProps, Theme } from '@mui/material';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

const LinkTab = ({
  label,
  href,
  sx,
  hidden,
}: {
  sx?: SxProps<Theme> | undefined;
  label: string;
  href: string;
  hidden?: boolean;
}) => <Tab sx={{ ...sx, display: hidden ? 'none' : undefined }} component={Link} label={label} to={href} />;
interface Props {
  currentRoute: string;
  routes: { label: string; href: string; hidden?: boolean }[];
}

const Navbar = ({ currentRoute, routes }: Props) => {
  const currentRouteIndex = useMemo(() => routes.map((x) => x.href).indexOf(currentRoute), [currentRoute, routes]);
  return (
    <Tabs value={currentRouteIndex} aria-label="nav tabs example" variant="scrollable" sx={{ flexGrow: 1 }}>
      {routes.map(({ label, href, hidden }) => {
        return <LinkTab key={href} label={label} href={href} hidden={hidden} />;
      })}
    </Tabs>
  );
};

export default Navbar;
