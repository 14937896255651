import { Controller, useFormContext } from 'react-hook-form';
import { DropdownField } from '../ui-component';

interface Props<T> {
  name: string;
  label: string;
  type?: string;
  readOnly?: boolean;
  items: T[];
  toOption: <Z>(t: T) => { displayText: string; value: T | Z; id: string | number };
  required?: boolean;
}

const TextInput = <T,>({ label, name, readOnly, items: options, toOption, required }: Props<T>) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <DropdownField
            label={label}
            onChange={onChange}
            value={value == null ? '' : value}
            items={options}
            readonly={readOnly}
            toOption={toOption}
            required={required}
          />
        );
      }}
    />
  );
};

export default TextInput;
