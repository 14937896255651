import { Link as MuiLink, SxProps } from '@mui/material';
import { Link as RouterLink, To } from 'react-router-dom';

interface Props {
  to?: To;
  replace?: boolean;
  children: JSX.Element[] | JSX.Element | string | number;
  href?: string;
  style?: React.CSSProperties;
  target?: string;
  onClick?: () => void;
  sx?: SxProps;
  disabled?: boolean;
}

const Link = ({ to, replace, children, href, style, target, sx, onClick }: Props) => {
  if (!to && !href) throw new Error('Props href or to is required');
  if (to)
    return (
      <MuiLink
        component={RouterLink}
        to={to}
        replace={replace}
        underline="none"
        color="inherit"
        style={style}
        sx={{ '&:visited': 'inherit', '&:active': 'inherit', '&:hover': 'inherit', ...sx }}
        onClick={onClick}
      >
        {children}
      </MuiLink>
    );

  return (
    <MuiLink style={style} href={href} target={target} onClick={onClick}>
      {children}
    </MuiLink>
  );
};

export default Link;
