import axios from 'axios';
import useService from '../common/useService';
import { MedicalCaseModel } from '../models';

const useGetHistory = () =>
  useService((medicalCaseId: number) => axios.get<MedicalCaseModel>(`/medical-case-history/${medicalCaseId}`));

const MedicalCaseHistoryService = {
  useGetHistory,
};

export default MedicalCaseHistoryService;
