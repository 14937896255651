import axios from 'axios';
import { ReactNode } from 'react';
import { AuthPayload } from '../auth';
import AccessTokenContext from './AccessTokenContext';
import useTokenState from './useTokenState';

interface Props {
  children: ReactNode;
  authPayload?: AuthPayload;
}

const AccessTokenProvider = ({ children, authPayload }: Props) => {
  const maybeToken = localStorage.getItem('jwt_token');
  const maybeRefresh = localStorage.getItem('jwt_refresh');
  const maybeIsAdmin = localStorage.getItem('is_admin');
  axios.defaults.headers.common.Authorization = `Bearer ${authPayload?.access_token || maybeToken}`;

  const [token, setToken] = useTokenState(
    authPayload || {
      access_token: maybeToken,
      is_admin: maybeIsAdmin === 'true',
      refresh_token: maybeRefresh,
    },
  );

  return <AccessTokenContext.Provider value={[token, setToken]}>{children}</AccessTokenContext.Provider>;
};

export default AccessTokenProvider;
