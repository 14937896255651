import { Container, Grid } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

const Centered = ({ children }: Props) => {
  return (
    <Container maxWidth="xs">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={3}>
          {children}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Centered;
