import { useState } from 'react';
import { AuthPayload } from '..';
import Auth, { AuthState } from '../auth';

const useTokenState = (defaultValue: AuthPayload): [AuthState, React.Dispatch<React.SetStateAction<AuthState>>] => {
  const [token, setToken] = useState(defaultValue ? Auth.loggedIn(defaultValue) : Auth.loggedOut());

  return [token, setToken];
};

export default useTokenState;
