import { TFunction } from 'react-i18next';
import {
  MedicalCaseAddressModel,
  MedicalCaseClaimedProductsModel,
  MedicalCaseModel,
  MedicalInformationModel,
} from '../models';

const getPatientIdErrors = (medicalCase: MedicalCaseModel, t: TFunction<'medical_case', undefined>): string[] => {
  return [
    !medicalCase.phoneNumber && t('missingPhoneNumber'),
    medicalCase.address?.address && medicalCase.address?.address.length > 35 && t('addressToLong'),
    !medicalCase.address ||
      (['address', 'cityName', 'stateName', 'zipCode'].some(
        (x) => !medicalCase?.address[x as keyof MedicalCaseAddressModel],
      ) &&
        t('missingAddressData')),
    !(medicalCase.photos || []).some((x) => x.type === 'license') &&
      medicalCase.driverLicenseRequired &&
      !medicalCase.videoRequired &&
      t('missingDriverLicensePhoto'),
    !medicalCase.dateOfBirth && t('missingDob'),
    !medicalCase.driverLicenseId &&
      medicalCase.driverLicenseRequired &&
      !medicalCase.videoRequired &&
      t('missingDriverLicenseId'),
  ].filter((x): x is string => typeof x === 'string');
};

const getMedicalInformationErrors = (
  medicalCase: MedicalCaseModel,
  t: TFunction<'medical_case', undefined>,
): string[] => {
  const questions = Object.values(medicalCase.medicalInformation || ({} as MedicalInformationModel));

  return [!(questions.length && questions.every((question) => !!question.value)) && t('missingDriverLicenseId')].filter(
    (x): x is string => typeof x === 'string',
  );
};

const getProductsErrors = (medicalCase: MedicalCaseModel, t: TFunction<'medical_case', undefined>): string[] => {
  const claimedProducts = Object.values(medicalCase.claimedProducts || ({} as MedicalCaseClaimedProductsModel));

  return [
    claimedProducts.length && claimedProducts.every((product) => product.isIdle) && t('allProductsAreIdle'),
  ].filter((x): x is string => typeof x === 'string');
};

const getPhotosErrors = (medicalCase: MedicalCaseModel, t: TFunction<'medical_case', undefined>): string[] => {
  return [
    medicalCase.driverLicenseRequired &&
      !(medicalCase.photos || []).some((x) => x.type === 'license') &&
      !medicalCase.videoRequired &&
      t('missingDriverLicensePhoto'),
    !(medicalCase.photos || []).some((x) => x.type === 'other') && t('missingHairPhotos'),
    (medicalCase.photos || []).some((x) => !x.mdiFile) && t('missingMdiFile'),
  ].filter((x): x is string => typeof x === 'string');
};

const getMedicalCaseErrors = (medicalCase: MedicalCaseModel, t: TFunction<'medical_case', undefined>) => [
  ...getPatientIdErrors(medicalCase, t),
  ...getPhotosErrors(medicalCase, t),
  ...getMedicalInformationErrors(medicalCase, t),
  ...getProductsErrors(medicalCase, t),
];

const MedicalCaseValidator = {
  getPatientIdErrors,
  getMedicalInformationErrors,
  getPhotosErrors,
  getMedicalCaseErrors,
};

export default MedicalCaseValidator;
