import { errorHandler, Loader } from '..';
import { fold, RemoteData } from '../../../utils/remote-data';

interface Props<T> {
  value: RemoteData<T, unknown>;
  whenSuccess: (x: T) => JSX.Element;
  whenLoading?: () => JSX.Element;
  whenFailure?: (x: unknown) => JSX.Element;
  whenNotAsked?: () => JSX.Element;
}
const RemoteDataContent = <T,>(props: Props<T>) => {
  const { value, whenSuccess, whenLoading, whenFailure, whenNotAsked } = props;
  const result = fold(
    whenNotAsked ? whenNotAsked : () => <Loader />,
    whenLoading ? whenLoading : () => <Loader />,
    whenSuccess,
    whenFailure ? whenFailure : errorHandler,
  )(value);

  return result;
};

export default RemoteDataContent;
