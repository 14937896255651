import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { loggedOut, useAccessToken } from '../authentication';
import { isLoggedIn } from '../authentication/auth';
import { LOGIN_ROUTE } from '../Router';

const Logout = () => {
  const [accessToken, setAccessToken] = useAccessToken();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn(accessToken)) navigate(LOGIN_ROUTE + location.search);
  }, [accessToken, navigate, location.search]);

  useEffect(() => {
    setAccessToken(loggedOut());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

export default Logout;
