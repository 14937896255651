import { Typography } from '@mui/material';

interface Props {
  children?: React.ReactNode | string;
}

const Subtitle = ({ children }: Props) => {
  return (
    <Typography component="h2" variant="h5" sx={{ verticalAlign: `middle` }}>
      {children}
    </Typography>
  );
};

export default Subtitle;
