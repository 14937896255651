import { useTranslation } from 'react-i18next';
import OrdersService from './orders.service';
import { Title } from '../common/ui-component';
import OrderList from './Orders';
import { useCallback } from 'react';

function ListPage() {
  const { t } = useTranslation('order');
  const [getOrdersV2, ordersV2] = OrdersService.useGetOrdersV2();

  const _getOrdersV2 = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (params: any) => {
      return getOrdersV2(params);
    },
    [getOrdersV2],
  );

  return (
    <>
      <Title>{t('orders')}</Title>
      <OrderList listId="order-table" getOrders={_getOrdersV2} orders={ordersV2} />
    </>
  );
}

export default ListPage;
