import {
  DataGridPro as DataGrid,
  getGridStringOperators,
  GridColumns,
  GridFilterModel,
  GridRowModel,
  GridRowParams,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Paginated } from '../../Paginated';
import { fold, RemoteData } from '../../../utils/remote-data';
import { useAccessToken } from '../../../authentication';
import { isAdminUser } from '../../../authentication/auth';
import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { filterModelToBackofficeQueries, PageProps, SearchParams } from './filters/backoffice-query';

const filterOperators = () => getGridStringOperators().filter((operator) => operator.value === 'equals');

export type Sort = SortParam | null;

export interface SortParam {
  orderBy: string;
  order: 'asc' | 'desc' | null | undefined;
}

export interface RowsState {
  page: number;
  pageSize: number;
  rows: GridRowModel[];
  loading: boolean;
  total: number;
  searchParams: SearchParams;
  sort: Sort;
}

interface Props<A> {
  cols: GridColumns;
  fetcher: (pageProps: PageProps) => void;
  datas: RemoteData<Paginated<A>, unknown>;
  checkboxSelection?: boolean;
  toRow: (d: A) => GridRowModel;
  actions?: (params: GridRowParams) => JSX.Element;
  forceReload?: boolean;
  hasToolbar?: boolean;
  sx?: SxProps<Theme> | undefined;
  renameFilterFields?: {
    original: string;
    renameto: string;
    parse: (from: string) => unknown;
  };
  hideFooter?: boolean;
  id: string;
  defaultFilters?: GridFilterModel;
  initialColumnVisibilityModel?: { [key: string]: boolean };
}

function DataTable<A>(props: Props<A>) {
  const {
    cols,
    checkboxSelection,
    fetcher,
    datas,
    toRow,
    forceReload,
    id,
    hasToolbar: hasAction = true,
    hideFooter = false,
    sx,
    defaultFilters,
    initialColumnVisibilityModel,
  } = props;

  const pageSize_ = 100;
  const [rows_, setRows] = useState<{ [key: string]: unknown }[]>([]);
  const [loading_, setLoading] = useState(false);
  const [searchParams_, setSearchParams] = useState(
    defaultFilters ? { queries: filterModelToBackofficeQueries(defaultFilters) } : { queries: {} },
  );
  const [sort_, setSort] = useState<Sort>(null);
  const [user] = useAccessToken();
  const apiRef = useGridApiRef();

  const memoFetcher = useCallback(
    (pageSize: number, searchParams?: { queries: SearchParams }, sort?: Sort) => {
      fetcher({ pageNumber: 1, pageSize, ...searchParams, ...sort });
    },
    [fetcher],
  );

  useEffect(() => {
    memoFetcher(pageSize_, searchParams_, sort_);
  }, [memoFetcher, pageSize_, searchParams_, sort_]);

  useEffect(() => {
    if (forceReload) {
      memoFetcher(pageSize_, searchParams_, sort_);
    }
    // do not remove force reload
  }, [memoFetcher, forceReload, pageSize_, searchParams_, sort_]);

  useEffect(() => {
    return fold(
      () => {
        setLoading(true);
      },
      () => {
        setLoading(true);
      },
      (data: Paginated<A>) => {
        setLoading(false);
        setRows(data.data.map(toRow));
      },
      () => {
        setLoading(false);
      },
    )(datas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas]);

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    const queries = filterModelToBackofficeQueries(filterModel);

    setSearchParams({ queries });
  }, []);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    const sorts = sortModel.map((sortM) => ({
      orderBy: sortM.field,
      order: sortM.sort,
    }));
    setSort(sorts[0]);
  }, []);

  const state = useMemo(() => {
    const s = JSON.parse(
      window.localStorage.getItem(`${id}_state`) ||
        `{ "columns": { "columnVisibilityModel": ${JSON.stringify(initialColumnVisibilityModel || {})} } }`,
    );
    return {
      columns: s.columns,
      filter: {
        filterModel: defaultFilters,
      },
    };
  }, [id, initialColumnVisibilityModel, defaultFilters]);

  const columns = useMemo(
    (): GridColumns =>
      cols
        .map((col) => ({
          ...col,
          align: 'center',
          headerAlign: 'center',
          filterOperators: col.filterOperators || filterOperators(),
        }))
        .sort((a, b) => {
          if (state.columns?.orderedFields?.length) {
            const aValue = state.columns.orderedFields.indexOf(a.field);
            const bValue = state.columns.orderedFields.indexOf(b.field);
            return aValue - bValue;
          }

          return 0;
        }) as GridColumns,
    [cols, state?.columns?.orderedFields],
  );

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      />

      <GridToolbarFilterButton
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      />

      <GridToolbarDensitySelector
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        placeholder={undefined}
      />
      {/* <GridToolbarExport /> */}
      {isAdminUser(user) && <GridToolbarExport />}
    </GridToolbarContainer>
  );

  return (
    <DataGrid
      sx={sx}
      columns={columns}
      checkboxSelection={checkboxSelection}
      components={{ Toolbar: hasAction ? CustomToolbar : undefined }}
      hideFooter={hideFooter}
      autoHeight
      apiRef={apiRef}
      pageSize={pageSize_}
      rows={rows_}
      loading={loading_}
      filterMode="server"
      onFilterModelChange={onFilterChange}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      getRowId={(row) => row.id}
      onColumnVisibilityModelChange={() => {
        window.localStorage.setItem(`${id}_state`, JSON.stringify(apiRef.current.exportState()));
      }}
      onColumnOrderChange={() => {
        window.localStorage.setItem(`${id}_state`, JSON.stringify(apiRef.current.exportState()));
      }}
      onColumnWidthChange={() => {
        window.localStorage.setItem(`${id}_state`, JSON.stringify(apiRef.current.exportState()));
      }}
      initialState={state}
    />
  );
}

export default DataTable;
