import axios from 'axios';
import useService from '../common/useService';
import { MdiCase } from './mdi-case.model';
import { FeatureToggleHelper } from '../utils/feature-toggle';

const prefix = FeatureToggleHelper.isEnable(process.env.REACT_APP_USE_MDI_MIDDLEWARE_TOGGLER)
  ? `${process.env.REACT_APP_MDI_MIDDLEWARE_URL}/`
  : '';

const useSyncPrescriptions = () => useService(({ id }: { id: string }) => axios.get(`${prefix}mdi/cases/${id}`));

const useSyncCase = () => useService(({ id }: { id: string }) => axios.get<MdiCase>(`${prefix}mdi/sync/${id}`));

const useUpdateCaseLockId = () =>
  useService(({ ...body }: { caseId: string }) => axios.get(`${prefix}mdi/update-lock-id/${body.caseId}`));

const MdiService = {
  useSyncPrescriptions,
  useSyncCase,
  useUpdateCaseLockId,
};

export default MdiService;
