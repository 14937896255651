import { Grid } from '@mui/material';
import { Children, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  md?: number;
}

const SectionContent = ({ children, md: overrideMd }: Props) => {
  const md = (_: number) => {
    if (overrideMd) return overrideMd;
    const count = Children.count(children);
    if (count === 1) return 12;
    else if (count === 2) return 6;
    else if (count === 3) return 4;
    else if (count === 4) return 3;
    else if (count === 5) return 2.4;
    else if (count <= 6) return 2;
    throw new Error('FormRow cannot get more than 6 children');
  };
  const render = Children.map(children, (child, index) => (
    <Grid data-testid={`checking-width-${index}`} item xs={12} md={md(index)}>
      {child}
    </Grid>
  ));
  if (render === undefined) throw new Error('FormRow need to receive at least one child');
  return <>{render}</>;
};

export default SectionContent;
