import axios from 'axios';
import { Paginated } from '../common/Paginated';
import useService from '../common/useService';
import { PageProps } from '../common/ui-component/data-table/filters/backoffice-query';
import { SubscriptionModel } from '../models';

const useSubscriptionsService = () =>
  useService((params: PageProps) =>
    axios.get<Paginated<SubscriptionModel>>(`/v2/subscriptions`, {
      params,
    }),
  );

const useGetSubscription = () => useService((id: string) => axios.get<SubscriptionModel>(`/subscriptions/${id}`));
const useSyncSubscription = () =>
  useService((id: string | number) => axios.get<SubscriptionModel>(`/subscriptions/${id}/sync`));

const useUpdateSubscription = () =>
  useService(({ id, subscription }: { id: string | number; subscription: SubscriptionModel }) =>
    axios.put<SubscriptionModel>(`/subscriptions/${id}`, { subscription }),
  );

const useSyncSubscriptionWithWoo = () => useService((id: number) => axios.post(`/subscriptions/${id}/sync`));

const SubscriptionsService = {
  useSubscriptionsService,
  useGetSubscription,
  useUpdateSubscription,
  useSyncSubscription,
  useSyncSubscriptionWithWoo,
};

export default SubscriptionsService;
