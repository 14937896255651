import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import HealthUpdateTrackerService from './health-update.tracker.service';
import { useEffect } from 'react';
import { Header, Icons, Link, Page, SectionContent } from '../common/ui-component';
import Section from '../common/ui-component/layouts/Section.v2';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent.v2';
import { HealthUpdateTrackerModel, HealthUpdateTrackerSubscriptionModel } from '../models';
import { Container } from '@mui/material';
import { CUSTOMERS_ROUTE } from '../Router';
import { Box, Info } from '../common/ui-component/layouts';
import HealthUpdateHelper from './health-update.helper';

const DetailPage = () => {
  const { t } = useTranslation('health_update_tracker');
  const { id } = useParams();
  const [fetchHealthUpdateTracker, rHealthUpdateTracker] = HealthUpdateTrackerService.useGetHealthUpdateTrackerById();

  useEffect(() => {
    (async () => {
      await fetchHealthUpdateTracker(id);
    })();
  }, [id, fetchHealthUpdateTracker]);

  return (
    <Container maxWidth="xl">
      <Page.Content>
        <RemoteDataContent value={rHealthUpdateTracker}>
          <RemoteDataContent.Success>
            {(healthUpdateTrackerModel: HealthUpdateTrackerModel) => {
              return (
                <>
                  <Header
                    title={`${t('healthUpdateTracker')} #${id}`}
                    details={[
                      <span style={{ marginRight: '1rem' }} key={id}>
                        <Link to={`${CUSTOMERS_ROUTE}/${healthUpdateTrackerModel.customerId}`}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {`${t('customer')} ${healthUpdateTrackerModel.customerId}`}
                            <Icons.Launch />
                          </Box>
                        </Link>
                      </span>,
                    ]}
                  />
                  <Section>
                    <Section.Title>{t('details')}</Section.Title>
                    <SectionContent>
                      <Info label={t('email')}>{healthUpdateTrackerModel.email}</Info>
                      <Info label={t('subscriptionStatus')}>
                        {HealthUpdateHelper.getSubscriptionStatus(
                          t,
                          healthUpdateTrackerModel.subscriptions as never as HealthUpdateTrackerSubscriptionModel[],
                        )}
                      </Info>
                      <Info label={t('healthUpdateDueDate')}>
                        <Info.DateTime>{healthUpdateTrackerModel.healthUpdateDueDate || ''}</Info.DateTime>
                      </Info>
                    </SectionContent>
                    <SectionContent>
                      <Info label={t('encounterCreated')}>
                        <Info.DateTime>{healthUpdateTrackerModel.encounterCreated || ''}</Info.DateTime>
                      </Info>
                      <Info label={t('prescriptionSigned')}>
                        <Info.DateTime>{healthUpdateTrackerModel.prescriptionSigned || ''}</Info.DateTime>
                      </Info>
                      <Info label={t('crmEntered')}>
                        <Info.DateTime>{healthUpdateTrackerModel.crmEntered || ''}</Info.DateTime>
                      </Info>
                    </SectionContent>
                    <SectionContent>
                      <Info label={t('crmConverted')}>{healthUpdateTrackerModel.crmConverted}</Info>
                      <Info label={t('mdiConverted')}>{healthUpdateTrackerModel.mdiConverted}</Info>
                      <Info label={t('fallbackMdConverted')}>{healthUpdateTrackerModel.fallbackMdConverted}</Info>
                    </SectionContent>
                    <SectionContent>
                      <Info label={t('firstAssignedMD')}>{healthUpdateTrackerModel.firstAssignedMD}</Info>
                      <Info label={t('prescribingMD')}>{healthUpdateTrackerModel.prescribingMD}</Info>
                      <Info label={t('fallbackMD')}>{healthUpdateTrackerModel.fallbackMD}</Info>
                    </SectionContent>
                  </Section>
                </>
              );
            }}
          </RemoteDataContent.Success>
        </RemoteDataContent>
      </Page.Content>
    </Container>
  );
};

export default DetailPage;
