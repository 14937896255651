import axios from 'axios';
import useService from '../common/useService';

const useAssignCase = () =>
  useService(({ orderId, ...body }: { orderId: string; caseId: string; status: undefined | 'Approved' }) =>
    axios.put(`/orders/${orderId}`, body),
  );

const useRegeneratCase = () => useService(({ id }: { id: string }) => axios.get(`/cases/${id}`));

const CaseService = {
  useAssignCase,
  useRegenerateCase: useRegeneratCase,
};

export default CaseService;
