import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

interface Props<T> {
  values: T[];
  label: string;
  toOption: (t: T) => { id: string; value: T; displayText: string };
  items: T[];
  onChange: (t: T[] | undefined) => void;
  readonly?: boolean;
  multiple?: boolean;
  required?: boolean;
}

const MultipleDropdownField = <T,>({ values, label, toOption, items, onChange, readonly, required }: Props<T>) => {
  const handleChange = (
    event:
      | (Event & {
          target: {
            value: string | number | readonly string[] | undefined;
            name: string;
          };
        })
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    const ids = event.target.value;

    if (Array.isArray(ids)) {
      const selected = items.filter((item) =>
        ids.some((v) => {
          return toOption(item).id === v;
        }),
      );

      onChange(selected);
      return;
    }

    onChange(undefined);
  };

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={values.map((x) => toOption(x).id)}
        label={label}
        onChange={handleChange}
        readOnly={readonly}
        multiple
        required={required}
      >
        {items.map((option: T) => {
          const opt = toOption(option);
          return (
            <MenuItem key={opt.id} value={opt.id}>
              {opt.displayText}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default MultipleDropdownField;
