import { Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Paginated } from '../common/Paginated';
import { BtnPrimary, Icons, Info, Link, SectionContent } from '../common/ui-component';
import { RoutedModal } from '../common/ui-component/modals';
import { MedicalCaseModel, OrderModel } from '../models';
import { MEDICAL_CASE_ROUTE, ORDERS_ROUTE, SUBSCRIPTIONS_ROUTE } from '../Router';
import Helper from '../utils/date/helper';
import { map, RemoteData, withDefault } from '../utils/remote-data';
import { TooltipsV2 } from '../common/ui-component/tooltips';
import Section from '../common/ui-component/layouts/Section.v2';
import { BillingIntervalForm } from './billing-interval';
import { NextPaymentDateForm } from './next-payment-date';
import { SubscriptionModel } from '../models';
import { Btn } from '../common/ui-component/buttons';

export const billingIntervalHash = '#billing';
export const nextPaymentDateHash = '#nextPayment';

export default function SubscriptionDetails({
  subscription,
  rMedicalCase,
  rOrders,
  syncModalHref,
}: {
  subscription: SubscriptionModel;
  rMedicalCase: RemoteData<MedicalCaseModel, unknown>;
  rOrders: RemoteData<Paginated<OrderModel>, unknown>;
  syncModalHref: string;
}) {
  const { t } = useTranslation('subscription');

  const [subscriptionToUpdateBillingInterval, setSubscriptionToUpdateBillingInterval] = useState<SubscriptionModel>();
  const [subscriptionToUpdateNextPaymentDate, setSubscriptionToUpdateNextPaymentDate] = useState<SubscriptionModel>();

  const currentRoute = `${SUBSCRIPTIONS_ROUTE}/${subscription.id}`;

  return (
    <Section>
      <Section.Title>{t('subscriptionDetails')}</Section.Title>
      <Section.Actions>
        <span>
          <TooltipsV2.Content>
            <BtnPrimary onClick={() => setSubscriptionToUpdateBillingInterval(subscription)}>
              <Link to={billingIntervalHash} replace>
                {t('changeBillingInterval')}
              </Link>
            </BtnPrimary>
          </TooltipsV2.Content>
        </span>
        <span>
          <TooltipsV2.Content>
            <BtnPrimary onClick={() => setSubscriptionToUpdateNextPaymentDate(subscription)}>
              <Link to={nextPaymentDateHash} replace>
                {t('changeNextPaymentDate')}
              </Link>
            </BtnPrimary>
          </TooltipsV2.Content>
        </span>
      </Section.Actions>
      <SectionContent>
        <Info label={t('created')}>{Helper.onlyDate(subscription?.dateCreated as string).value}</Info>
        <Info label={t('woocommerceId')}>
          <Link
            // eslint-disable-next-line max-len
            href={`${process.env.REACT_APP_WOOCOMMERCE_URL}/post.php?post=${subscription?.woocommerceId}&action=edit`}
          >
            {subscription?.woocommerceId}
          </Link>
          <Link to={syncModalHref}>
            <Btn sx={{ width: '24px', minWidth: 0, margin: 0, padding: 0 }}>
              <Tooltip title={t('syncSubscriptionWithWoo')}>
                <Icons.Sync />
              </Tooltip>
            </Btn>
          </Link>
        </Info>
        <Info label={t('medicalCaseId')}>
          {withDefault(<span>--</span>)(
            map((x: MedicalCaseModel) => <Link href={`${MEDICAL_CASE_ROUTE}/${x.id}`}>{x.id}</Link>)(rMedicalCase),
          )}
        </Info>
      </SectionContent>
      <SectionContent>
        <Info label={t('billingInterval')}>{`${subscription.billingInterval} ${subscription.billingPeriod}`}</Info>
        <Info label={t('nextPaymentDate')}>{subscription.nextPaymentDate || 'n/a'}</Info>
        <Info label={t('endDate')}>{subscription.endDate || 'n/a'}</Info>
      </SectionContent>
      <SectionContent>
        <Info label={t('orderIds')}>
          {withDefault(<span>--</span>)(
            map((x: Paginated<OrderModel>) => (
              <>
                {x.data
                  .filter((o) => o.subscriptionIds.some((s) => s === subscription.id))
                  .map((o) => (
                    <Link href={`${ORDERS_ROUTE}/${o.id}`}>{o.id}</Link>
                  ))}
              </>
            ))(rOrders),
          )}
        </Info>
      </SectionContent>
      <SectionContent>
        <Typography variant="subtitle2" display="block" my={1} sx={{ textDecoration: 'underline' }}>
          {t('products')}
        </Typography>
      </SectionContent>

      <SectionContent>
        <Info label={t('sku')}>{subscription.lineItems.map((s) => s.sku).join(' / ')}</Info>
      </SectionContent>
      <RoutedModal predicatedHash={billingIntervalHash} defaultUrl={currentRoute} title={t('billingInterval')}>
        {(onClose: () => void) => (
          <BillingIntervalForm subscription={subscriptionToUpdateBillingInterval} onSuccess={onClose} close={onClose} />
        )}
      </RoutedModal>
      <RoutedModal predicatedHash={nextPaymentDateHash} defaultUrl={currentRoute} title={t('nextPaymentDate')}>
        {(onClose: () => void) => (
          <NextPaymentDateForm subscription={subscriptionToUpdateNextPaymentDate} onSuccess={onClose} close={onClose} />
        )}
      </RoutedModal>
    </Section>
  );
}
