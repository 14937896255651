import { Typography } from '@mui/material';

type LabelProps = {
  children: string | JSX.Element;
  underline?: boolean;
};

const Label = ({ children, underline }: LabelProps) => (
  <Typography variant="subtitle2" display="block" sx={{ textDecoration: underline ? 'underline' : 'none' }}>
    {children}
  </Typography>
);

export default Label;
