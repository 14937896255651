import SendIcon from '@mui/icons-material/Send';

const Send = ({
  color,
}: {
  color?:
    | 'inherit'
    | 'warning'
    | 'disabled'
    | 'action'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | undefined;
}) => <SendIcon color={color || 'success'} />;

export default Send;
