import { GridColumns, GridRowId, GridRowParams, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Paginated } from '../common/Paginated';
import {
  BtnDanger,
  BtnPrimary,
  BtnSuccess,
  DataTablePro as DataTable,
  Icons,
  Link,
  Tooltips,
} from '../common/ui-component';
import { RemoteData, isSuccess, notAsked } from '../utils/remote-data';
import { DateHelper } from '../utils/date';
import { DeadLetterModel } from './dead-letter.model';
import DeadLetterService from './dead-letter.service';
import { PageProps } from '../common/ui-component/data-table/filters/backoffice-query';
import { DateFormatter } from '../common/ui-component/data-table';

const getActions =
  ({
    retryDeadLetter,
    deleteDeadLetter,
  }: {
    retryDeadLetter: (id: GridRowId) => void;
    deleteDeadLetter: (id: GridRowId) => void;
  }) =>
  ({ id }: GridRowParams) => {
    return [
      <Link to={`/dead-letter/${id}`}>
        <BtnSuccess>
          <Icons.Visibility />
        </BtnSuccess>
      </Link>,
      <BtnPrimary onClick={() => retryDeadLetter(id)}>
        <Icons.Replay />
      </BtnPrimary>,
      <BtnDanger onClick={() => deleteDeadLetter(id)}>
        <Icons.Delete />
      </BtnDanger>,
    ];
  };

interface Props {
  defaultParams?: PageProps;
  getDeadLetters: (params?: PageProps | undefined) => Promise<RemoteData<Paginated<DeadLetterModel<unknown>>, unknown>>;
  deadLetters: RemoteData<Paginated<DeadLetterModel<unknown>>, unknown>;
  hasToolbar?: boolean;
  filterOut?: string[];
}

const DeadLetterList = ({ defaultParams, getDeadLetters, deadLetters, hasToolbar = true, filterOut = [] }: Props) => {
  const { t } = useTranslation('deadLetter');

  const [forceReload, setForceRelaod] = useState(false);

  const fetcher = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (param: any) => getDeadLetters({ ...defaultParams, ...param }),
    [getDeadLetters, defaultParams],
  );

  const [retryDeadLetter, retryResponse, setRetryResponse] = DeadLetterService.useRetryDeadLetter();

  const [deleteDeadLetter, deleteResponse, setDeleteResponse] = DeadLetterService.useDeleteDeadLetter();

  const getColumns = (): GridColumns => {
    const cols: GridColumns = [
      { headerName: t('id'), field: 'id' },
      { headerName: t('context'), field: 'context', flex: 0.75 },
      {
        headerName: t('value'),
        field: 'queueMessage',
        flex: 1,
        renderCell: (params) => {
          if (typeof params.value === 'string' || params.value instanceof String) {
            return (
              <Tooltips value={<pre>{params.value}</pre>}>
                <pre>{params.row.queueKey}</pre>
              </Tooltips>
            );
          }

          return (
            <Tooltips value={<pre>{JSON.stringify(params.value, undefined, 2)}</pre>}>
              <pre style={{ overflow: 'auto' }}>{params.row.queueKey}</pre>
            </Tooltips>
          );
        },
      },
      {
        headerName: t('error'),
        field: 'error',
        flex: 1,
        renderCell: function (params) {
          return (
            <Tooltips
              value={(() => {
                if (typeof params.value === 'string') {
                  return <pre>{params.value}</pre>;
                }

                return <pre>{JSON.stringify(JSON.parse(params.value), undefined, 2)}</pre>;
              })()}
            >
              <pre>{params.value}</pre>
            </Tooltips>
          );
        },
      },
      {
        headerName: t('created'),
        field: 'created',
        width: 190,
        valueFormatter: (param: GridValueFormatterParams<Date>) => {
          return DateHelper.dateTime(param.value).value;
        },
      },
      {
        headerName: t('createdWithTime'),
        field: 'createdWithTime',
        width: 190,
        valueFormatter: DateFormatter.withTime,
        filterable: false,
        sortable: false,
      },
      {
        field: 'actions',
        type: 'actions',
        flex: 1,
        getActions: getActions({ retryDeadLetter, deleteDeadLetter }),
      },
    ];

    return filterOut.length ? cols.filter((x) => !filterOut.some((y) => y === x.field)) : cols;
  };

  useEffect(() => {
    if (isSuccess(retryResponse)) {
      setRetryResponse(notAsked());
      setForceRelaod(true);
    } else {
      setForceRelaod(false);
    }
  }, [retryResponse, setRetryResponse]);

  useEffect(() => {
    if (isSuccess(deleteResponse)) {
      setDeleteResponse(notAsked());
      setForceRelaod(true);
    } else {
      setForceRelaod(false);
    }
  }, [deleteResponse, setDeleteResponse]);

  return (
    <DataTable
      id="dead-letter-table"
      cols={getColumns()}
      forceReload={forceReload}
      fetcher={fetcher}
      datas={deadLetters}
      toRow={<T,>(deathLetter: DeadLetterModel<T>) => ({
        ...deathLetter,
        createdWithTime: deathLetter.created,
        headerAlign: 'center',
      })}
      initialColumnVisibilityModel={{ createdWithTime: false }}
      toCsvRow={<T,>(deathLetter: DeadLetterModel<T>) => ({
        ...deathLetter,
        queueMessage: JSON.stringify(deathLetter.queueMessage),
        error: JSON.stringify(deathLetter.error),
        headerAlign: 'center',
      })}
    />
  );
};

export default DeadLetterList;
