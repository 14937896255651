import { Chip } from '../common/ui-component';

const OrderStatusBadge = ({ status }: { status: string }) => {
  let color = '';
  switch (status) {
    case 'pending-photo': {
      color = 'warning';
      break;
    }

    case 'completed': {
      color = 'success';
      break;
    }

    case 'cancelled': {
      color = 'error';
      break;
    }

    case 'refunded': {
      color = 'error';
      break;
    }
    case 'failed': {
      color = 'primary';
      break;
    }
    case 'awaiting-review': {
      color = 'warning';
      break;
    }
    case 'pending-payment': {
      color = 'warning';
      break;
    }
    case 'processing': {
      color = 'warning';
      break;
    }
    case 'on-hold': {
      color = 'warning';
      break;
    }
    case 'support': {
      color = 'warning';
      break;
    }
    case 'shipping-pre-transit': {
      color = 'success';
      break;
    }
    case 'shipping-transit': {
      color = 'success';
      break;
    }
    case 'shipping-delivered': {
      color = 'success';
      break;
    }
    case 'shipping-returned': {
      color = 'error';
      break;
    }
    case 'shipping-failure': {
      color = 'error';
      break;
    }
  }

  return <Chip text={status} color={color} />;
};

export default OrderStatusBadge;
