import { GridValueFormatterParams } from '@mui/x-data-grid';
import { result } from '../../../../utils/result';
import { DateHelper } from '../../../../utils/date';

const dateFormatter = ({ value }: GridValueFormatterParams) => {
  const format = (date: Date) => {
    const yymmdd = result.fold(
      (e: string) => e,
      (d: string) => d,
    )(DateHelper.onlyDate(date));
    return yymmdd;
  };
  if (value instanceof Date) return format(value);
  else if (typeof value === 'string') return format(new Date(value));

  return '';
};

const dateFormatterWithTime = ({ value }: GridValueFormatterParams) => {
  const format = (date: Date) => {
    const yymmdd = result.fold(
      (e: string) => e,
      (d: string) => d,
    )(DateHelper.dateTime(date));
    return yymmdd;
  };
  if (value instanceof Date) return format(value);
  else if (typeof value === 'string') return format(new Date(value));

  return '';
};

const dateFormatterDOB = ({ value }: GridValueFormatterParams) => {
  if (value instanceof Date) return value.toLocaleDateString(navigator.language);
  else if (typeof value === 'string') return new Date(value).toLocaleDateString(navigator.language);

  return '';
};

const DateFormatter = {
  normalize: dateFormatter,
  withTime: dateFormatterWithTime,
  dateOfBirth: dateFormatterDOB,
};

export default DateFormatter;
