import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Header, Icons, Info, Section, SectionContent } from '../common/ui-component';
import { Box, Container, IconButton, Typography } from '@mui/material';

import PendingOrdersService from './pendingOrder.service';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent';
import Helper from '../utils/date/helper';
import { PendingOrderModel } from '../models';

const PendingOrder = () => {
  const { t } = useTranslation('pending_order');
  const { id } = useParams();
  const [show, setShow] = useState<boolean>(true);
  const [getPendingOrder, rPendingOrder] = PendingOrdersService.useGetPendingOrder();

  useEffect(() => {
    getPendingOrder(id);
  }, [id, getPendingOrder]);

  return (
    <Container maxWidth="xl">
      <RemoteDataContent
        value={rPendingOrder}
        whenSuccess={(pOrder: PendingOrderModel) => (
          <>
            <Header title={`${t('pendingOrder')} #${pOrder.id}`} />
            <Section title={t('details')}>
              <SectionContent>
                <Info label={t('email')}>{pOrder.email}</Info>
                <Info label={t('gender')}>{pOrder.gender}</Info>
                <Info label={t('dob')}>{Helper.onlyDateOfBirth(pOrder.dateOfBirth).value}</Info>
              </SectionContent>
              <SectionContent>
                <Info label={t('uniqueId')}>{pOrder.uniqueId}</Info>
                <Info label={t('created')}>{Helper.onlyDateOfBirth(pOrder.created).value}</Info>
                <Info label={t('updated')}>{Helper.onlyDateOfBirth(pOrder.updated).value}</Info>
              </SectionContent>
              <SectionContent>
                <Box>
                  <Typography variant="subtitle2" display="block">
                    {t('questions')}
                    <IconButton onClick={() => setShow(!show)}>
                      {show ? <Icons.ChevronUp /> : <Icons.ChevronDown />}
                    </IconButton>
                  </Typography>
                  {show
                    ? Object.entries(pOrder.questions).map(([key, question]) => (
                        <Typography variant="subtitle1" key={key} mb={2}>
                          {question?.longName}
                          <Typography sx={{ fontWeight: 'bold' }}>{question?.value}</Typography>
                          <Typography sx={{ fontWeight: 'bold' }}>{question?.description}</Typography>
                        </Typography>
                      ))
                    : ''}
                </Box>
              </SectionContent>
            </Section>
          </>
        )}
      />
    </Container>
  );
};

export default PendingOrder;
