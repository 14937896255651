import { Card, CardActions, CardMedia, Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { pink, green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { Icons } from '.';

const useStyles = makeStyles((theme) => ({
  parentFlexRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
interface Props {
  photos: {
    id?: number;
    title: string;
    url: string;
    isSync?: boolean;
  }[];
  onDeletePhoto?: (id: number) => void;
  onSyncPhoto?: (id: number) => void;
}

const Gallery = ({ photos, onDeletePhoto, onSyncPhoto }: Props) => {
  const classes = useStyles();
  return (
    <Grid container rowSpacing={1} sx={{ minHeight: '194px' }}>
      {photos.map(({ id, title, url, isSync }) => (
        <Grid item xs={12} sm={6} md={4} key={url}>
          <Card sx={{ maxWidth: 345 }}>
            <h2>{title}</h2>
            <CardMedia component="img" height="194" image={url} alt={title} />
            {onDeletePhoto && id && id >= 0 && (
              <CardActions disableSpacing className={classes.parentFlexRight}>
                <IconButton
                  onClick={(e) => {
                    onDeletePhoto(id);
                  }}
                >
                  <DeleteIcon sx={{ color: pink[500] }} />
                </IconButton>
                {!isSync && onSyncPhoto && (
                  <IconButton
                    onClick={(e) => {
                      onSyncPhoto(id);
                    }}
                  >
                    <Icons.Sync sx={{ color: green[500] }} />
                  </IconButton>
                )}
              </CardActions>
            )}
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Gallery;
