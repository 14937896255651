import { AccessTokenProvider } from './authentication';
import Router from './Router';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
  '763e1dc3c4084e7c6b8a36fe622819c7Tz04MjAzNixFPTE3MzY2MTc0MDIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

function App() {
  return (
    <AccessTokenProvider>
      <Router />
    </AccessTokenProvider>
  );
}

export default App;
