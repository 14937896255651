import {
  getGridDateOperators,
  getGridStringOperators,
  GridFilterItem,
  getGridBooleanOperators,
  GridFilterInputDate,
  GridFilterInputDateProps,
  getGridSingleSelectOperators,
} from '@mui/x-data-grid-pro';
import { DateTime } from 'luxon';

const getGridArrayHasValueOperators = () => [
  {
    ...getGridBooleanOperators()[0],
    label: 'is filled',
    value: 'is-filled-array',
  },
];

const getGridSelectOperators = () => [
  {
    ...getGridSingleSelectOperators()[0],
    label: 'equals',
    value: 'equals',
  },
];

const getGridArrayOperators = () => [
  {
    ...getGridStringOperators()[0],
    label: 'contains',
    value: 'contains',
  },
];

const getGridJsonArrayOperators = () => [
  {
    ...getGridStringOperators()[0],
    label: 'json-array-contains',
    value: 'json-array-contains',
  },
];

const getGridNullOperators = () => [
  {
    ...getGridBooleanOperators()[0],
    label: 'has',
    value: 'has',
  },
];

const getGridHasAllPhotos = () => [
  {
    ...getGridBooleanOperators()[0],
    label: 'is',
    value: 'has-all-photos-uploaded',
  },
];

const getGridIsBooleanOperator = () => [
  {
    ...getGridBooleanOperators()[0],
    label: 'is',
    value: 'is',
  },
];

const getGridIsEmptyObjectOperators = () => [
  {
    ...getGridBooleanOperators()[0],
    label: 'is empty',
    value: 'is-empty-object',
  },
];

const GridFilterInputDate_ = (props: GridFilterInputDateProps) =>
  GridFilterInputDate({
    ...props,
    applyValue: (filterItem: GridFilterItem) => {
      props.applyValue({
        ...filterItem,
        value: DateTime.fromISO(filterItem.value).toUTC().toFormat('yyyy-MM-dd HH:mm:ss'),
      });
    },
    item: {
      ...props.item,
      value: props.item.value && DateTime.fromFormat(props.item.value, 'yyyy-MM-dd HH:mm:ss').toISODate(),
    },
  });

const getGridDateOperators_ = (showTime?: boolean) => [
  {
    ...getGridDateOperators(showTime)[0],
    label: 'is on',
    value: 'on',
    InputComponent: GridFilterInputDate_,
  },
  {
    ...getGridDateOperators(showTime)[2],
    InputComponent: GridFilterInputDate_,
  },
  {
    ...getGridDateOperators(showTime)[3],
    InputComponent: GridFilterInputDate_,
  },

  {
    ...getGridDateOperators(showTime)[4],
    InputComponent: GridFilterInputDate_,
  },
  {
    ...getGridDateOperators(showTime)[5],
    InputComponent: GridFilterInputDate_,
  },
];

const getStringOrEmptyOperators = () => {
  return [
    {
      ...getGridStringOperators()[0],
    },
    {
      ...getGridStringOperators()[4],
      label: 'is empty',
      value: 'is-null',
    },
  ];
};

const FilterOperator = {
  getGridIsEmptyObjectOperators,
  getGridNullOperators,
  getGridArrayHasValueOperators,
  getGridArrayOperators,
  getGridJsonArrayOperators,
  getGridHasAllPhotos,
  getGridIsBooleanOperator,
  getGridDateOperators: getGridDateOperators_,
  getStringOrEmptyOperators,
  getGridSelectOperators,
};

export default FilterOperator;
