const DEAD_LETTER_EN = {
  context: 'Context',
  created: 'Created',
  createdWithTime: 'Created (with time)',
  deadLetter: 'DeadLetter',
  details: 'Details',
  error: 'Error',
  id: 'Id',
  queueKey: 'QueueKey',
  save: 'Save',
  success: 'The Dead Letter queue has been successfully updated!',
  syncAndExit: 'Sync and Exit',
  value: 'Value',
  retryAll: 'Retry all {{value}}',
  displayFailure: 'Display Failure',
  missingClinicianInformation: 'Missing Clinician Information',
  noResolver: 'No Resolver Found For This Dead Letter',
  ResolvingWith: 'Relsolving With',
  fillClinicianInformation: 'Fill Clinician Information',
  getOrder: 'Get the order',
  getMdiCase: 'Get Case From MDI',
  updatingDeadLetter: 'Updating the dead letter',
  sendToRetry: 'Retry It',
  json: 'JSON',
};

export default DEAD_LETTER_EN;
