import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { AuthenticateService, EmailPassword, LoginForm } from '../authentication';
import { useAccessToken } from '../authentication/AccessTokenProvider';
import { loggedIn } from '../authentication/auth';
import { fold, isSuccess } from '../utils/remote-data';
import { ConnectedForm, TextInput } from '../common/form-controller';
import { SEARCH_ROUTE, SECOND_FACTOR_AUTH_ROUTE } from '../Router';
import { BtnPrimary, Centered, ErrorHandler, Loader, Title } from '../common/ui-component';
import GoogleOAuthv2 from './GoogleOAuthv2';
import { FeatureToggleHelper } from '../utils/feature-toggle';
import jwtTokenHelper from '../authentication/jwt-token.helper';
import { GoogleOAuthProvider } from '@react-oauth/google';

const Login = () => {
  const { t } = useTranslation('login');
  const [authenticate, accessToken] = AuthenticateService.useAuthenticate();
  const [, setAccessToken] = useAccessToken();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo');
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

  const submit = (loginForm: LoginForm) => authenticate(loginForm);

  const defaultLoginForm: EmailPassword = {
    _name: 'emailPassword',
    email: '',
    password: '',
  };

  useEffect(() => {
    if (isSuccess(accessToken)) {
      setAccessToken(loggedIn(accessToken.value));
      const isTwoFactorAuthenticated =
        accessToken.value && jwtTokenHelper.isTwoFactorAuthenticated(accessToken.value.access_token);

      if (isTwoFactorAuthenticated) {
        navigate(redirectTo || SEARCH_ROUTE);
      } else {
        navigate({
          pathname: SECOND_FACTOR_AUTH_ROUTE,
          search: createSearchParams({ redirectTo: redirectTo || SEARCH_ROUTE }).toString(),
        });
      }
    }
  }, [accessToken, navigate, setAccessToken, redirectTo]);

  function showLoginForm(e?: Error) {
    return (
      <ConnectedForm<LoginForm> defaultValues={defaultLoginForm} onSubmit={submit}>
        <Title>{t('signIn')}</Title>
        <ErrorHandler err={e} />
        <TextInput name="email" label={t('email')} />
        <TextInput name="password" type="password" label={t('password')} />
        <BtnPrimary type="submit">{t('signIn')}</BtnPrimary>
        {FeatureToggleHelper.isEnable(process.env.REACT_APP_GOOGLE_AUTH_TOGGLER) && (
          <GoogleOAuthProvider clientId={clientId}>
            <GoogleOAuthv2 submit={submit} />
          </GoogleOAuthProvider>
        )}
      </ConnectedForm>
    );
  }

  return (
    <Centered>
      {fold(
        () => showLoginForm(),
        () => <Loader />,
        () => <span>{t('successLogin')}</span>,
        (e) => showLoginForm(e as Error),
      )(accessToken)}
    </Centered>
  );
};

export default Login;
