import axios from 'axios';
import useService from '../common/useService';
import { SubscriptionStatusResponse } from './subscription-status.model';

const useGetSubscriptionStatuses = () =>
  useService((id: string) => axios.get<SubscriptionStatusResponse[]>(`/subscription-status/${id}`));

const SubscriptionStatusService = {
  useGetSubscriptionStatuses,
};

export default SubscriptionStatusService;
