import { DateTime } from 'luxon';
import { Controller, useFormContext } from 'react-hook-form';
import { StaticDatePickerField } from '../ui-component/inputs';

interface Props {
  name: string;
  maxDate?: DateTime;
  label?: string;
}

const StaticDatePickerInput = ({ name, maxDate, label }: Props) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return <StaticDatePickerField onChange={onChange} value={value} maxDate={maxDate} label={label} />;
      }}
    />
  );
};

export default StaticDatePickerInput;
