import React from 'react';
import { GridColumns, GridEnrichedColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { TFunction, useTranslation } from 'react-i18next';
import { DataTablePro as DataTable, Icons, Link, Title, Tooltips } from '../common/ui-component';
import { DateFormatter } from '../common/ui-component/data-table';
import FilterOperator from '../common/ui-component/data-table/filters/filter-operator-pro';
import { OrderFile } from '../files/order-file.model';
import MedicalCaseService from './medical-case.service';
import MedicalCaseValidator from './medical-case.validator';
import {
  MedicalCaseModel,
  MedicalCasePrescriptionModel,
  MedicalCaseRxCompoundModel,
  MedicalCaseRxMedicationModel,
} from '../models';
import { booleanIntegerValueOptions } from '../common/ui-component/data-table/filters/backoffice-query';

const getActions = ({ id }: GridRowParams) => [
  <Link to={`/medical-case/${id}`}>
    <Icons.Edit />
  </Link>,
];

type MedicalCaseColumn = {
  field:
    | keyof MedicalCaseModel
    | 'actions'
    | 'proveVerified'
    | 'virtualActive'
    | 'updatedAtWithTime'
    | 'createdAtWithTime'
    | 'healthUpdateDueDateWithTime'
    | 'lastEncounteredWithTime'
    | 'lastPrescriptionSignedWithTime';
} & GridEnrichedColDef;

const getColumns = (t: TFunction<'medical_case', undefined>): GridColumns => {
  const col: MedicalCaseColumn[] = [
    {
      field: 'subscriptionIds',
      headerName: t('subscriptionIds'),
      filterOperators: FilterOperator.getGridArrayOperators(),
      renderCell: (params: GridRenderCellParams) => {
        return (params.value || []).join(', ');
      },
    },
    {
      field: 'orderIds',
      headerName: t('orderIds'),
      filterOperators: FilterOperator.getGridArrayOperators(),
      renderCell: (params: GridRenderCellParams) => {
        return (params.value || []).join(', ');
      },
    },
    {
      field: 'email',
      headerName: t('email'),
    },
    {
      field: 'photos',
      headerName: t('hasPhotos'),
      renderHeader: () => (
        <Tooltips value={t('hasPhotos')}>
          <span>{t('hasPhotos')}</span>
        </Tooltips>
      ),
      filterOperators: FilterOperator.getGridHasAllPhotos(),
      renderCell: (params: GridRenderCellParams) => {
        const errors = MedicalCaseValidator.getPhotosErrors(params.row, t);

        if (!errors.length)
          return (
            <Tooltips
              value={JSON.stringify(
                params.value.reduce(
                  (count: { hair: number; license: number }, curr: OrderFile) =>
                    curr.type === 'other'
                      ? { ...count, hair: count.hair + 1 }
                      : { ...count, license: count.license + 1 },
                  {
                    hair: 0,
                    license: 0,
                  },
                ),
              )}
            >
              <span>
                <Icons.Checked />
              </span>
            </Tooltips>
          );

        return (
          <Tooltips
            value={
              <div>
                {errors.map((error) => (
                  <div key={error}>{error}</div>
                ))}
              </div>
            }
          >
            <span>
              <Icons.Cancel />
            </span>
          </Tooltips>
        );
      },
    },
    {
      field: 'patientId',
      headerName: t('hasPatientId'),
      renderHeader: () => (
        <Tooltips value={t('patientId')}>
          <span>{t('hasPatientId')}</span>
        </Tooltips>
      ),

      filterOperators: FilterOperator.getGridNullOperators(),
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.patientId)
          return (
            <Tooltips value={params.value}>
              <span>
                <Icons.Checked />
              </span>
            </Tooltips>
          );

        return (
          <Tooltips
            value={
              <div>
                {MedicalCaseValidator.getPatientIdErrors(params.row, t).map((error) => (
                  <div key={error}>{error}</div>
                ))}
              </div>
            }
          >
            <span>
              <Icons.Cancel />
            </span>
          </Tooltips>
        );
      },
    },
    {
      field: 'medicalInformation',
      headerName: t('hasMedicalQuestionFilled'),
      renderHeader: () => (
        <Tooltips value={t('medicalQuestion')}>
          <span>{t('hasMedicalQuestionFilled')}</span>
        </Tooltips>
      ),

      filterOperators: FilterOperator.getGridArrayHasValueOperators(),
      renderCell: (params: GridRenderCellParams) => {
        const errors = MedicalCaseValidator.getMedicalInformationErrors(params.row, t);
        if (!errors.length) return <Icons.Checked />;

        return (
          <Tooltips
            value={errors.map((error) => (
              <div key={error}>{error}</div>
            ))}
          >
            <span>
              <Icons.Cancel />
            </span>
          </Tooltips>
        );
      },
    },
    {
      field: 'prescriptions',
      headerName: t('hasPrescriptions'),
      renderHeader: () => (
        <Tooltips value={t('prescriptions')}>
          <span>{t('hasPrescriptions')}</span>
        </Tooltips>
      ),

      filterOperators: FilterOperator.getGridArrayHasValueOperators(),
      renderCell: (params: GridRenderCellParams) => {
        if (
          (params.row.claimedProducts || []).length &&
          (params.row.claimedProducts || []).every((cp: MedicalCaseRxMedicationModel | MedicalCaseRxCompoundModel) =>
            (params.value || []).some((p: MedicalCasePrescriptionModel) => cp.sku === p.medication.sku),
          )
        )
          return (
            <Tooltips value={params.value.map((x: MedicalCasePrescriptionModel) => x.medication.sku).join(', ')}>
              <span>
                <Icons.Checked />
              </span>
            </Tooltips>
          );

        return <Icons.Cancel />;
      },
    },
    {
      field: 'lastPrescriptionSigned',
      headerName: t('lastPrescriptionSigned'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.dateOfBirth,
    },
    {
      field: 'lastPrescriptionSignedWithTime',
      headerName: t('lastPrescriptionSignedWithTime'),
      headerAlign: 'center',
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'lastEncountered',
      headerName: t('lastEncountered'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.dateOfBirth,
    },
    {
      field: 'lastEncounteredWithTime',
      headerName: t('lastEncounteredWithTime'),
      headerAlign: 'center',
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'proveVerified',
      headerName: t('proveVerified'),
      filterable: false,
      sortable: false,
      minWidth: 70,
      renderCell: (params: GridRenderCellParams) => (params.value ? <Icons.Checked /> : <Icons.Cancel />),
    },
    {
      field: 'driverLicenseRequired',
      headerName: t('idRequired'),
      filterOperators: FilterOperator.getGridIsBooleanOperator(),
      minWidth: 70,
      renderCell: (params: GridRenderCellParams) => (params.value ? <Icons.Checked /> : <Icons.Cancel />),
    },
    {
      field: 'videoRequired',
      headerName: t('videoRequired'),
      filterOperators: FilterOperator.getGridIsBooleanOperator(),
      minWidth: 70,
      renderCell: (params: GridRenderCellParams) => (params.value ? <Icons.Checked /> : <Icons.Cancel />),
    },
    {
      field: 'healthUpdateDueDate',
      headerName: t('healthUpdateDueDate'),
      filterOperators: FilterOperator.getGridDateOperators(),
      valueFormatter: DateFormatter.dateOfBirth,
    },
    {
      field: 'healthUpdateDueDateWithTime',
      headerName: t('healthUpdateDueDateWithTime'),
      headerAlign: 'center',
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'virtualActive',
      headerName: t('casesAllowed'),
      filterOperators: FilterOperator.getGridSelectOperators(),
      valueOptions: booleanIntegerValueOptions,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.active) return <Icons.Checked />;

        return <Icons.Cancel />;
      },
    },
    {
      field: 'subscriptionActive',
      headerName: t('hasActiveSub'),
      filterOperators: FilterOperator.getGridSelectOperators(),
      valueOptions: booleanIntegerValueOptions,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.subscriptionActive) return <Icons.Checked />;

        return <Icons.Cancel />;
      },
    },
    {
      field: 'createdAt',
      headerName: t('created'),
      headerAlign: 'center',
      filterOperators: FilterOperator.getGridDateOperators(),

      valueFormatter: DateFormatter.normalize,
    },
    {
      field: 'createdAtWithTime',
      headerName: t('createdWithTime'),
      headerAlign: 'center',
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'updatedAt',
      headerName: t('updated'),
      headerAlign: 'center',

      valueFormatter: DateFormatter.normalize,
      filterOperators: FilterOperator.getGridDateOperators(),
    },
    {
      field: 'updatedAtWithTime',
      headerName: t('updatedWithTime'),
      headerAlign: 'center',
      filterable: false,
      sortable: false,
      valueFormatter: DateFormatter.withTime,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions,
    },
  ];

  return col;
};

function ListPage() {
  const { t } = useTranslation('medical_case');
  const [fetchMedicalCases, rMedicalCases] = MedicalCaseService.useGetMedicalCases();

  return (
    <>
      <Title>{t('medicalCases')}</Title>

      <DataTable
        id="medical-case-table"
        cols={getColumns(t)}
        fetcher={fetchMedicalCases}
        datas={rMedicalCases}
        toRow={(medicalCase: MedicalCaseModel) => ({
          ...medicalCase,
          updatedAtWithTime: medicalCase.updatedAt,
          createdAtWithTime: medicalCase.createdAt,
          healthUpdateDueDateWithTime: medicalCase.healthUpdateDueDate,
          lastEncounteredWithTime: medicalCase.lastEncountered,
          lastPrescriptionSignedWithTime: medicalCase.lastPrescriptionSigned,
          proveVerified: !medicalCase.driverLicenseRequired,
          virtualActive: medicalCase.active,
        })}
        initialColumnVisibilityModel={{
          updatedAtWithTime: false,
          createdAtWithTime: false,
          healthUpdateDueDateWithTime: false,
          lastEncounteredWithTime: false,
          lastPrescriptionSignedWithTime: false,
        }}
        toCsvRow={(medicalCase: MedicalCaseModel) => {
          const photoErrors = MedicalCaseValidator.getPhotosErrors(medicalCase, t);
          const medicalInformationErrors = MedicalCaseValidator.getMedicalInformationErrors(medicalCase, t);
          return {
            ...medicalCase,
            photos: !photoErrors,
            orderIds: medicalCase.orderIds?.join(', '),
            subscriptionIds: medicalCase.subscriptionIds?.join(', '),
            patientId: !!medicalCase.patientId,
            medicalInformation: !medicalInformationErrors,
            prescriptions:
              (medicalCase.claimedProducts || []).length &&
              (medicalCase.claimedProducts || []).every(
                (cp: MedicalCaseRxMedicationModel | MedicalCaseRxCompoundModel) =>
                  (medicalCase.prescriptions || []).some(
                    (p: MedicalCasePrescriptionModel) => cp.sku === p.medication.sku,
                  ),
              ),
            proveVerified: !medicalCase.driverLicenseRequired,
            virtualActive: medicalCase.active,
            updatedAtWithTime: medicalCase.updatedAt,
            createdAtWithTime: medicalCase.createdAt,
            healthUpdateDueDateWithTime: medicalCase.healthUpdateDueDate,
            lastEncounteredWithTime: medicalCase.lastEncountered,
            lastPrescriptionSignedWithTime: medicalCase.lastPrescriptionSigned,
          };
        }}
      />
    </>
  );
}

export default ListPage;
