import axios from 'axios';
import { Paginated } from '../common/Paginated';
import { PageProps as DeprecatedPageProps } from '../common/ui-component/data-table';
import useService from '../common/useService';
import { PharmacyOrder } from './pharmacy-order.model';
import { ReplacementDTO } from './replacement/replace-order.dto';
import { OrderShipping } from './shipping-form/order-shipping.dto';
import { PharmacyOrderHistory } from '../models';
import { PageProps } from '../common/ui-component/data-table/filters/backoffice-query';

const useGetOrders = () =>
  useService((params: DeprecatedPageProps) =>
    axios.get<Paginated<PharmacyOrder>>(`/pharmacy`, {
      params,
    }),
  );

const useGetOrdersV2 = () =>
  useService((params: PageProps) =>
    axios.get<Paginated<PharmacyOrder>>(`/v2/pharmacy`, {
      params,
    }),
  );

const useCancelOrder = () => useService((id: number) => axios.post(`/pharmacy/${id}/cancel`));

const useReplaceOrder = () =>
  useService(({ id, replacement }: { id: number; replacement: ReplacementDTO }) =>
    axios.post<PharmacyOrder>(`/pharmacy/${id}/replace`, replacement),
  );

const useShippingOrder = () =>
  useService(({ id, orderShipping }: { id: number; orderShipping: OrderShipping }) =>
    axios.post<PharmacyOrder>(`/pharmacy/${id}/shipping`, orderShipping),
  );

const useResendOrder = () =>
  useService(({ id, orderShipping }: { id: number; orderShipping: OrderShipping }) =>
    axios.post<PharmacyOrder>(`/pharmacy/${id}/resend`, orderShipping),
  );

const useGetOrdersByOrderId = () => useService((orderId: number) => axios.get<PharmacyOrder[]>(`/pharmacy/${orderId}`));

const useGetOrder = () => useService((id: number) => axios.get<PharmacyOrder>(`v2/pharmacy/${id}`));

const useGetPharmacyOrderHistory = () =>
  useService((id: number) => axios.get<Paginated<PharmacyOrderHistory>>(`/pharmacy-history/${id}`));

const PharmacyService = {
  useGetOrders,
  useGetOrdersV2,
  useCancelOrder,
  useReplaceOrder,
  useShippingOrder,
  useResendOrder,
  useGetOrder,
  useGetOrdersByOrderId,
  useGetPharmacyOrderHistory,
};

export default PharmacyService;
