import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

type HistoryModel<A> = {
  id?: number;
  source: string;
  new: A;
  old?: A;
  change: { updated: Partial<A>; added: Partial<A>; deleted: Partial<A> };
  created?: string;
};

function InlineProperty<A>(props: { object: Partial<A> }) {
  return (
    <ul style={{ paddingInlineStart: '10px' }}>
      {Object.entries(props.object).map(([key, value]) => (
        <li style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {key} -{' '}
          {(() => {
            if (value === undefined) return '-';
            if (value === null) return 'null';
            if (typeof value === 'string') return value;
            if (typeof value === 'number') return value;
            if (typeof value === 'boolean') return value;
            if (typeof value === 'object') return <InlineProperty object={value} />;
            if (typeof value === 'symbol') return <InlineProperty object={value} />;
            return 'unknown';
          })()}
        </li>
      ))}
    </ul>
  );
}

export default function History<A>({ history }: { history: HistoryModel<A>[] }) {
  return (
    <Box sx={{ position: 'relative' }}>
      <Table sx={{ width: '100%', tableLayout: 'fixed' }}>
        <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: '#FFF' }}>
          <TableCell width="30%">Added</TableCell>
          <TableCell width="30%">Updated</TableCell>
          <TableCell width="30%">Deleted</TableCell>
          <TableCell width="15%">Created</TableCell>
        </TableHead>
        <TableBody>
          {history.map((h) => (
            <TableRow>
              <TableCell>
                <InlineProperty object={h.change.added}></InlineProperty>
              </TableCell>
              <TableCell>
                <InlineProperty object={h.change.updated}></InlineProperty>
              </TableCell>
              <TableCell>
                <InlineProperty object={h.change.deleted}></InlineProperty>
              </TableCell>
              <TableCell>{h.created}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
