import { Box, Container, Grid } from '@mui/material';
import React from 'react';

const Side = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid item xs={4}>
      {children}
    </Grid>
  );
};

const Main = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid item xs={8}>
      {children}
    </Grid>
  );
};

const Split = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid container spacing={2} maxWidth="xl">
      {children}
    </Grid>
  );
};

Split.Main = Main;
Split.Side = Side;

const Content = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container maxWidth="xl" sx={{ padding: 2 }}>
      {children}
    </Container>
  );
};

Content.Split = Split;

const Page = ({ children, navbar }: { children: React.ReactNode; navbar: React.ReactNode }) => {
  return (
    <Box sx={{ backgroundColor: 'primary.light', padding: '8px', paddingTop: 0 }}>
      {navbar}
      {children}
    </Box>
  );
};

Page.Content = Content;

export default Page;
