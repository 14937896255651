import axios from 'axios';
import { Paginated } from '../common/Paginated';
import useService from '../common/useService';
import { PageProps } from '../common/ui-component/data-table/filters/backoffice-query';
import { MedicalCaseModel, MedicalInformationModel } from '../models';

const useGetMedicalCases = () =>
  useService((params: PageProps) => axios.get<Paginated<MedicalCaseModel>>(`/medical-case`, { params }));

const useGetMedicalCase = () => useService((id: string) => axios.get<MedicalCaseModel>(`/medical-case/${id}`));

const useGetMedicalCaseByCustomerId = () =>
  useService((customerId: string | number) => axios.get<MedicalCaseModel>(`/medical-case/customer/${customerId}`));

const useUpdateMedicalInformation = () =>
  useService(({ id, medicalInformation }: { id: number; medicalInformation: MedicalInformationModel[] }) => {
    return axios.put<MedicalCaseModel>(`/medical-case/${id}/medical-information`, {
      medicalInformation,
    });
  });

const usePushToNextStep = () => useService((id: number) => axios.post<MedicalCaseModel>(`/medical-case/${id}`));

const useDeleteFile = () =>
  useService(({ fileId, medicalCaseId }: { fileId: number | null; medicalCaseId: number | undefined }) =>
    axios.delete(`/medical-case/${medicalCaseId}/delete-file/${fileId}`),
  );

const useSyncOrders = () =>
  useService((params: { id: number }) => axios.post(`/medical-case/${params.id}/sync-orders`));

const usePatch = () =>
  useService(({ id, medicalCase }: { id: number; medicalCase: Partial<MedicalCaseModel> }) =>
    axios.patch<MedicalCaseModel>(`/medical-case/${id}`, medicalCase),
  );

const useTriggerHealthUpdates = () =>
  useService(() => axios.post<MedicalCaseModel>(`/medical-case/health-update-due-date-encounter-trigger`, {}));

const useTriggerHealthUpdate = () =>
  useService((id: string) =>
    axios.post<MedicalCaseModel>(`/medical-case/${id}/health-update-due-date-encounter-trigger`, {}),
  );

const useGetAnnualHealthUpdateCallList = () =>
  useService((date: string) =>
    axios.get<Blob>(`/medical-case/annual-health-update-call-list`, { responseType: 'blob', params: { date } }),
  );

const useSyncMedicalCase = () => useService((id: number) => axios.post(`/medical-case/${id}/sync`));

const MedicalCaseService = {
  useGetMedicalCases,
  useGetMedicalCase,
  useGetMedicalCaseByCustomerId,
  useUpdateMedicalInformation,
  usePushToNextStep,
  useDeleteFile,
  useSyncOrders,
  usePatch,
  useTriggerHealthUpdates,
  useTriggerHealthUpdate,
  useGetAnnualHealthUpdateCallList,
  useSyncMedicalCase,
};

export default MedicalCaseService;
