import { Chip } from '../common/ui-component';
import { SubscriptionStatusEnum } from '../models';

const SubscriptionStatusBadge = ({ status }: { status: SubscriptionStatusEnum }) => {
  const color = () => {
    switch (status) {
      case 'active':
        return 'success';
      case 'pending-cancel':
        return 'warning';
      case 'cancelled':
        return 'danger';
      case 'on-hold':
        return 'warning';
      case 'pending-photos':
        return 'warning';
      default:
        return '';
    }
  };

  return <Chip text={status || ''} color={color()} />;
};

export default SubscriptionStatusBadge;
