import { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OrderFileService from './order-files.service';
import UploadOrderFiles, { MyFile } from './UploadOrderFiles';
import { OrderFileType } from './order-file-type.type';
import { BtnPrimary, Gallery, Info, Link, SectionContent, Subtitle } from '../common/ui-component';
import { OrderFile } from './order-file.model';
import { ConfirmRoutedModal, RoutedModal } from '../common/ui-component/modals';
import Section from '../common/ui-component/layouts/Section.v2';
import { Box } from '../common/ui-component/layouts';
import { MedicalCasePhotoModel } from '../models';

const PhotosSection = ({
  customerId,
  pageUrl,
  orderIds,
  photos,
  onDeletePhoto,
  proveVerified,
  idRequired,
  videoRequired,
}: {
  customerId: number;
  pageUrl: string;
  orderIds: number[];
  photos: OrderFile[] | MedicalCasePhotoModel[];
  proveVerified: boolean;
  idRequired: boolean;
  videoRequired: boolean;
  onDeletePhoto?: (id: number) => void;
}) => {
  const uploadHash = '#upload';
  const confirmDeleteHash = '#delete-file-2';
  const { t } = useTranslation('file');
  const [type, setType] = useState<OrderFileType>('license');
  const [newPhotos, setNewPhotos] = useState<MyFile[]>([]);

  const [deletePhoto, deleteResponse] = OrderFileService.useDeletePhoto();
  const [syncPhoto] = OrderFileService.useSyncPhoto();

  const [fileIdToDelete, setFileIdToDelete] = useState<number | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setNewPhotos([]);
  }, [customerId, deleteResponse]);

  const onUploadOrderFilesSuccess = useCallback(
    (onClose: () => void) => (files: MyFile[]) => {
      onClose();
      setNewPhotos((prevState) => {
        const mergedPhotos = new Set(prevState);
        files.forEach((file) => mergedPhotos.add(file));
        return Array.from(mergedPhotos);
      });
    },
    [],
  );

  const booleanToYesNo = (value: boolean) => (value ? t('yes') : t('no'));

  return (
    <>
      <Section>
        <Section.Title>
          <Subtitle>{t('photos')}</Subtitle>
          <Box sx={{ display: 'flex', paddingTop: '10px' }}>
            <SectionContent>
              <Info label={`${t('proveVerified')}: ${booleanToYesNo(proveVerified)}`}>
                <></>
              </Info>
              <Info label={`${t('videoRequired')}: ${booleanToYesNo(videoRequired)}`}>
                <></>
              </Info>
              <Info label={`${t('idRequired')}: ${booleanToYesNo(idRequired)}`}>
                <></>
              </Info>
            </SectionContent>
          </Box>
        </Section.Title>
        <Section.Actions>
          <Link to={`${pageUrl}/${uploadHash}`} replace>
            <BtnPrimary onClick={() => setType('license')}>{t('uploadLicense')}</BtnPrimary>
          </Link>
          <Link to={`${pageUrl}/${uploadHash}`} replace>
            <BtnPrimary onClick={() => setType('other')}>{t('uploadOther')}</BtnPrimary>
          </Link>
        </Section.Actions>

        <Gallery
          photos={photos
            .map((x) => ({
              id: x.id,
              title: x.type,
              url: x.s3File?.signedUrl || x.mdiFile?.url_thumbnail || '',
              isSync: !!x.mdiFile && !!x.s3File,
            }))
            .concat(
              newPhotos.map((x) => ({
                id: -1, //the local file don't have file id
                title: x.title,
                url: x.url,
                isSync: false,
              })),
            )}
          onSyncPhoto={(fileId: number) => {
            syncPhoto(fileId);
          }}
          onDeletePhoto={(fileId: number) => {
            setFileIdToDelete(fileId);
            navigate(`${pageUrl}/${confirmDeleteHash}`);
          }}
        />
      </Section>

      <RoutedModal predicatedHash={uploadHash} defaultUrl={pageUrl} title={t('uploadFile')}>
        {(onClose: () => void) => (
          <UploadOrderFiles
            onSuccess={onUploadOrderFilesSuccess(onClose)}
            onCancel={onClose}
            type={type}
            customerId={customerId}
          />
        )}
      </RoutedModal>
      <ConfirmRoutedModal
        predicatedHash={confirmDeleteHash}
        defaultUrl={pageUrl}
        title={t('confirmOrderFileDelete', { id: fileIdToDelete })}
        value={fileIdToDelete}
        onConfirm={(value, close) => {
          if (fileIdToDelete) {
            deletePhoto(fileIdToDelete);
            if (onDeletePhoto) onDeletePhoto(fileIdToDelete);
          }
          close();
        }}
      />
    </>
  );
};

export default PhotosSection;
