import { Grid, Paper } from '@mui/material';

const LightWeightSection = ({ children }: { children: JSX.Element | JSX.Element[] | string | undefined }) => {
  return (
    <Paper sx={{ padding: 2, marginBottom: 1 }} elevation={2}>
      <Grid container rowSpacing={1}>
        {children}
      </Grid>
    </Paper>
  );
};

export default LightWeightSection;
