import axios from 'axios';
import useService from '../common/useService';
import { CreateCasePayload, MedicalCaseModel } from '../models';

const useCreateNewCase = () =>
  useService((param: CreateCasePayload & { id: number }) =>
    axios.post<MedicalCaseModel>(`/case-creator/${param.id}/create-case`, {
      skus: param.skus,
      reasonForEncounter: param.reasonForEncounter,
      sendToCreatedQueue: param.sendToCreatedQueue,
      chargeable: param.chargeable,
    }),
  );

const useForceCreateNewCase = () =>
  useService((param: CreateCasePayload & { id: number }) =>
    axios.post<MedicalCaseModel>(`/case-creator/${param.id}/force-create-case`, {
      skus: param.skus,
      reasonForEncounter: param.reasonForEncounter,
      sendToCreatedQueue: param.sendToCreatedQueue,
      chargeable: param.chargeable,
    }),
  );

const CaseCreatorService = {
  useCreateNewCase,
  useForceCreateNewCase,
};

export default CaseCreatorService;
