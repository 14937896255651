import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Info, Section, SectionContent } from '../common/ui-component';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent';
import CustomerService from '../customer/customer.service';
import { OrderModel } from '../models';
import OrdersService from '../orders/orders.service';
import Helper from '../utils/date/helper';

export default function MdiDetails({
  orders,
  customerId,
}: {
  orders?: {
    data: OrderModel[];
  };
  customerId: number;
}) {
  const { t } = useTranslation('subscription');

  const [getPrescriptions, rPrescriptions] = OrdersService.useSearchPrescriptions();

  const [getCustomer, rCustomer] = CustomerService.useGetCustomer();

  const clinicianName = orders?.data.map((el: OrderModel) => el?.clinician?.fullName);
  const [prescriptionToShow, setPrescription] = useState<string | number>();
  const clinicianNameUnique = clinicianName?.filter((name: string | undefined, index: number) => {
    return clinicianName?.indexOf(name) === index;
  });
  useEffect(() => {
    if (orders?.data && orders?.data.length > 0) {
      const ids = orders.data.map((el) => el.id);
      if (ids.length > 0) {
        getPrescriptions({
          queries: { orderId: [{ value: ids.toString(), operator: 'equals' }] },
          pageNumber: 1,
          pageSize: 100,
        });
      }
    }
  }, [orders, getPrescriptions]);

  useEffect(() => {
    if (customerId) {
      getCustomer(customerId.toString());
    }
  }, [customerId, getCustomer]);

  const getSku = (metadata: string | { sku: string } | undefined) => {
    if (typeof metadata === 'string') {
      return metadata;
    } else {
      return metadata?.sku;
    }
  };

  return (
    <RemoteDataContent
      value={rCustomer}
      whenSuccess={(customer) => (
        <RemoteDataContent
          value={rPrescriptions}
          whenNotAsked={() => (
            <div>
              <Section title={t('mdiDetails')}>
                <SectionContent>
                  <Info label={t('patientID')}>{customer.patientId || ''}</Info>
                  <>
                    <Info label="">
                      <Typography
                        variant="subtitle2"
                        display="block"
                        my={-1}
                        fontSize={16}
                        sx={{ textDecoration: 'underline' }}
                      >
                        {t('prescriptions')}
                      </Typography>
                    </Info>
                  </>
                </SectionContent>
                <Box>
                  <SectionContent>
                    <Info label={t('clinician')}>{clinicianNameUnique?.[0] ?? '---'}</Info>
                  </SectionContent>
                </Box>
                <SectionContent>
                  <Typography variant="subtitle2" display="block" mt={1} sx={{ textDecoration: 'underline' }}>
                    {t('caseHistory')}
                  </Typography>
                </SectionContent>
                <>
                  {orders?.data.map((el: OrderModel) => {
                    if (el.caseId) {
                      return (
                        <div key={`${el.id}-${el.customerId}`}>
                          <SectionContent>
                            <Info label="">{el.caseId}</Info>
                            <Info label="">
                              <Typography textAlign="center">{Helper.onlyDate(el?.orderCreated).value}</Typography>
                            </Info>
                            <Info label="">
                              <Typography textAlign="start">---</Typography>
                            </Info>
                          </SectionContent>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </>
              </Section>
            </div>
          )}
          whenSuccess={(prescriptions) => {
            return (
              <div>
                <Section title={t('mdiDetails')}>
                  <SectionContent>
                    <Info label={t('patientID')}>{customer.patientId || ''}</Info>
                    <>
                      <Info label="">
                        <Typography
                          variant="subtitle2"
                          display="block"
                          my={-1}
                          fontSize={16}
                          sx={{ textDecoration: 'underline' }}
                        >
                          {t('prescriptions')}
                        </Typography>
                      </Info>
                      {prescriptions?.data.length > 0 && (
                        <FormControl fullWidth sx={{ marginTop: 1 }}>
                          <Select
                            id="demo-simple-select"
                            value={prescriptionToShow ?? prescriptions.data[0].id ?? ''}
                            sx={{ border: 1 }}
                            onChange={(e) => setPrescription(e.target.value)}
                          >
                            {prescriptions?.data?.map((el) => {
                              return (
                                <MenuItem value={el.id} key={`${el.id}-${el.orderId}-prescriptions`}>
                                  <div>
                                    <Typography>{getSku(el?.medication?.metadata)}</Typography>
                                    <Typography>{`ID: ${el?.dosespotPrescriptionId}`}</Typography>
                                    <Typography>{`Refills: ${el?.refills}`}</Typography>
                                  </div>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )}
                    </>
                  </SectionContent>
                  <Box mt={prescriptions?.data.length > 0 ? -8 : 0}>
                    <SectionContent>
                      <Info label={t('clinician')}>{clinicianNameUnique?.[0] ?? '---'}</Info>
                    </SectionContent>
                  </Box>
                  <SectionContent>
                    <Typography variant="subtitle2" display="block" mt={1} sx={{ textDecoration: 'underline' }}>
                      {t('caseHistory')}
                    </Typography>
                  </SectionContent>
                  <>
                    {orders?.data.map((el: OrderModel) => {
                      if (el.caseId) {
                        return (
                          <SectionContent key={`${el.caseId}-${el.customerId}`}>
                            <Info label="">{el.caseId}</Info>
                            <Info label="">
                              <Typography textAlign="center">{Helper.onlyDate(el?.orderCreated).value}</Typography>
                            </Info>
                            <Info label="">
                              <Typography textAlign="start">
                                {prescriptions?.data.length > 0
                                  ? getSku(
                                      prescriptions.data.find((pres) => pres.orderId === el.id)?.medication.metadata,
                                    )
                                  : '---'}
                              </Typography>
                            </Info>
                          </SectionContent>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </>
                </Section>
              </div>
            );
          }}
        />
      )}
    />
  );
}
