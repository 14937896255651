const STUCK_ORDER_EN = {
  dayAgo_one: '{{count}} day ago',
  dayAgo_other: '{{count}} days ago',
  hourAgo_one: '{{count}} hour ago',
  hourAgo_other: '{{count}} hours ago',
  beforeMdi: 'Before MDI',
  beforePharmacy: 'Before Pharmacy',
  id: 'id',
  orderId: 'Order id',
  medicalCaseId: 'Medical case Id',
  stuckDate: 'Stuck date',
  customerId: 'Customer id',
  customerName: 'Customer Name',
  mdiStatus: 'MDI Status',
  status: 'Status',
  pharmacyStatus: 'Pharmacy Status',
  stuckBeforeMdi: 'Stuck Before Mdi',
  stuckBeforePharmacy: 'Stuck Before Pharmacy',
  stuckMdi: 'Stuck in MDI',
  stuckMissingPhotos: 'Stuck Missing Photos',
  stuckPharmacy: 'Stuck in pharmacy',
  stuck: 'Stuck',
  inPharmacy: 'In Pharmacy',
  errors: 'Errors',
  subscriptionIds: 'Subscription ids',
  created: 'Created',
  createdWithTime: 'Created (with time)',
  parent: 'Parent',
  renewal: 'Renewal',
  orderType: 'Order type',
  oneOff: 'One-Off',
};

export default STUCK_ORDER_EN;
