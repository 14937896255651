import { Alert, Divider, Snackbar } from '@mui/material';
import { useAccessToken } from '../authentication';
import { isAdminUser } from '../authentication/auth';
import { BtnDanger, Icons, Link, Section } from '../common/ui-component';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent.v2';
import HairYardService from '../hair-yard/hair-yard.service';
import { HIDDEN_ROUTE } from '../Router';
import { ConfirmRoutedModal } from '../common/ui-component/modals';
import { notAsked } from '../utils/remote-data';

const hashTriggerTasksAttachPhotos = '#tasks-attach-photos';

export default function DetailPage() {
  const currentRoute = `${HIDDEN_ROUTE}`;

  const [user] = useAccessToken();
  const [triggerHealthUpdate, triggerResult, setTriggerReuslt] = HairYardService.useTriggerTasksAttachPhotos();

  return (
    <>
      {isAdminUser(user) && (
        <>
          <Section title="Trigger Tasks Hair Yard Attach Photos">
            <Divider>
              <BtnDanger>
                <Link to={`${currentRoute}/${hashTriggerTasksAttachPhotos}`}>
                  <>
                    TRIGGER
                    <Icons.Bomb />
                  </>
                </Link>
              </BtnDanger>
            </Divider>
          </Section>
        </>
      )}
      <RemoteDataContent value={triggerResult}>
        <RemoteDataContent.Success>
          {() => {
            return (
              <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert
                  variant="filled"
                  severity="success"
                  onClick={() => {
                    setTriggerReuslt(notAsked());
                  }}
                >
                  {'success -> click on me '}
                </Alert>
              </Snackbar>
            );
          }}
        </RemoteDataContent.Success>
      </RemoteDataContent>

      <ConfirmRoutedModal
        predicatedHash={hashTriggerTasksAttachPhotos}
        defaultUrl={currentRoute}
        title={'Are you sure you want trigger tasks attach photos ?'}
        value={'a'}
        onConfirm={(_, close) => {
          triggerHealthUpdate();
          close();
        }}
      ></ConfirmRoutedModal>
    </>
  );
}
