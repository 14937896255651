import axios from 'axios';
import { Paginated } from '../common/Paginated';
import { PageProps } from '../common/ui-component/data-table/filters/backoffice-query';
import useService from '../common/useService';
import { PendingOrderModel } from '../models';

const useGetPendingOrder = () => useService((id: string) => axios.get<PendingOrderModel>(`/pending-orders/${id}`));

const useGetPendingOrders = () =>
  useService((params: PageProps) =>
    axios.get<Paginated<PendingOrderModel>>(`/pending-orders`, {
      params,
    }),
  );

const PendingOrdersService = {
  useGetPendingOrder,
  useGetPendingOrders,
};

export default PendingOrdersService;
