export interface OrderShipping {
  orderId: number;
  version: number;
  carrier: Carrier;
  shippingMethod: ShippingMethods;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  pharmacyName: string;
}

export type Carrier = 'FEDEX' | 'USPS';

export type ShippingMethods = FedexShippingMethod | UspsShippingMethod;

export type FedexShippingMethod = 'fedex_2_day' | 'fedex_priority_overnight';

export type UspsShippingMethod = 'usps_first' | 'usps_priority';

export const getAvailableShippingMethods = (carrier: Carrier): [ShippingMethods?, ShippingMethods[]?] => {
  if (carrier === 'FEDEX') return ['fedex_2_day', ['fedex_2_day', 'fedex_priority_overnight']];
  else if (carrier === 'USPS') return ['usps_first', ['usps_first', 'usps_priority']];
  return [];
};
