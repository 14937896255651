import { MedicalInformationModel } from '../models';

export type QuestionForm = { [key: string]: MedicalInformationModel };

const convertKey = (str: string) => str.replace('-', '_');

const toQuestionForm = (question: MedicalInformationModel[]): QuestionForm => {
  const withRemovedDuplicate = new Map(question.map((q) => [q.key, q]));
  const result = [...withRemovedDuplicate.entries()].reduce<QuestionForm>(
    (prev, [key, q]) => ({ ...prev, [`${convertKey(key)}`]: q }),
    {},
  );
  return result;
};

const toAnwser = (questionForm: QuestionForm): MedicalInformationModel[] => Object.values(questionForm);
const QuestionHelper = {
  toQuestionForm,
  toAnwser,
};

export default QuestionHelper;
