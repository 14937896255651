const HEALTH_UPDATE_TRACKER_EN = {
  active: 'Active',
  cancelled: 'Cancelled',
  completed: 'Completed',
  crmConverted: 'CRM Converted',
  crmEntered: 'Entered CRM Flow',
  crmEnteredWithTime: 'Entered CRM Flow (with time)',
  customer: 'Customer',
  customerId: 'Customer ID',
  details: 'Details',
  email: 'Email',
  encounterCreated: 'Case Creation Date',
  encounterCreatedWithTime: 'Case Creation Date (with time)',
  fallbackMD: 'Fallback MD',
  fallbackMdAssigned: 'Fallback MD Assigned Date',
  fallbackMdAssignedWithTime: 'Fallback MD Assigned Date (with time)',
  fallbackMdConverted: 'Fallback Converted',
  firstAssignedMD: 'First Assigned MD',
  healthUpdateDueDate: 'HUDD',
  healthUpdateDueDateWithTime: 'HUDD (with time)',
  healthUpdateFilled: 'HUF Filled',
  healthUpdateTracker: 'Health Update Tracker',
  incompleted: 'Incompleted',
  inMdi: 'In MDI',
  hasEnteredCrm: 'Has Entered CRM',
  mdiConverted: 'MDI Converted',
  medicalCaseId: 'Medical Case Id',
  paused: 'Paused',
  prescribingMD: 'Prescibing MD',
  prescriptionSigned: 'Prescription Signed Date',
  prescriptionSignedWithTime: 'Prescription Signed Date (with time)',
  subscriptionStatus: 'Subscription Status',
  unknown: 'Unknown',
};

export default HEALTH_UPDATE_TRACKER_EN;
