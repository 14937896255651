import { Box, Grid } from '@mui/material';
import React from 'react';
import FormSection from './FormSection';

interface Props {
  children: React.ReactNode;
  onSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
  id?: string;
}

const Form = ({ id, children, onSubmit }: Props) => {
  return (
    <Box
      component="form"
      id={id}
      onSubmit={onSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Grid container spacing={2}>
        {children}
      </Grid>
    </Box>
  );
};

Form.Section = FormSection;

export default Form;
