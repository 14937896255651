import { Button, ButtonProps } from '@mui/material';
import { MouseEventHandler } from 'react';

interface Props {
  children: React.ReactNode | string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: MouseEventHandler | undefined;
}

const BtnSuccess = ({ children, type, onClick, ...rest }: Props & ButtonProps) => {
  return (
    <Button
      {...rest}
      sx={{ m: 2 }}
      fullWidth
      type={type || 'button'}
      variant="contained"
      onClick={onClick}
      color="success"
    >
      {children}
    </Button>
  );
};

export default BtnSuccess;
