import axios from 'axios';
import useService from '../common/useService';
import { PageProps } from '../common/ui-component/data-table/filters/backoffice-query';
import { Paginated } from '../common/Paginated';
import { MarketingProfileModel } from '../models';

const useTriggerHealthUpdateList = () => useService(() => axios.post(`/marketing-platform/health-update-due-list`, {}));

const useSearch = () =>
  useService((params: PageProps) => axios.get<Paginated<MarketingProfileModel>>(`/marketing-platform`, { params }));

const MarketingPlaformService = {
  useTriggerHealthUpdateList,
  useSearch,
};

export default MarketingPlaformService;
