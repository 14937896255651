import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { useAccessToken } from './AccessTokenProvider';
import { LOGIN_ROUTE } from '../Router';
import Auth from './auth';
import JwtTokenHelper from './jwt-token.helper';
import AuthenticateService from './authenticate.service';
import { isSuccess } from '../utils/remote-data';

interface Props {
  element: JSX.Element;
}

const Authenticated = ({ element }: Props): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useAccessToken();
  const [getRefreshToken] = AuthenticateService.useRefresh();
  const { t } = useTranslation();

  const isNotAuthenticated = (token: string | null) =>
    !token || JwtTokenHelper.hasExpired(token) || !JwtTokenHelper.isTwoFactorAuthenticated(token);

  useEffect(() => {
    Auth.fold(
      () => {
        navigate({
          pathname: LOGIN_ROUTE,
          search: createSearchParams({ redirectTo: location.pathname }).toString(),
        });
      },
      (user_) => {
        (async () => {
          if (isNotAuthenticated(user_?.access_token || null)) {
            if (
              user_?.refresh_token &&
              !JwtTokenHelper.hasExpired(user_.refresh_token) &&
              JwtTokenHelper.hasExpired(user_?.access_token)
            ) {
              const _r = await getRefreshToken(user_.refresh_token);

              if (isSuccess(_r)) {
                setUser({
                  _tag: 'loggedIn',
                  authPayload: _r.value,
                });

                return;
              }
            }
            navigate({
              pathname: LOGIN_ROUTE,
              search: createSearchParams({ redirectTo: location.pathname }).toString(),
            });
          }
        })();
      },
    )(user);
  }, [user, navigate, getRefreshToken, setUser, location.pathname]);

  return Auth.fold(
    () => <span>{t('redirect')}</span>,
    (accessToken) => {
      if (isNotAuthenticated(accessToken?.access_token ?? null)) return <span>{t('redirect')}</span>;
      return element;
    },
  )(user);
};

export default Authenticated;
