import { Alert } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

interface Props {
  err: unknown;
}

const ErrorHandler = ({ err }: Props) => {
  const { t } = useTranslation();

  if (axios.isAxiosError(err)) {
    const message = () => {
      if (err.response?.status === 400) {
        return t('httpError_400');
      } else if (err.response?.status === 401) {
        return t('httpError_401');
      }

      return t('httpError_500');
    };

    return <>{<Alert severity="error">{message()}</Alert>}</>;
  }
  return (
    <pre>
      <code>{JSON.stringify(err, undefined, 2)}</code>
    </pre>
  );
};

export const errorHandler = (err: unknown) => <ErrorHandler err={err} />;

export default ErrorHandler;
