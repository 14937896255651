import { FormControl, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BtnPrimary, Section } from '../common/ui-component';
import { useTranslation } from 'react-i18next';
import { OrderActionsEnum } from '../orders/orderActions.enum';
import {
  PharmacyOrderStatusEnum,
  PharmacyOrder,
  PharmacyNameEnum,
  CancellableResendableError,
} from './pharmacy-order.model';
import featureToggleHelper from '../utils/feature-toggle/feature-toggle.helper';

export const replaceOrderHash = '#replace';
export const cancelOrderHash = '#cancel';
export const shippingOrderHash = '#shipping';
export const resendOrderHash = '#resend';

interface Props {
  pharmacyOrders: PharmacyOrder[] | null;
  currentRoute: string;
  onCancel: (order: PharmacyOrder) => void;
  onReplace: (order: PharmacyOrder) => void;
  onShipped: (order: PharmacyOrder) => void;
  onResend: (order: PharmacyOrder) => void;
}

const OrderActions = ({ pharmacyOrders, currentRoute, onCancel, onReplace, onShipped, onResend }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation('order');
  const sendTrackingEmailHashModal = '#opensendemail';
  const [orderAction, setOrderAction] = useState<OrderActionsEnum | null>(null);

  const onSubmit = (value: string) => {
    const location = value.includes('.') ? value.split('.')[1] : value;
    switch (location) {
      case OrderActionsEnum.CHANGE_SHIPPING_ADDRESS:
        return navigate(`${currentRoute}${shippingOrderHash}`);
      case OrderActionsEnum.REPLACEMENT_ORDER:
        return navigate(`${currentRoute}${replaceOrderHash}`);
      case OrderActionsEnum.RESEND_TRACKING_EMAIL:
        return navigate(`${currentRoute}${sendTrackingEmailHashModal}`);
      case OrderActionsEnum.CANCEL_ORDER:
        return navigate(`${currentRoute}${cancelOrderHash}`);
      case OrderActionsEnum.RESEND_ORDER:
        return navigate(`${currentRoute}${resendOrderHash}`);
      default:
        return;
    }
  };
  const pharmacies = featureToggleHelper.isEnable(process.env.REACT_APP_CHEMISTRYRX_REPLACEMENT_TOGGLER)
    ? [...new Set(pharmacyOrders?.map((x) => x.pharmacyName))]
    : ['curexa'];
  const lastVersions = new Map<string, PharmacyOrder | undefined>();
  pharmacies.forEach((pharmacy) => {
    lastVersions.set(
      pharmacy,
      pharmacyOrders
        ?.filter((po) => po.pharmacyName === pharmacy)
        .sort((a, b) => {
          return b.version - a.version;
        })[0],
    );
  });

  const renderOptions = () => {
    const result: JSX.Element[] = [];
    lastVersions.forEach((lastVersion, pharmacyName) => {
      const status = lastVersion?.status;
      const alreadyDelivered = status === PharmacyOrderStatusEnum.COMPLETED;
      const shipped = lastVersion?.hasAlreadyBeenShipped;
      const allRxItemsHaveBeenreplaced = lastVersion?.rxItems.every((x) => x.prescription?.replaced);
      const allOtcItemsHaveBeenreplaced = lastVersion?.otcItems.every((x) => x?.replaced);
      const allItemsHasBeenReplaced = allRxItemsHaveBeenreplaced && allOtcItemsHaveBeenreplaced;
      const canCancel = !alreadyDelivered && !shipped && !allItemsHasBeenReplaced;
      const canReplace = shipped && !allItemsHasBeenReplaced;
      const canResend =
        !shipped &&
        lastVersion?.pharmacyName === PharmacyNameEnum.CUREXA &&
        CancellableResendableError.some((x) => x === lastVersion.statusDetails);
      if (lastVersion) {
        if (canResend) {
          result.push(
            <MenuItem
              value={`${lastVersion.id}.${OrderActionsEnum.RESEND_ORDER}`}
              key={`${lastVersion.id}.${OrderActionsEnum.RESEND_ORDER}`}
              onClick={() => {
                onResend(lastVersion);
              }}
            >
              {`${pharmacyName} - ${t('resend')}`}
            </MenuItem>,
          );
        }
        if (!shipped) {
          result.push(
            <MenuItem
              value={`${lastVersion.id}.${OrderActionsEnum.CHANGE_SHIPPING_ADDRESS}`}
              key={`${lastVersion.id}.${OrderActionsEnum.CHANGE_SHIPPING_ADDRESS}`}
              onClick={() => {
                onShipped(lastVersion);
              }}
            >
              {`${pharmacyName} - ${t('shippingMethodAddress')}`}
            </MenuItem>,
          );
        }

        if (canReplace) {
          result.push(
            <MenuItem
              key={`${lastVersion.id}.${OrderActionsEnum.REPLACEMENT_ORDER}`}
              value={`${lastVersion.id}.${OrderActionsEnum.REPLACEMENT_ORDER}`}
              onClick={() => onReplace(lastVersion)}
            >
              {`${pharmacyName} - ${t('replaceOrder')}`}
            </MenuItem>,
          );
        }

        if (canCancel) {
          result.push(
            <MenuItem
              key={`${lastVersion.id}.${OrderActionsEnum.CANCEL_ORDER}`}
              value={`${lastVersion.id}.${OrderActionsEnum.CANCEL_ORDER}`}
              onClick={() => onCancel(lastVersion)}
            >
              {`${pharmacyName} - ${t('cancelOrder')}`}
            </MenuItem>,
          );
        }
      }
    });

    return result;
  };

  return (
    <Section title={t('orderActions')}>
      <FormControl fullWidth>
        <Select
          labelId="simple-select-label"
          id="simple-select"
          value={orderAction ?? 'default'}
          onChange={(e) => setOrderAction(e.target?.value as OrderActionsEnum)}
        >
          <MenuItem value="default">Select options</MenuItem>
          <MenuItem value={OrderActionsEnum.RESEND_TRACKING_EMAIL}>{t('resendShipping')}</MenuItem>
          {renderOptions()}
        </Select>
        <BtnPrimary
          type="button"
          disabled={orderAction === null}
          sx={{ marginTop: 2 }}
          fullWidth={false}
          onClick={() => onSubmit(orderAction as OrderActionsEnum)}
        >
          {t('submit')}
        </BtnPrimary>
      </FormControl>
    </Section>
  );
};

export default OrderActions;
