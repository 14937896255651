import { BtnPrimary, Icons } from '../common/ui-component';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent';
import { MdiService } from '../mdi';
import { OrdersService } from '../orders';
import { OrderModel } from '../models';
import { fold, RemoteData } from '../utils/remote-data';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import DeadLetterService from './dead-letter.service';
import { MdiCase } from '../mdi/mdi-case.model';
import { DeadLetterModel } from './dead-letter.model';
import { useTranslation } from 'react-i18next';

const Indicator = <T,>({ response, text }: { response: RemoteData<T, unknown>; text: string }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <RemoteDataContent
        value={response}
        whenSuccess={() => <Icons.Checked />}
        whenFailure={() => <Icons.Cancel />}
        whenNotAsked={() => <Icons.Gray />}
      />
      <span>{text}</span>
    </Box>
  );
};

const FixClinicianMissing = <T extends object>({
  orderId,
  deadLetter,
}: {
  orderId: string;
  deadLetter: DeadLetterModel<T>;
}) => {
  const [getOrder, rOrder] = OrdersService.useGetOrder();
  const [syncCase, rSync] = MdiService.useSyncCase();
  const [updateDeadLetter, syncResponse] = DeadLetterService.useUpdateDeadLetter();
  const [retry, rRetry] = DeadLetterService.useRetryDeadLetter();
  const { t } = useTranslation('deadLetter');

  useEffect(() => {
    fold(
      () => {},
      () => {},
      (order: OrderModel) => syncCase({ id: order.caseId }),
      () => {},
    )(rOrder);
  }, [rOrder, syncCase]);

  useEffect(() => {
    fold(
      () => {},
      () => {},
      (case_: MdiCase) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const detail: any =
          'value' in deadLetter.queueMessage ? { ...deadLetter.queueMessage.value } : { ...deadLetter.queueMessage };
        if (detail.parentOrder) {
          detail.parentOrder.clinician = case_.caseAssignment.clinician;
        } else {
          detail.order.clinician = case_.caseAssignment.clinician;
        }

        updateDeadLetter({
          id: deadLetter.id.toString(),
          data: {
            ...deadLetter,
            queueMessage: {
              ...deadLetter.queueMessage,
              value: detail,
            },
          },
        });
      },
      () => {},
    )(rSync);
  }, [rSync, deadLetter, updateDeadLetter]);

  useEffect(() => {
    fold(
      () => {},
      () => {},
      () => {
        retry(deadLetter.id);
      },

      () => {},
    )(syncResponse);
  }, [syncResponse, deadLetter, retry]);

  return (
    <>
      <BtnPrimary onClick={() => getOrder(orderId)}>{t('fillClinicianInformation')}</BtnPrimary>
      <Indicator response={rOrder} text={t('getOrder')} />
      <Indicator response={rSync} text={t('getMdiCase')} />
      <Indicator response={syncResponse} text={t('updatingDeadLetter')} />
      <Indicator response={rRetry} text={t('sendToRetry')} />
    </>
  );
};

export default FixClinicianMissing;
