const MARKETING_PLATFORM_EN = {
  created: 'Created',
  createdWithTime: 'Created (with time)',
  customerId: 'Customer Id',
  externalId: 'Profile Id',
  healthUpdateDueLapsedAdded: 'Health Update Due Lapsed Added',
  healthUpdateDueLapsedAddedWithTime: 'Health Update Due Lapsed Added (with time)',
  healthUpdateDueLapsedRemoved: 'Health Update Due Lapsed Removed',
  healthUpdateDueLapsedRemovedWithTime: 'Health Update Due Lapsed Removed (with time)',
  healthUpdateDueListAdded: 'Health Update Due List Added',
  healthUpdateDueListAddedWithTime: 'Health Update Due List Added (with time)',
  healthUpdateDueListRemoved: 'Health Update Due List Removed',
  healthUpdateDueListRemovedWithTime: 'Health Update Due List Removed (with time)',
  id: 'Id',
  marketingPlatform: 'Marketing Platform',
  firstName: 'First Name',
  lastName: 'Last Name',
  isActive: 'Is Active',
  uploadedHairloss: 'Uploaded Hair Loss',
  uploadedId: 'Uploaded Id',
  healthUpdateDueDate: 'Health Update Due Date',
  healthUpdateDueDateWithTime: 'Health Update Due Date (with time)',
  lastHealthUpdate: 'Last Health Update',
  lastHealthUpdateWithTime: 'Last Health Update (with time)',
};

export default MARKETING_PLATFORM_EN;
