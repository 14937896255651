/**
 * Usefull when you have something that can fail and you need to know why
 *
 * Err - We asked, but something went wrong. Here's the error.
 *
 * Ok - Everything worked, and here's the data.
 */
export type Result<A, E> = Ok<A> | Err<E>;

/**   Initial ok into the realm of Result.

 /**  Lift an ordinary value into the realm of Result.
  **/
export const ok = <A, B>(value: A): Result<A, B> => ({
  _tag: 'Ok',
  value,
});

/**  Lift an error into the realm of Result.
 **/
export const err = <A, E>(value: E): Result<A, E> => ({
  _tag: 'Err',
  value,
});

type Ok<A> = { value: A; _tag: 'Ok' };
type Err<E> = { value: E; _tag: 'Err' };

/** Map a function into the Ok value.
 * @param whenSucceed Function to map the succeed value
 * @returns Function thats take the remote data
 * @param remoteData The result to map
 * @returns The return new states ,if succeed state return mapped state
 * @example map(item => items.filter(predicate)))(loading()) === loading()
 * @example map(item => +item)(ok("10")) === ok(10)
 */
export const map =
  <A, R>(whenSucceed: (a: A) => R) =>
  (rd: Result<A, unknown>) => {
    if (isOk(rd)) return ok(whenSucceed(rd.value));
    return rd;
  };

// eslint-disable-next-line max-len
/** Combine two remote data sources with the given function. The result will succeed when (and if) both sources succeed. If not return de err one and if its 2 err return the first one.
 * @param whenSucceed Function to map the succeed value
 * @returns Function thats take the first Result
 * @param remoteData First Result
 * @returns Function thats take the remote second Result
 * @param remoteData2 Second Result
 * @returns The return new states ,if succeed state return mapped state
 * @example map2((item1, item2) => item1 + item2)(loading())(ok(10)) === loading()
 * @example map2((item1, item2) => item1 + item2)(ok(10))(ok(10)) === ok(20)
 * @example map2((item1, item2) => item1 + item2)(notAsked())(loading()) === notAsked()
 */
export const map2 =
  <A, B, R>(whenSucceed: (a: A) => (b: B) => R) =>
  (rd: Result<A, unknown>) =>
  (rd2: Result<B, unknown>) => {
    if (isOk(rd) && isOk(rd2)) return ok(whenSucceed(rd.value)(rd2.value));
    if (isErr(rd)) return rd;
    return rd2;
  };

/** Return the Ok value, or the default.
 * @param defaultValue The value returned if is not Succeed
 * @returns Function thats take the Result
 * @param remoteData Result
 * @returns The default value or the ok value
 * @example withDefault("Not Ok Result")(loading()) === "Not Ok Result"
 * @example withDefault("Not Ok Result")(ok("10")) === ok("10")
 */
export const withDefault =
  <A>(defaultValue: A) =>
  (rd: Result<A, unknown>) =>
    isOk(rd) ? rd.value : defaultValue;

/** Extract data for each state.
   * @param whenOk Function when is state is Ok.
   * @param whenErr Function when is state is Err.
   * @returns Function thats take the remote data
   * @param remoteData The result to extract
   * @returns The right result of the current state
   * @example fold(
                  (items: List<Item>) => <> {items.map(\i -> <Item item={i}/>} </>,
                  (_ : AxiosError) => <p> Something bad happen! Call the 911 </p> 
            )(notAsked()) === <p> Not Asked yet </p>
   * @example fold(
                  (items: List<Item>) => <> {items.map(\i -> <Item item={i}/>} </>,
                  (_ : AxiosError) => <p> Something bad happen! Call the 911 </p> 
            )(loading()) === <Loader />,
   * @example fold(
                  (items: List<Item>) => <> {items.map(\i -> <Item item={i}/>} </>,
                  (_ : AxiosError) => <p> Something bad happen! Call the 911 </p> 
            )(ok([items1])) === <> {items.map(\i -> <Item item={i}/>} </>
   * @example fold(
                  (items: List<Item>) => <> {items.map(\i -> <Item item={i}/>} </>,
                  (_ : AxiosError) => <p> Something bad happen! Call the 911 </p> 
            )(err(anyError)) === <p> Something bad happen! Call the 911 </p> 
  
   */
export const fold =
  <A, E, R>(whenOk: (a: A) => R, whenErr: (e: E) => R) =>
  (rd: Result<A, E>) => {
    if (isErr(rd)) return whenErr(rd.value);
    return whenOk(rd.value);
  };

const _isOk = <A>(ma: Result<A, unknown>): ma is Ok<A> => ma._tag === 'Ok';

export const isOk: <A>(rd: Result<A, unknown>) => rd is Ok<A> = _isOk;

const _isErr = <E>(ma: Result<unknown, E>): ma is Err<E> => ma._tag === 'Err';

export const isErr: <E>(rd: Result<unknown, E>) => rd is Err<E> = _isErr;

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Result = { withDefault, fold };

export default Result;
