import axios from 'axios';
import { Paginated } from '../common/Paginated';
import { HairYardModel } from '../models';
import useService from '../common/useService';
import { PageProps } from '../common/ui-component/data-table/filters/backoffice-query';

const useSearch = () =>
  useService((params: PageProps) => axios.get<Paginated<HairYardModel>>(`/hair-yard`, { params }));

const useAttachPhotos = () => useService((id: string) => axios.post<HairYardModel>(`/hair-yard/${id}/attach-photo`));

const useTriggerTasksAttachPhotos = () =>
  useService((id: string) => axios.post<HairYardModel>(`/hair-yard/tasks/attach-photos`));

const HairYardService = {
  useSearch,
  useAttachPhotos,
  useTriggerTasksAttachPhotos,
};

export default HairYardService;
