const ADDRESS_EN = {
  city: 'City',
  state: 'State',
  country: 'Country',
  postcode: 'Postcode',
  company: 'Company',
  address_1: 'Address 1',
  address_2: 'Address 2',
  phone: 'Phone',
};

export default ADDRESS_EN;
