import { Divider, Grid, Paper, Box } from '@mui/material';
import { Subtitle } from '../typography';
import SectionContent from './SectionContent';
import { Children, ReactNode } from 'react';

const Sub = ({
  title,
  children,
}: {
  title?: string | undefined;
  children: JSX.Element | JSX.Element[] | string | undefined;
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <SectionContent>
        <Subtitle>{title}</Subtitle>
      </SectionContent>
      <Grid container rowSpacing={1} sx={{ pt: 2 }}>
        {children}
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
    </Box>
  );
};

const Actions = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>{children}</Box>;
};

const isActions = (node: ReactNode) => {
  if (typeof node === 'boolean') return false;
  if (typeof node === 'number') return false;
  if (typeof node === 'string') return false;
  if (node && 'type' in node) {
    return node.type === Actions;
  }

  return false;
};

const Title = ({ children }: { children: string | JSX.Element | JSX.Element[] }) => {
  return typeof children === 'string' ? <Subtitle>{children}</Subtitle> : <>{children}</>;
};

const isTitle = (node: ReactNode) => {
  if (typeof node === 'boolean') return false;
  if (typeof node === 'number') return false;
  if (typeof node === 'string') return false;
  if (node && 'type' in node) {
    return node.type === Title;
  }

  return false;
};

const Section = ({ children }: { children: JSX.Element | JSX.Element[] | string | undefined }) => {
  const title = Children.toArray(children).filter(isTitle)[0];
  const actions = Children.toArray(children).filter(isActions)[0];
  const others = Children.toArray(children).filter((x) => !(isActions(x) || isTitle(x)));
  return (
    <Paper sx={{ padding: 2, marginBottom: 4 }} elevation={2}>
      <Grid container>
        <Grid item xs={6}>
          {title}
        </Grid>
        <Grid item xs={6}>
          {actions}
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
      <Grid container rowSpacing={1}>
        {others}
      </Grid>
    </Paper>
  );
};

Section.Actions = Actions;
Section.Sub = Sub;
Section.Title = Title;

export default Section;
