import { LoginForm } from '../authentication';
import { useGoogleLogin, TokenResponse, CodeResponse } from '@react-oauth/google';
import GoogleIcon from '@mui/icons-material/Google';
import { Button } from '@mui/material';

interface Props {
  submit: (loginForm: LoginForm) => void;
}

const GoogleOAuthv2 = ({ submit }: Props) => {
  const responseSuccess = (googleAuthBody: CodeResponse) => {
    const authCode = googleAuthBody.code;
    submit({ _name: 'google', authCode: authCode });
  };

  const responseError = (error: Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
    console.error(error);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: responseSuccess,
    onError: responseError,
    flow: 'auth-code',
  });

  return (
    <Button
      variant="outlined"
      fullWidth
      startIcon={<GoogleIcon />}
      onClick={() => googleLogin()}
      sx={{ marginTop: '0.5em' }}
    >
      Sign in with Google
    </Button>
  );
};

export default GoogleOAuthv2;
