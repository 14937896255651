import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { _databaseDateOnlyFormat } from '../../utils/date/helper';

interface Props {
  name: string;
  type?: string;
  readOnly?: boolean;
  maxDate?: DateTime;
  label?: string;
  required?: boolean;
}

const DatePickerInput = ({ name, maxDate, label, required }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();
  const inError = !!errors[name];
  const helperText = inError ? t('required') : undefined;
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required }}
      render={({ field: { onChange, value } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              onChange={onChange}
              value={value}
              maxDate={maxDate}
              label={label}
              inputFormat={_databaseDateOnlyFormat}
              renderInput={(params) => (
                <TextField
                  required={params.required}
                  {...params}
                  error={inError}
                  helperText={helperText}
                  margin="normal"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};

export default DatePickerInput;
