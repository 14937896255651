const PENDING_ORDER_EN = {
  pendingOrders: 'Pending Orders',
  pendingOrder: 'Pending Order',
  uniqueId: 'Unique Id',
  dob: 'Date Of Birth',
  gender: 'Gender',
  questions: 'Questions',
  created: 'Created',
  createdWithTime: 'Created (with time)',
  updated: 'Updated',
  updatedWithTime: 'Updated (with time)',
  id: 'id',
  details: 'Details',
  email: 'Email',
};

export default PENDING_ORDER_EN;
