import {
  Chip,
  Icons,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../common/ui-component';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import OrderService, { Prescription } from '../orders/orders.service';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent.v2';
import { DateHelper } from '../utils/date';
import { DateTime } from 'luxon';
import { _databaseFormat } from '../utils/date/helper';

type Props = {
  customerId: number;
};

const PrescriptionTable = ({ customerId }: Props) => {
  const [getPrescriptionsByCustomer, rPrescriptions] = OrderService.useGetPrescriptionsByCustomer();
  const { t } = useTranslation('medical_case');

  useEffect(() => {
    getPrescriptionsByCustomer(customerId);
  }, [customerId, getPrescriptionsByCustomer]);

  return (
    <RemoteDataContent value={rPrescriptions}>
      <RemoteDataContent.Success>
        {(prescriptions: Prescription[]) => {
          return (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('isActive')}</TableCell>
                    <TableCell>{t('caseId')}</TableCell>
                    <TableCell>{t('caseStatus')}</TableCell>
                    <TableCell>{t('subscriptionId')}</TableCell>
                    <TableCell>{t('clinician')}</TableCell>
                    <TableCell>{t('sku')}</TableCell>
                    <TableCell>{t('quantity')}</TableCell>
                    <TableCell>{t('dailySupply')}</TableCell>
                    <TableCell>{t('signed')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(prescriptions || []).map((prescription, index) => {
                    const signedDate = DateTime.fromFormat(prescription.signed, _databaseFormat);
                    const expired =
                      DateHelper.isOlderThanOneYear(signedDate) || DateHelper.isFirstAnniversary(signedDate)
                        ? signedDate
                        : null;
                    const sku =
                      typeof prescription.medication.metadata === 'string'
                        ? prescription.medication.metadata
                        : prescription.medication.metadata.sku;

                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {expired === null ? (
                            <Icons.Checked />
                          ) : expired ? (
                            <Icons.Cancel />
                          ) : (
                            <Icons.Cancel color="disabled" />
                          )}
                        </TableCell>
                        <TableCell>
                          <Link href={`${process.env.REACT_APP_MDI_URL}/tabs/cases/${prescription.caseId}`}>
                            {prescription.caseId}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Chip text={t('completed')} color="success" />
                        </TableCell>
                        <TableCell>{prescription.subscriptionId || '-'}</TableCell>
                        <TableCell>{prescription.clinician?.fullName || '-'}</TableCell>
                        <TableCell>{sku}</TableCell>
                        <TableCell>{prescription.quantity}</TableCell>
                        <TableCell>{prescription.daysSupply}</TableCell>
                        <TableCell>{prescription.signed}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      </RemoteDataContent.Success>
    </RemoteDataContent>
  );
};

export default PrescriptionTable;
