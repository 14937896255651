import axios from 'axios';
import { Paginated } from '../common/Paginated';
import { HealthUpdateTrackerModel } from '../models';
import useService from '../common/useService';
import { PageProps } from '../common/ui-component/data-table/filters/backoffice-query';

const useGetHealthUpdateTrackers = () =>
  useService((params: PageProps) =>
    axios.get<Paginated<HealthUpdateTrackerModel>>(`/health-update-tracker`, { params }),
  );

const useGetHealthUpdateTrackerById = () => useService((id: string) => axios.get(`/health-update-tracker/${id}`));

const HealthUpdateTrackerService = {
  useGetHealthUpdateTrackers,
  useGetHealthUpdateTrackerById,
};

export default HealthUpdateTrackerService;
