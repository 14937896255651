import { FormControl, MenuItem, Select } from '@mui/material';
import { ReactNode, createContext } from 'react';
import { TooltipsV2 } from './tooltips';

const DisabledContext = createContext<boolean>(false);

const Item = ({ children, disabled }: { children: ReactNode; disabled?: boolean }) => {
  return (
    <DisabledContext.Consumer>
      {(_disabled) => <MenuItem disabled={_disabled || disabled}>{children}</MenuItem>}
    </DisabledContext.Consumer>
  );
};

const TooltipedItem = ({ children, disabled = false }: { children: ReactNode; disabled: boolean }) => {
  return (
    <DisabledContext.Provider value={disabled}>
      <Item>
        <TooltipsV2>{children}</TooltipsV2>
      </Item>
    </DisabledContext.Provider>
  );
};

const Dropdown = ({ children }: { children: ReactNode }) => {
  return (
    <FormControl fullWidth margin="normal">
      <Select>{children}</Select>
    </FormControl>
  );
};

Dropdown.Item = Item;

Dropdown.TooltipedItem = TooltipedItem;

export default Dropdown;
