import { errorHandler, Loader } from '..';
import { fold, RemoteData } from '../../../utils/remote-data';
import { Children, cloneElement, isValidElement, ReactNode } from 'react';

const isSuccess = (node: ReactNode) => {
  if (typeof node === 'boolean') return false;
  if (typeof node === 'number') return false;
  if (typeof node === 'string') return false;
  if (node && 'type' in node) {
    return node.type === Success;
  }

  return false;
};

const Success = <T,>({ value, children }: { value?: T; children: (value: T) => JSX.Element | JSX.Element[] }) => {
  if (value) return <>{children(value)}</>;
  return <></>;
};

const isNotAsked = (node: ReactNode) => {
  if (typeof node === 'boolean') return false;
  if (typeof node === 'number') return false;
  if (typeof node === 'string') return false;
  if (node && 'type' in node) return node.type === NotAsked;

  return false;
};

const NotAsked = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return <>{children}</>;
};

const isLoading = (node: ReactNode) => {
  if (typeof node === 'boolean') return false;
  if (typeof node === 'number') return false;
  if (typeof node === 'string') return false;
  if (node && 'type' in node) return node.type === Loading;

  return false;
};

const Loading = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return <>{children}</>;
};

const isFailure = (node: ReactNode) => {
  if (typeof node === 'boolean') return false;
  if (typeof node === 'number') return false;
  if (typeof node === 'string') return false;
  if (node && 'type' in node) return node.type === Failure;

  return false;
};

const Failure = ({
  error,
  children,
}: {
  error?: unknown;
  children: (value: unknown) => JSX.Element | JSX.Element[];
}) => {
  return <>{children(error)}</>;
};

interface Props<T> {
  value: RemoteData<T, unknown>;
  children: ((value: T) => JSX.Element | JSX.Element[]) | JSX.Element | JSX.Element[];
}

const RemoteDataContent = <T,>(props: Props<T>) => {
  const { value, children } = props;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const whenNotAsked = Children.toArray(children).filter(isNotAsked)[0];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const whenLoading = Children.toArray(children).filter(isLoading)[0];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const whenSuccess = Children.toArray(children).filter(isSuccess)[0];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const whenFailure = Children.toArray(children).filter(isFailure)[0];

  const result = fold(
    whenNotAsked ? () => whenNotAsked : () => <Loader />,
    whenLoading ? () => whenLoading : () => <Loader />,
    whenSuccess && isValidElement(whenSuccess)
      ? (v: T) => <>{cloneElement(whenSuccess, { ...whenSuccess.props, value: v })}</>
      : () => <></>,
    whenFailure && isValidElement(whenFailure)
      ? (v: unknown) => <>{cloneElement(whenFailure, { ...whenFailure.props, error: v })}</>
      : (e) => errorHandler(e),
  )(value);

  return <>{result}</>;
};

RemoteDataContent.Success = Success;
RemoteDataContent.Failure = Failure;
RemoteDataContent.Loading = Loading;
RemoteDataContent.NotAsked = NotAsked;

export default RemoteDataContent;
