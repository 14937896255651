import React from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';

interface Props<A> {
  children: React.ReactNode;
  defaultValues: DefaultValues<A>;
  onSubmit: (value: A) => void;
  id?: string;
}

function Form<A>({ id, children, defaultValues, onSubmit }: Props<A>) {
  const methods = useForm({ defaultValues });
  const { handleSubmit } = methods;
  return (
    <FormProvider {...methods}>
      {/* @ts-expect-error Note: To Verify because is work */}
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
}

export default Form;
