import { GridActionsCellItemProps, GridActionsColDef, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Paginated } from '../common/Paginated';
import { BtnPrimary, DataTable, PageProps } from '../common/ui-component';
import { RemoteData } from '../utils/remote-data';
import { TrackingEmail } from './caution-form/email-sending.dto';
import { trackingEmailEntity } from './tracking.email.model';

type GetAction = (param: GridRowParams) => React.ReactElement<GridActionsCellItemProps>[];

const getColumns = (t: (key: string) => string, filterOut: string[], getActions?: GetAction) => {
  const cols: GridColDef[] = [
    { field: 'orderId', headerName: t('orderId'), flex: 0.1 },
    { field: 'version', headerName: t('version'), flex: 0.1 },
    { field: 'pharmacyName', headerName: t('pharmacyName'), flex: 0.1 },
    {
      field: 'trackingNumber',
      renderCell: (params) => <pre>{params.row.templateData.trackingNumber}</pre>,
      headerName: t('trackingNumber'),
      flex: 0.2,
    },
  ];

  const filteredCol = filterOut.length ? cols.filter((x) => !filterOut.some((y) => y === x.field)) : cols;

  if (getActions) {
    const actions: GridActionsColDef = {
      field: 'actions',
      type: 'actions',
      flex: 0.08,
      getActions,
    };
    filteredCol.push(actions);
  }

  return filteredCol;
};

interface Props {
  defaultParams?: { [key: string]: string | number | null | undefined };
  getEmails: (params?: PageProps | undefined) => void;
  emails: RemoteData<Paginated<trackingEmailEntity>, unknown>;
  getActions?: GetAction;
  hasToolbar?: boolean;
  filterOut?: string[];
  currentRoute?: string;
  sendTrackingEmailHash?: string;
  trackingEmailToSend?: TrackingEmail;
  onClose?: () => void;
}

const TrackingEmailList = ({
  defaultParams,
  getEmails,
  emails,
  getActions,
  hasToolbar = true,
  filterOut = [],
  onClose,
}: Props) => {
  const { t } = useTranslation('email_tracking');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetcher = useCallback((param: any) => getEmails({ ...defaultParams, ...param }), [getEmails, defaultParams]);

  return (
    <>
      <DataTable
        sx={{ minWidth: 800 }}
        cols={getColumns(t, filterOut, getActions)}
        fetcher={fetcher}
        datas={emails}
        toRow={(order: trackingEmailEntity) => ({
          ...order,
          id: `${order.orderId} + ${order.version} + ${order.pharmacyName}`,
        })}
        hasToolbar={hasToolbar}
        hideFooter
      />

      <BtnPrimary sx={{ m: 0, maxWidth: 100, marginTop: 2 }} onClick={onClose}>
        Close
      </BtnPrimary>
    </>
  );
};

export default TrackingEmailList;
