import { Controller, useFormContext } from 'react-hook-form';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  label: string;
  readOnly?: boolean;
  required?: boolean;
}

const TextInput = ({ label, name, readOnly, required }: Props) => {
  const defaultCountry = 'US';
  const { control } = useFormContext();
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (value) => matchIsValidTel(value),
        required,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const helperText = !!error ? t('invalidPhoneNumber') : undefined;
        return (
          <MuiTelInput
            margin="normal"
            label={label}
            onChange={onChange}
            value={value == null ? undefined : value}
            disabled={readOnly}
            required={required}
            defaultCountry={defaultCountry}
            helperText={helperText}
            error={!!error}
            onlyCountries={['US']}
            forceCallingCode={false}
            fullWidth
          />
        );
      }}
    />
  );
};

export default TextInput;
