import { useTranslation } from 'react-i18next';
import OrdersService from './orders.service';
import { Title } from '../common/ui-component';
import { Box, Switch, Typography } from '@mui/material';
import { useState, useCallback } from 'react';
import StuckOrders from './OrdersStuck';
import StuckOrderV2 from '../stuck-order/StuckOrders';
import StuckOrdersService from '../stuck-order/stuck-order.service';

function OrdersStuck() {
  const { t } = useTranslation('order');
  const [getStuckOrdersV2, stuckOrdersV2] = OrdersService.useGetStuckOrdersV2();
  const [getStuckOrdersV3, stuckOrdersV3] = StuckOrdersService.useGetStuckOrders();
  const [listUsed, setListUsed] = useState(true);

  const _getStuckOrdersV2 = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (params: any) => {
      return getStuckOrdersV2(params);
    },
    [getStuckOrdersV2],
  );

  const _getStuckOrdersV3 = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (params: any) => {
      return getStuckOrdersV3(params);
    },
    [getStuckOrdersV3],
  );

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Title>{t('stuckOrders')}</Title>
        <Box display="flex" alignItems="center">
          <Typography style={{ marginRight: 1 }}>v1</Typography>
          <Switch defaultChecked onChange={(e) => setListUsed(e.target.checked)} />
          <Typography style={{ marginLeft: 1 }}>v2</Typography>
        </Box>
      </Box>

      {listUsed ? (
        <StuckOrderV2 listId="stuck-order-table-2" orders={stuckOrdersV3} getStuckOrders={_getStuckOrdersV3} />
      ) : (
        <StuckOrders listId="stuck-order-table" orders={stuckOrdersV2} getOrders={_getStuckOrdersV2} />
      )}
    </>
  );
}

export default OrdersStuck;
