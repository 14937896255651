import { DateTime } from 'luxon';
import { _databaseFormat } from '../utils/date/helper';
import { TFunction } from 'react-i18next';
import { HealthUpdateTrackerSubscriptionModel } from '../models';

type PartialMedicalCase = {
  healthUpdateDueDate: string | null;
  lastHealthUpdate: string | null;
};

const getNeedsToCompleteHUFBadgeInfo = (dates: PartialMedicalCase): { text: string; color: string } => {
  if (!dates.healthUpdateDueDate || !dates.lastHealthUpdate) return { text: 'Up to Date', color: 'success' };
  const healthUpdateDueDate = DateTime.fromFormat(dates.healthUpdateDueDate, _databaseFormat).startOf('day');
  const lastHealthUpdate = DateTime.fromFormat(dates.lastHealthUpdate, _databaseFormat).startOf('day');

  const startOfToday = DateTime.now().toUTC().startOf('day');
  const sixtyDaysInThePast = startOfToday.minus({ days: 60 });
  const sixtyDaysInTheFuture = startOfToday.plus({ days: 60 });

  const isWithin60DaysOfHUDD = healthUpdateDueDate <= sixtyDaysInTheFuture;
  const lastHealthUpdateIsOlderThan60Days = lastHealthUpdate <= sixtyDaysInThePast;
  const healthUpdateDueDateIsInThePast = healthUpdateDueDate < startOfToday;

  const needsToCompleteHUF =
    healthUpdateDueDateIsInThePast || (isWithin60DaysOfHUDD && lastHealthUpdateIsOlderThan60Days);

  if (needsToCompleteHUF) return { text: 'Update Needed', color: 'warning' };
  return { text: 'Up to Date', color: 'success' };
};

const getSubscriptionStatus = (
  t: TFunction<'health_update_tracker', undefined>,
  subscriptions: HealthUpdateTrackerSubscriptionModel[],
) => {
  const statuses = subscriptions.map((subscription): 'active' | 'paused' | 'cancelled' | 'unknown' => {
    switch (subscription.status) {
      case 'active':
      case 'active-action-required':
        return 'active';
      case 'cancelled':
        return 'cancelled';
      case 'on-hold':
      case 'awaiting-doctor-review':
      case 'paused-action_required':
      case 'pending-cancel':
      case 'pending-photos':
        return 'paused';
      default:
        return 'unknown';
    }
  });
  if (statuses.includes('active')) return t('active');
  if (statuses.includes('paused')) return t('paused');
  if (statuses.every((status) => status === 'cancelled')) return t('cancelled');
  return 'Unknown';
};

const HealthUpdateHelper = {
  getNeedsToCompleteHUFBadgeInfo,
  getSubscriptionStatus,
};

export default HealthUpdateHelper;
