import { DateHelper } from '../../utils/date';
import { List, ListItem, ListItemText } from '@mui/material';

const StatusHistoryList = ({
  history,
}: {
  history: { id: string | number; source: string; status: string; created: string }[];
}) => {
  return (
    <List
      sx={{
        maxHeight: 820,
        overflowY: 'auto',
        width: '100%',
      }}
    >
      {history.map((o) => (
        <ListItem key={o.id}>
          <ListItemText
            primary={o.status}
            secondary={
              <span>
                {o.source ? <span>{`Source : ${o.source}`}</span> : undefined}
                <span>{`Date : ${DateHelper.dateTime(o.created).value}`}</span>
              </span>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default StatusHistoryList;
