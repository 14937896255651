const HAIR_YARD_EN = {
  id: 'Id',
  removed: 'Removed',
  removedWithTime: 'Removed (with time)',
  photoAttached: 'Photo Attached',
  photoAttachedWithTime: 'Photo Attached (with time)',
  created: 'Created',
  createdWithTime: 'Created (with time)',
  updated: 'Updated',
  updatedWithTime: 'Updated (with time)',
  customerId: 'Customer Id',
  medicalCaseId: 'Medical Case Id',
  medicalCaseCreated: 'Medical Case Created',
  medicalCaseCreatedWithTime: 'Medical Case Created (with time)',
  gender: 'Gender',
  address: 'Address',
  medicalInformation: 'Medical Information',
  driverLicenseRequired: 'Driver License Required',
  driverLicenseUploaded: 'Driver License Uploaded',
  driverLicenseUploadedWithTime: 'Driver License Uploaded (with time)',
  hairPhotoUploaded: 'Hair Photo Uploaded',
  hairPhotoUploadedWithTime: 'Hair Photo Uploaded (with time)',
  hairLossLocations: 'Hair Loss Locations',
  hairYard: 'Hair Yard',
  videoRequired: 'Video Required',
};

export default HAIR_YARD_EN;
