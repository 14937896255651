import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BtnPrimary, Info, Link, Section, SectionContent, Subtitle } from '../common/ui-component';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent';
import CustomerService from '../customer/customer.service';
import { CUSTOMERS_ROUTE } from '../Router';
import Helper from '../utils/date/helper';
import { withDefault } from '../utils/result';
import { OrderModel, AddressModel, CustomerModel } from '../models';

const CustomerInfo = ({ order }: { order: OrderModel }) => {
  const { t } = useTranslation('order');
  const [getCustomer, rCustomer] = CustomerService.useGetCustomer();
  const [syncByWooId] = CustomerService.useSyncByWooId();
  const { billingAddress, shippingAddress, customerId } = order;
  const { phone } = billingAddress;
  const getAddresses = ({ address1, address2, postcode, state, city }: AddressModel): [string, string] => {
    const line1 = `${address1} ${address2 && `, ${address2}`}`;
    const line2 = `${city}, ${state} ${postcode}`;
    return [`http://maps.google.com/?q=${line1} ${line2}`, `${line1}\n${line2}`];
  };

  useEffect(() => {
    if (customerId) {
      getCustomer(customerId?.toString());
    }
  }, [getCustomer, customerId]);

  const [redirectShippingAddress, formattedShippingAddress] = getAddresses(shippingAddress);

  return (
    <RemoteDataContent
      value={rCustomer}
      whenNotAsked={() => (
        <Section title={<Subtitle>{`Customer`}</Subtitle>}>
          <SectionContent>
            <BtnPrimary onClick={() => syncByWooId(order.woocommerceCustomerId)}>{t('sync')}</BtnPrimary>
          </SectionContent>
        </Section>
      )}
      whenSuccess={(customer: CustomerModel) => (
        <Section
          title={
            <Link to={`${CUSTOMERS_ROUTE}/${customer.id}`}>
              <Subtitle>{`Customer #${customer.id}`} </Subtitle>
            </Link>
          }
        >
          <SectionContent>
            <Info label={t('name')}>{`${customer.firstName} ${customer.lastName}`}</Info>
            <Info label={t('dateOfBirth')}>{withDefault('--')(Helper.onlyDateOfBirth(customer.dateOfBirth))}</Info>
            <Info label={t('shippingAddress')}>
              <Link href={redirectShippingAddress} target="_blank">
                {formattedShippingAddress}
              </Link>
            </Info>
          </SectionContent>
          <SectionContent>
            <Info label={t('email')}>
              <Link href={`mailto:${customer.email}`}>{customer.email}</Link>
            </Info>
            <Info label={t('phone')}>
              <Link href={`tel:${phone}`}>{phone || ''}</Link>
            </Info>
            <div />
          </SectionContent>
        </Section>
      )}
    />
  );
};

export default CustomerInfo;
