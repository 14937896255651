import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedForm, DropdownInput, TextInput } from '../../common/form-controller';
import { map, withDefault } from '../../utils/remote-data';
import { BtnDanger, BtnPrimary, FormRow } from '../../common/ui-component';
import { FormSection } from '../../common/ui-component';
import { useFormContext, useWatch } from 'react-hook-form';
import { getAvailableShippingMethods, OrderShipping } from './order-shipping.dto';
import { PharmacyNameEnum, PharmacyOrder } from '../../pharmacy/pharmacy-order.model';
import PharmacyService from '../pharmacy.service';

const ShippingMethodsDropdown = () => {
  const { t } = useTranslation('pharmacy');

  useWatch({ name: 'carrier' });
  const shippingForm = useFormContext();

  const [defaultShippingMethods, shippingMethodsItems] = getAvailableShippingMethods(shippingForm.getValues('carrier'));

  useEffect(() => {
    shippingForm.setValue('shippingMethod', defaultShippingMethods);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultShippingMethods]);

  return (
    <DropdownInput
      name="shippingMethod"
      label={t('shippingMethod')}
      items={shippingMethodsItems || []}
      toOption={(x) => ({
        displayText: x,
        value: x,
        id: x,
      })}
    />
  );
};

interface Props {
  pharmacyOrder: PharmacyOrder | undefined;
  onSuccess: (order: PharmacyOrder) => void;
  close: () => void;
}

const ShippingForm = ({ pharmacyOrder, onSuccess, close }: Props) => {
  const { t } = useTranslation(['pharmacy', 'common']);
  const [shippingOrder, rOrder] = PharmacyService.useShippingOrder();
  const onSubmit = useCallback(
    (orderShippingDto: OrderShipping, id: number) => {
      shippingOrder({ id, orderShipping: orderShippingDto });
    },
    [shippingOrder],
  );
  const shippingMethod = pharmacyOrder?.pharmacyName === PharmacyNameEnum.CHEMISTRY_RX ? ['USPS'] : ['FEDEX', 'USPS'];

  useEffect(() => {
    const nothing = () => {};
    const rFn = map((o: PharmacyOrder) => () => onSuccess(o))(rOrder);
    const fn = withDefault(nothing)(rFn);
    fn();
  }, [rOrder, onSuccess]);

  return !pharmacyOrder ? (
    <>
      <p>{t('common:weird')}</p>
      <BtnDanger sx={{ m: 0 }} onClick={close}>
        {t('pharmacy:close')}
      </BtnDanger>
    </>
  ) : (
    <ConnectedForm<OrderShipping>
      defaultValues={{
        orderId: pharmacyOrder.orderId,
        version: pharmacyOrder.version,
        carrier: pharmacyOrder?.pharmacyName === PharmacyNameEnum.CHEMISTRY_RX ? 'USPS' : 'FEDEX',
        shippingMethod: pharmacyOrder?.pharmacyName === PharmacyNameEnum.CHEMISTRY_RX ? 'usps_first' : 'fedex_2_day',
        address1: pharmacyOrder.address1,
        address2: pharmacyOrder.address2,
        city: pharmacyOrder.city,
        country: pharmacyOrder.country,
        state: pharmacyOrder.state,
        zip: pharmacyOrder.zip,
        pharmacyName: pharmacyOrder.pharmacyName,
      }}
      onSubmit={(formValue) => onSubmit(formValue, pharmacyOrder.id)}
    >
      <FormSection title={t('pharmacy:shippingDetails')}>
        <FormRow>
          <TextInput readOnly name="orderId" label={t('pharmacy:orderId')} />
          <TextInput readOnly name="version" label={t('pharmacy:version')} />
          <TextInput readOnly name="pharmacyName" label={t('pharmacy:pharmacy')} />
        </FormRow>
        <FormRow>
          <DropdownInput
            name="carrier"
            label={t('pharmacy:carrier')}
            items={shippingMethod}
            toOption={(x) => ({
              displayText: x,
              value: x,
              id: x,
            })}
          />
        </FormRow>
        <FormRow>
          <ShippingMethodsDropdown />
        </FormRow>
      </FormSection>
      <FormSection title={t('pharmacy:shippingAddress')}>
        <FormRow>
          <TextInput required name="address1" label={t('pharmacy:address1')} />
          <TextInput name="address2" label={t('pharmacy:address2')} />
          <TextInput required name="city" label={t('pharmacy:city')} />
        </FormRow>
        <FormRow>
          <TextInput required name="state" label={t('pharmacy:state')} />
          <TextInput required name="zip" label={t('pharmacy:zip')} />
          <TextInput required name="country" label={t('pharmacy:country')} />
        </FormRow>
      </FormSection>
      <BtnPrimary sx={{ m: 0 }} type="submit">
        {t('pharmacy:send')}
      </BtnPrimary>
    </ConnectedForm>
  );
};

export default ShippingForm;
