import { TFunction } from 'react-i18next';
import { PharmacyOrder } from './pharmacy-order.model';

const getClinicianAssignedError = (pharmacyOrder: PharmacyOrder, t: TFunction<'pharmacy', undefined>) =>
  !pharmacyOrder.clinician && t('hasNoClinicianAssigned');

const getApprovedDoctorError = (pharmacyOrder: PharmacyOrder, t: TFunction<'pharmacy', undefined>) =>
  !pharmacyOrder.hasBeenApprovedByDoctor && t('isNotApprovedByDoctor');

const getBeenPayedError = (pharmacyOrder: PharmacyOrder, t: TFunction<'pharmacy', undefined>) =>
  !pharmacyOrder.hasBeenPayed && t('isNotPaid');

const getPrescriptionError = (pharmacyOrder: PharmacyOrder, t: TFunction<'pharmacy', undefined>) =>
  pharmacyOrder.rxItems.length && !pharmacyOrder.rxItems.every((x) => x.prescription) && t('missingPrescriptions');

const getAlreadyBeenShippedError = (pharmacyOrder: PharmacyOrder, t: TFunction<'pharmacy', undefined>) =>
  pharmacyOrder.hasAlreadyBeenShipped && t('isAlreadyShipped');

const getRefundedError = (pharmacyOrder: PharmacyOrder, t: TFunction<'pharmacy', undefined>) =>
  pharmacyOrder.hasBeenRefunded && t('isRefunded');

const getCancelledError = (pharmacyOrder: PharmacyOrder, t: TFunction<'pharmacy', undefined>) =>
  pharmacyOrder.hasBeenRefunded && t('isCancelled');

const getAllErrors = (pharmacyOrder: PharmacyOrder, t: TFunction<'pharmacy', undefined>) =>
  [
    getClinicianAssignedError,
    getApprovedDoctorError,
    getBeenPayedError,
    getPrescriptionError,
    getAlreadyBeenShippedError,
    getRefundedError,
    getCancelledError,
  ]
    .map((fn) => fn(pharmacyOrder, t))
    .filter((x): x is string => typeof x === 'string');

const PharmacyValidator = {
  getClinicianAssignedError,
  getApprovedDoctorError,
  getBeenPayedError,
  getPrescriptionError,
  getAlreadyBeenShippedError,
  getRefundedError,
  getCancelledError,
  getAllErrors,
};

export default PharmacyValidator;
