import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  label: string;
  type?: string;
  readOnly?: boolean;
  required?: boolean;
}

const emailAddressRegex =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const TextInput = ({ label, name, type, readOnly, required }: Props) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        pattern: emailAddressRegex,
        required,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const helperText = !!error ? t('invalidEmail') : undefined;
        return (
          <TextField
            label={label}
            value={value}
            type={type}
            onChange={onChange}
            disabled={readOnly}
            required={required}
            error={!!error}
            helperText={helperText}
            margin="normal"
            fullWidth
          ></TextField>
        );
      }}
    />
  );
};

export default TextInput;
