import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '../ui-component';

interface Props {
  name: string;
  label: string;
  type?: string;
  readOnly?: boolean;
  required?: boolean;
  errorIfEmpty?: boolean;
}

const TextInput = ({ label, name, type, readOnly, required, errorIfEmpty }: Props) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <TextField
            label={label}
            type={type}
            onChange={onChange}
            value={value == null ? undefined : value}
            readOnly={readOnly}
            required={required}
            error={!!errorIfEmpty && !value}
          />
        );
      }}
    />
  );
};

export default TextInput;
