import FormRow from './FormRow';
import Subtitle from '../../typography/Subtitle';
import { Grid as MuiGrid } from '@mui/material';

interface Props {
  title: string;
  children: React.ReactNode;
}

const Grid = ({ children }: { children: React.ReactNode }) => {
  return (
    <MuiGrid container spacing={2}>
      {children}
    </MuiGrid>
  );
};

const FormSection = ({ title, children }: Props) => {
  return (
    <>
      <FormRow>
        <Subtitle>{title}</Subtitle>
      </FormRow>
      {children}
    </>
  );
};

FormSection.Row = FormRow;
FormSection.Grid = Grid;

export default FormSection;
