import { List, ListItem, SxProps } from '@mui/material';

type Props = {
  listItems: string[];
  sx?: SxProps | undefined;
};

const BulletList = ({ listItems, sx }: Props) => {
  return (
    <List sx={{ listStyleType: 'disc', ...sx }}>
      {listItems.map((listItem) => (
        <ListItem sx={{ display: 'list-item' }}>{listItem}</ListItem>
      ))}
    </List>
  );
};

export default BulletList;
