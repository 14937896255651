const getKeys = (o: object, path = ''): string[] => {
  if (!o || typeof o !== 'object') return [path];
  return Object.keys(o)
    .map((key) => {
      const t = o[key as keyof unknown];

      return getKeys(t, path ? [path, key].join('.') : key);
    })
    .flat();
};

const paths = (o: object) => {
  return getKeys(o).toString().split(',');
};

const ObjectHelper = {
  paths,
};

export default ObjectHelper;
