import { useTranslation } from 'react-i18next';
import { BtnDanger, BtnPrimary } from '../buttons';
import RoutedModal from './RoutedModal';

interface Props<T> {
  onConfirm: (value: T, close: () => void) => void;
  onClose?: () => void;
  title: string;
  message?: string;
  predicatedHash: string;
  defaultUrl: string;
  value: T | undefined | null;
}

const ConfirmRoutedModal = <T,>(props: Props<T>) => {
  const { title, predicatedHash, defaultUrl, message, onConfirm, value, ...rest } = props;
  const { t } = useTranslation('common');

  const handleClose = (onModalClose: () => void) => () => {
    rest.onClose && rest.onClose();
    onModalClose();
  };

  return (
    <RoutedModal
      predicatedHash={predicatedHash}
      defaultUrl={defaultUrl}
      title={title}
      actions={(close) => {
        return (
          <>
            {value && <BtnDanger onClick={() => onConfirm(value, close)}>{t('confirm')}</BtnDanger>}
            <BtnPrimary onClick={handleClose(close)}>{t('close')}</BtnPrimary>
          </>
        );
      }}
    >
      <>{value ? <span>{message || t('areYouSure')}</span> : <span>Something wrong happened close and retry</span>}</>
    </RoutedModal>
  );
};

export default ConfirmRoutedModal;
