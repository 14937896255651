import { useLocation } from 'react-router-dom';
import { SubNavbar } from '../common/ui-component';
import { ORDERS_ROUTE } from '../Router';

const Navbar = () => {
  const { pathname } = useLocation();

  return (
    <SubNavbar
      currentRoute={pathname}
      routes={[
        { href: ORDERS_ROUTE, label: 'List' },
        { href: `${ORDERS_ROUTE}/stuck`, label: 'Stuck Orders' },
      ]}
    />
  );
};

export default Navbar;
