import { Controller, useFormContext } from 'react-hook-form';
import MultipleDropdownFieldV2 from '../ui-component/inputs/MultipleDropdownField.v2';

type ObjectValuesProps<T, V> = {
  name: string;
  label: string;
  type?: string;
  readOnly?: boolean;
  required?: boolean;
  items: T[];
  toOption: (t: T) => { displayText: string; value: V };
  getIdFromValue: (value: V) => string;
};

type Props<T, V> = V extends string | number
  ? Omit<ObjectValuesProps<T, V>, 'getIdFromValue'>
  : ObjectValuesProps<T, V>;

const MultipleDropdownInputV2 = <T, V>(props: Props<T, V>) => {
  const { label, name, readOnly, required, items: options, toOption } = props;
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
      }: {
        field: { onChange: (t: V[] | undefined) => void; value: V[] | null };
      }) => {
        return (
          <MultipleDropdownFieldV2<T, V>
            label={label}
            onChange={onChange}
            values={value == null ? [] : value}
            items={options}
            readonly={readOnly}
            toOption={toOption}
            required={required}
            getIdFromValue={'getIdFromValue' in props ? props.getIdFromValue : undefined}
          />
        );
      }}
    />
  );
};

export default MultipleDropdownInputV2;
