import axios from 'axios';
import { Paginated } from '../common/Paginated';
import { PageProps as PagePropsPro } from '../common/ui-component/data-table/filters/backoffice-query';
import useService from '../common/useService';
import { DeadLetterModel } from './dead-letter.model';

const useGetDeadLettersV2 = <T>() =>
  useService((params: PagePropsPro) =>
    axios.get<Paginated<DeadLetterModel<T>>>('/v2/dead-letter', {
      params,
    }),
  );

const useGetDeadLetter = <T>() => useService((id) => axios.get<DeadLetterModel<T>>(`/dead-letter/${id}`));

const useRetryDeadLetter = () => useService((id: string | number) => axios.post(`/dead-letter/${id}`));

const useDeleteDeadLetter = () => useService((id) => axios.delete(`/dead-letter/${id}`));

const useUpdateDeadLetter = <T>() =>
  useService(({ id, data }: { id: string; data: T }) => axios.put(`/dead-letter/${id}`, data));

const DeadLetterService = {
  useGetDeadLettersV2,
  useGetDeadLetter,
  useRetryDeadLetter,
  useDeleteDeadLetter,
  useUpdateDeadLetter,
};

export default DeadLetterService;
