import { List, ListItem, ListItemText } from '@mui/material';
import React, { useMemo } from 'react';
import { groupBy } from '../../utils/array';
import { SyncDeadLetterResponse } from './sync-dead-letter.model';

const SyncResponse = ({ response }: { response: SyncDeadLetterResponse }) => {
  const listMemo = useMemo(() => {
    const extractEmail = (x: { id: number; message: string }) => ({
      id: x.id,
      email: x.message.split('email : ')[1],
    });

    const emailIds = groupBy(
      response.errors.filter((x) => x.message.includes('email')).map(extractEmail),
      (x) => x.email,
    );

    return Object.entries(emailIds).map(([mail, err]) =>
      !!mail ? (
        <ListItem key={mail}>
          <ListItemText primary={mail} secondary={err.map((x) => x.id).join(',')} />
        </ListItem>
      ) : undefined,
    );
  }, [response]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <span>Success : {response.successCount}</span>
        <span>Failure : {response.errorCount}</span>
      </div>
      <List dense>{listMemo}</List>
    </>
  );
};

export default React.memo(SyncResponse);
