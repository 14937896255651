import { Typography } from '@mui/material';

type TextProps = { children: string | number | JSX.Element | JSX.Element[] };

const Paragraph = ({ children }: TextProps) => {
  return (
    <Typography variant="subtitle1" fontSize={14} sx={{ fontWeight: 'bold', whiteSpace: 'pre-wrap' }}>
      {children || '-'}
    </Typography>
  );
};

export default Paragraph;
