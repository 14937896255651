import axios from 'axios';
import { Paginated } from '../common/Paginated';
import { SearchParams } from '../common/ui-component/data-table/';
import useService from '../common/useService';
import { OrderModel } from '../models';
import { PageProps } from '../common/ui-component/data-table/filters/backoffice-query';

export type License = {
  type: string;
  value: string;
};
export interface Clinician {
  firstName: string;
  lastName: string;
  clinicianId: string;
  fullName: string;
  specialty: string;
  photo: {
    path: string;
    name: string;
    mime_type: string;
    url: string;
    url_thumbnail: string;
    file_id: string;
  };
  licenses?: License[];
}

export interface Prescription {
  id: number;
  orderId: number;
  caseId: string;
  dosespotPrescriptionId: string;
  medication: {
    metadata: string | { sku: string };
  };
  refills: number;
  refillsCount: number;
  quantity: number;
  daysSupply: number;
  clinicalNote: string;
  directions: string;
  subscriptionId: number;
  signed: string;
  clinician: Clinician;
}

const useGetOrder = () => useService((id: string) => axios.get<OrderModel>(`/orders/${id}`));

const useGetOrders = () =>
  useService((params: PageProps) =>
    axios.get<Paginated<OrderModel>>(`/orders`, {
      params,
    }),
  );

const useGetOrdersV2 = () =>
  useService((params: PageProps) =>
    axios.get<Paginated<OrderModel>>(`/v2/orders/`, {
      params,
    }),
  );

export interface CreatedAtStats {
  isRenewal: string;
  total: number;
}

const useCreatedAtStats = () =>
  useService((params: SearchParams) => axios.get<CreatedAtStats[]>(`/orders/stats/created`, { params }));

const useGetPrescriptions = () => useService((id: string) => axios.get<Prescription[]>(`/order-prescriptions/${id}`));

const useGetPrescriptionsByCustomer = () =>
  useService((id: number) => axios.get<Prescription[]>(`/order-prescriptions/customer/${id}`));

const useSearchPrescriptions = () =>
  useService((params: PageProps) =>
    axios.get<Paginated<Prescription>>(`/order-prescriptions`, {
      params,
    }),
  );

const useSyncOrderWithWoo = () => useService((id: number) => axios.post(`/orders/${id}/sync`));

export type NeededAttentionFilter =
  | 'stuck-in-pending-photo'
  | 'stuck-before-mdi'
  | 'stuck-in-mdi'
  | 'stuck-before-pharmacy'
  | 'stuck-in-pharmacy'
  | 'wrong-data';

const useGetStuckOrders = () =>
  useService((filter?: NeededAttentionFilter) =>
    axios.get<OrderModel[]>(`/orders/needed-attention`, { params: { 'filter-kind': filter } }),
  );

const useGetStuckOrdersV2 = () =>
  useService((params: PageProps) => axios.get<Paginated<OrderModel>>(`/v2/orders/needed-attention`, { params }));

const OrdersService = {
  useGetOrders,
  useGetOrdersV2,
  useGetOrder,
  useCreatedAtStats,
  useGetPrescriptions,
  useGetPrescriptionsByCustomer,
  useSearchPrescriptions,
  useGetStuckOrders,
  useGetStuckOrdersV2,
  useSyncOrderWithWoo,
};

export default OrdersService;
