const ORDER_EN = {
  beforeMdi: 'Before MDI',
  beforePharmacy: 'Before Pharmacy',
  billingAddress: 'Billing Address',
  billingEmail: 'Billing Email',
  billingPhone: 'Billing Phone',
  both: 'Both',
  cancelOrder: 'Cancel Order',
  caseId: 'Case Id',
  category: 'Category',
  childOf: 'Parent Order',
  clinician: 'Clinician',
  close: 'Close',
  confirmOrderFileDelete: 'Confirm Delete File {{id}}',
  created: 'Created',
  currency: 'Currency',
  customerId: 'Customer id',
  customerName: 'Customer Name',
  dateOfBirth: 'Date Of Birth',
  dateTime: 'Date/Time',
  dayAgo_one: '{{count}} day ago',
  dayAgo_other: '{{count}} days ago',
  details: 'Details',
  dob: 'Date Of Birth',
  email: 'Email',
  gender: 'Gender',
  hourAgo_one: '{{count}} hour ago',
  hourAgo_other: '{{count}} hours ago',
  id: 'id',
  inPharmacy: 'In Pharmacy',
  latestCaseId: 'Latest Case ID',
  mdiDetails: 'MDI Details',
  mdiStatus: 'MDI Status',
  medicalCaseId: 'Medical case Id',
  medicalInformation: 'Medical Information',
  mix: 'Mix',
  name: 'Name',
  oneOf: 'One Of',
  oneOff: 'One-Off',
  order: 'Order',
  orderActions: 'Order Actions',
  orderCreated: 'Order created',
  orderCreatedWithTime: 'Order created (with time)',
  orderDetails: 'Order Details',
  orderId: 'Order id',
  orders: 'Orders',
  orderStatuses: 'Order statuses',
  orderType: 'Order Type',
  otc: 'OTC',
  parent: 'Parent',
  parentOf: 'New Order',
  party: 'Party',
  patientId: 'Patient Id',
  pendingOrder: 'Pending Order',
  pendingOrders: 'Pending Orders',
  pharmacy: 'Pharmacy',
  pharmacyCancel: 'Pharmacy Cancel',
  pharmacyOrder: 'Pharmacy Order',
  pharmacyOrders: 'Pharmacy Orders',
  pharmacyReplacement: 'Pharmacy Replacement Order Form',
  pharmacyShipping: 'Pharmacy Shipping Order Form',
  pharmacyStatus: 'Pharmacy Status',
  phone: 'Phone',
  photos: 'Photos',
  prescriptions: 'Prescriptions',
  products: 'Products',
  quantityShort: 'QTY',
  questions: 'Questions',
  reason: 'Reason',
  refills: 'Refills',
  renewal: 'Renewal',
  replacementDetails: 'Replacement Details',
  replaceOrder: 'Replace Order',
  resend: 'Resend',
  resendEmail: 'Resend Tracking Email',
  resendShipping: 'Resend Shipment Tracking Email',
  rx: 'Rx',
  search: 'Search',
  send: 'Send',
  shippingAddress: 'Shipping Address',
  shippingMethodAddress: 'Change shipping method/address',
  shippingPhone: 'Shipping phone',
  sku: 'SKU',
  status: 'Status',
  statusHistory: 'Status History',
  stuckBeforeMdi: 'Stuck Before Mdi',
  stuckBeforePharmacy: 'Stuck Before Pharmacy',
  stuckMdi: 'Stuck in MDI',
  stuckMissingPhotos: 'Stuck Missing Photos',
  stuckPharmacy: 'Stuck in pharmacy',
  submit: 'Submit',
  subscription: 'Subscription',
  subscriptionId_other: 'Subscription ids',
  subscriptionId: 'Subscription id',
  sync: 'Sync',
  syncMdi: 'Sync Mdi Case',
  syncPrescriptions: 'Sync Prescriptions',
  syncOrderWithWoo: 'Sync order with Woo',
  total: 'Total',
  trackingEmail: 'Email Tracking',
  trackingId: 'Tracking ID',
  uniqueId: 'Unique Id',
  unknown: 'Unknown',
  updated: 'Updated',
  upload: 'Upload',
  uploadFile: 'Upload Files',
  uploadLicense: 'Upload License',
  uploadOther: 'Upload Other',
  stuck: 'Stuck',
  stuckOrders: 'Stuck Orders',
  wooCommerceId: 'Woo Commerce Id',
  wrongData: 'Wrong Data',
  errors: 'Errors',
};

export default ORDER_EN;
