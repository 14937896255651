import { Typography } from '@mui/material';

interface Props {
  children?: React.ReactNode | string;
}

const Title = ({ children }: Props) => {
  return (
    <Typography component="h1" variant="h4" sx={{ variant: 'h6' }}>
      {children}
    </Typography>
  );
};

export default Title;
