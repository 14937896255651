import { useTranslation } from 'react-i18next';
import { BtnPrimary, Info, Link, Loader, SectionContent, TextField, Title, errorHandler } from '../common/ui-component';
import { SearchResult } from './searchResult.entity';
import SearchService from './search.service';
import { useState } from 'react';
import { fold } from '../utils/remote-data';
import { CUSTOMERS_ROUTE, MEDICAL_CASE_ROUTE, ORDERS_ROUTE, SUBSCRIPTIONS_ROUTE } from '../Router';
import LightWeightSection from '../common/ui-component/layouts/LightWeightSection';
import CenteredContentBox from '../common/ui-component/layouts/CenteredContentBox';
import BulletList from '../common/ui-component/layouts/BulletList';

const Search = () => {
  const { t } = useTranslation('search');
  const [search, rSearchR] = SearchService.useSearch();

  const [query, setSearchQuery] = useState<string>('');

  const getWooCommerceUrl = (id: string) => `${process.env.REACT_APP_WOOCOMMERCE_URL}/post.php?post=${id}&action=edit`;
  const getWooCommerceCustomerEditUrl = (id: string) =>
    `${process.env.REACT_APP_WOOCOMMERCE_URL}/user-edit.php?user_id=${id}`;

  const getLineOrders = (searchResult: SearchResult) => {
    if (!searchResult.orders?.length) return <Info label={t('orders')}>--</Info>;
    return (
      <Info label={t('orders')}>
        <Info.Splitter>
          {searchResult.orders.map((order) => (
            <Info.Splitter.Item>
              <span>
                <Link sx={{ marginRight: '3px' }} href={`${ORDERS_ROUTE}/${order.id}`}>
                  {order.id}
                </Link>
              </span>
              <span>
                <Link sx={{ marginRight: '3px' }} href={getWooCommerceUrl(order.woocommerceId)}>
                  {`(${t('woocommerceId')} ${order.woocommerceId})`}
                </Link>
              </span>
            </Info.Splitter.Item>
          ))}
        </Info.Splitter>
      </Info>
    );
  };

  const getLineSubscriptions = (searchResult: SearchResult) => {
    if (!searchResult.subscriptions?.length) return <Info label={t('subscriptions')}>--</Info>;
    return (
      <Info label={t('subscriptions')}>
        <Info.Splitter>
          {searchResult.subscriptions.map((subscription) => (
            <Info.Splitter.Item>
              <span>
                <Link sx={{ marginRight: '3px' }} href={`${SUBSCRIPTIONS_ROUTE}/${subscription.id}`}>
                  {subscription.id}
                </Link>
              </span>

              <span>
                <Link sx={{ marginRight: '3px' }} href={getWooCommerceUrl(subscription.woocommerceId)}>
                  {`(${t('woocommerceId')} ${subscription.woocommerceId})`}
                </Link>
              </span>
            </Info.Splitter.Item>
          ))}
        </Info.Splitter>
      </Info>
    );
  };
  return (
    <>
      <CenteredContentBox>
        <Title>{t('searchUsing')}</Title>
      </CenteredContentBox>
      <CenteredContentBox>
        <div style={{ width: '50%' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <BulletList sx={{ paddingRight: '1rem' }} listItems={[t('customerId'), t('orderId')]} />
            <BulletList sx={{ paddingLeft: '1rem' }} listItems={[t('subscriptionId'), t('email')]} />
            <BulletList sx={{ paddingLeft: '1rem' }} listItems={[t('medicalCaseId')]} />
          </div>
          <TextField label="" onChange={(e) => setSearchQuery(e.target.value)} value={query} />
          <BtnPrimary sx={{ marginBottom: '2rem' }} onClick={() => search(query)}>
            {t('search')}
          </BtnPrimary>
        </div>
      </CenteredContentBox>

      {fold(
        () => null,
        () => <Loader />,
        (searchResults: SearchResult[]) => (
          <>
            {searchResults.map((searchResult) => (
              <LightWeightSection>
                <SectionContent>
                  <Info label={t('customer')}>
                    <Link sx={{ marginRight: '10px' }} href={`${CUSTOMERS_ROUTE}/${searchResult.customerId}`}>
                      {searchResult.customerId}
                    </Link>
                  </Info>
                  <Info label={t('woocommerceId')}>
                    <Link href={getWooCommerceCustomerEditUrl(searchResult.woocommerceId)}>
                      {searchResult.woocommerceId}
                    </Link>
                  </Info>
                  <Info label={t('firstName')}>{searchResult.firstName}</Info>
                  <Info label={t('lastName')}>{searchResult.lastName}</Info>
                  <Info label={t('email')}>{searchResult.email}</Info>
                </SectionContent>
                <SectionContent>
                  <Info label={t('medicalCase')}>
                    <Link sx={{ marginRight: '10px' }} href={`${MEDICAL_CASE_ROUTE}/${searchResult.medicalCaseId}`}>
                      {searchResult.medicalCaseId}
                    </Link>
                  </Info>
                </SectionContent>
                <SectionContent>{getLineOrders(searchResult)}</SectionContent>
                <SectionContent>{getLineSubscriptions(searchResult)}</SectionContent>
              </LightWeightSection>
            ))}
          </>
        ),
        errorHandler,
      )(rSearchR)}
    </>
  );
};

export default Search;
