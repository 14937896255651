import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Info, Link, Section, SectionContent, Subtitle } from '../common/ui-component';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent';
import CustomerService from '../customer/customer.service';
import { CUSTOMERS_ROUTE } from '../Router';
import { CustomerAddressModel } from '../models';

import Helper from '../utils/date/helper';

const CustomerInfo = ({ customerId }: { customerId: number }) => {
  const { t } = useTranslation('order');
  const [getCustomer, rCustomer] = CustomerService.useGetCustomer();
  const getAddresses = (address: CustomerAddressModel | undefined): [string, string] => {
    if (!address) {
      return ['', ''];
    }
    const { address1, address2, postcode, state, city } = address;
    const line1 = `${address1} ${address2 && `, ${address2}`}`;
    const line2 = `${city}, ${state} ${postcode}`;
    return [`http://maps.google.com/?q=${line1} ${line2}`, `${line1}\n${line2}`];
  };

  useEffect(() => {
    if (customerId) {
      getCustomer(customerId.toString());
    }
  }, [getCustomer, customerId]);
  return (
    <RemoteDataContent
      value={rCustomer}
      whenSuccess={(customer) => {
        const { firstName, lastName, email } = customer;
        const [redirectShippingAddress, formattedShippingAddress] = getAddresses(customer.shippingAddress);
        return (
          <Section
            title={
              <Link to={`${CUSTOMERS_ROUTE}/${customer.id}`}>
                <Subtitle>{`Customer #${customer.id}`} </Subtitle>
              </Link>
            }
          >
            <SectionContent>
              <Info label={t('name')}>{`${firstName} ${lastName}`}</Info>
              <Info label={t('dateOfBirth')}>{Helper.onlyDateOfBirth(customer.dateOfBirth).value}</Info>
              <Info label={t('shippingAddress')}>
                {redirectShippingAddress ? (
                  <Link href={redirectShippingAddress} target="_blank">
                    {formattedShippingAddress}
                  </Link>
                ) : (
                  '-'
                )}
              </Info>
            </SectionContent>
            <SectionContent>
              <Info label={t('email')}>
                <Link href={`mailto:${email}`}>{email}</Link>
              </Info>
              <Info label={t('phone')}>
                {!!customer?.billingAddress?.phone ? (
                  <Link href={`tel:${customer.billingAddress.phone}`}>{customer?.billingAddress?.phone}</Link>
                ) : (
                  '-'
                )}
              </Info>
              <div />
            </SectionContent>
          </Section>
        );
      }}
    />
  );
};

export default CustomerInfo;
