import { useTranslation } from 'react-i18next';

type GenderProps = {
  children: number;
};

const Gender = ({ children }: GenderProps): JSX.Element => {
  const { t } = useTranslation('common');
  if (children === 1) return <>{t('male')}</>;
  else if (children === 2) return <>{t('female')}</>;
  return <>{t('unknown')}</>;
};

export default Gender;
