import { useTranslation } from 'react-i18next';

type YesNoProps = {
  children: boolean;
};

const YesNo = ({ children }: YesNoProps): JSX.Element => {
  const { t } = useTranslation('common');
  if (children) return <>{t('yes')}</>;
  return <>{t('no')}</>;
};

export default YesNo;
