import { Box, Grid } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
  title?: string | undefined;
}

const SubFormSection = ({ children, title }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Grid container spacing={2}>
        {children}
      </Grid>
    </Box>
  );
};

export default SubFormSection;
