import { Controller, useFormContext } from 'react-hook-form';
import { CheckboxField } from '../ui-component';

interface Props {
  name: string;
  label: string;
  type?: string;
  readOnly?: boolean;
  required?: boolean;
}

const CheckboxInput = ({ label, name, type, readOnly, required }: Props) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <CheckboxField
            label={label}
            type={type}
            onChange={onChange}
            value={!!value}
            readOnly={readOnly}
            required={required}
          />
        );
      }}
    />
  );
};

export default CheckboxInput;
