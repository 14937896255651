import { Chip as MuiChip } from '@mui/material';

type Color = PredefinedColor | string;

type PredefinedColor = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

const _isPredefinedColor = (color: Color): color is PredefinedColor =>
  ['default', 'primary', 'secondary', 'error', 'info', 'success', 'warning'].includes(color);

const isPredefinedColor: (color: Color) => color is PredefinedColor = _isPredefinedColor;

interface Props {
  text: string;
  color: Color;
}

const Chip = ({ text, color }: Props) => {
  if (isPredefinedColor(color)) {
    return <MuiChip data-testid="predefined-color" label={text} color={color} />;
  }
  return <MuiChip style={{ backgroundColor: color }} data-testid="custom-color" label={text} />;
};

export default Chip;
