const FILE_EN = {
  add: 'Add',
  confirmOrderFileDelete: 'Confirm Delete File {{id}}',
  created: 'Created',
  customerId: 'CustomerId',
  id: 'Id',
  idRequired: 'ID Required',
  mdiId: 'MdiId',
  no: 'No',
  photos: 'Photos',
  picture: 'Picture',
  proveVerified: 'Prove Verified',
  s3Id: 'S3Id',
  search: 'Search',
  uploadFile: 'Upload Files',
  uploadLicense: 'Upload License',
  uploadOther: 'Upload Other',
  videoRequired: 'Video Required',
  yes: 'Yes',
};

export default FILE_EN;
