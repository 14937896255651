import { Divider, Grid, List, Paper, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BtnPrimary, Info } from '../common/ui-component';
import CustomerService from '../customer/customer.service';
import { map, withDefault } from '../utils/remote-data';
import { CustomerModel, OrderModel } from '../models';
import { Prescription } from './orders.service';

const MdiDetails = ({
  order,
  prescriptions,
  syncPrescriptions,
  syncMdi,
  caseId,
}: {
  order: OrderModel;
  caseId: string | null;
  syncMdi: ({ id }: { id: string }) => void;
  syncPrescriptions: ({ id }: { id: string }) => void;
  prescriptions: Prescription[];
}) => {
  const { t } = useTranslation('order');
  const [getCustomer, rCustomer] = CustomerService.useGetCustomer();
  const { customerId } = order;
  useEffect(() => {
    if (customerId) {
      getCustomer(customerId?.toString());
    }
  }, [getCustomer, customerId]);

  return (
    <Paper sx={{ padding: 2, marginBottom: 4 }} elevation={2}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        <div>
          <Typography component="h2" variant="h5">
            {t('mdiDetails')}
          </Typography>
        </div>
        <div>
          <div
            style={{
              display: 'none',
              flex: 1,
            }}
          >
            <BtnPrimary
              disabled={caseId === null}
              onClick={() => syncPrescriptions({ id: caseId as string })}
              sx={{ flex: 1, width: 239, marginRight: '14px' }}
            >
              {t('syncPrescriptions')}
            </BtnPrimary>
            <BtnPrimary
              disabled={caseId === null}
              onClick={() => syncMdi({ id: caseId as string })}
              sx={{ flex: 1, width: 239 }}
            >
              {t('syncMdi')}
            </BtnPrimary>
          </div>
        </div>
      </div>
      <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
      <Grid container spacing={2} columns={16} mt={1}>
        <Grid item xs={8}>
          <Info label={t('patientId')}>
            {withDefault('--')(map((customer: CustomerModel) => customer.patientId || '')(rCustomer))}
          </Info>
          <div style={{ marginTop: 20 }}>
            <Info label={t('latestCaseId')}>{order.caseId}</Info>
          </div>
          <div style={{ marginTop: 20 }}>
            <Info label={t('clinician')}>{order?.clinician?.fullName ?? '--'}</Info>
          </div>
        </Grid>

        <Grid item xs={8}>
          <Info label={t('prescriptions')} underlineLabel>
            <List>
              {prescriptions.map((p) => (
                <div key={p.dosespotPrescriptionId}>
                  <Typography>{p?.dosespotPrescriptionId}</Typography>
                  <Typography>{`${t('sku')} : ${p?.medication?.metadata}`}</Typography>
                  <Typography>{`${t('refills')} : ${p?.refillsCount?.toString()}`}</Typography>
                </div>
              ))}
            </List>
          </Info>
          <div style={{ marginTop: 20 }}>
            <Info label={t('mdiStatus')}>{order?.mdiStatus ?? '--'}</Info>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MdiDetails;
