import { useTranslation } from 'react-i18next';
import { DataTablePro as DataTable, Icons, Link } from '../common/ui-component';
import { OrderModel } from '../models';
import { GridColumns, GridRowParams } from '@mui/x-data-grid-pro';
import OrderStatusBadge from './OrderStatusBadge';
import { DateFormatter } from '../common/ui-component/data-table';
import FilterOperator from '../common/ui-component/data-table/filters/filter-operator-pro';
import { DefaultQueryParams } from '../utils/query';
import { Paginated } from '../common/Paginated';
import { RemoteData } from '../utils/remote-data';

const getActions = ({ id }: GridRowParams) => [
  <Link to={`/orders/${id}`}>
    <Icons.Edit />
  </Link>,
];

const getColumns = (t: (key: string) => string): GridColumns => [
  {
    field: 'status',
    headerName: t('status'),

    renderCell: (params) => {
      return <OrderStatusBadge status={params.value} />;
    },
  },
  {
    field: 'type',
    headerName: t('orderType'),
    filterOperators: FilterOperator.getGridSelectOperators(),
    valueOptions: [
      { value: 'Parent', label: t('parent') },
      { value: 'Renewal', label: t('renewal') },
      { value: 'One-Off', label: t('oneOff') },
    ],
  },
  {
    field: 'id',
    headerName: t('id'),
  },
  {
    field: 'woocommerceId',
    headerName: t('orderId'),
  },
  {
    field: 'pharmacyName',
    headerName: t('pharmacy'),
  },
  {
    field: 'category',
    headerName: t('category'),
    filterOperators: FilterOperator.getGridSelectOperators(),
    valueOptions: [
      { value: 'Rx', label: t('rx') },
      { value: 'OTC', label: t('otc') },
      { value: 'Both', label: t('both') },
      { value: 'Unknown', label: t('unknown') },
    ],
  },
  // {
  //   field: 'subscriptionIds',
  //   headerName: t('subscriptionId'),
  //   filterOperators: FilterOperator.getGridArrayOperators(),
  // },
  {
    field: 'customerId',
    headerName: t('customerId'),
  },
  {
    field: 'customerName',
    headerName: t('customerName'),
  },
  {
    field: 'email',
    headerName: t('email'),
  },
  {
    field: 'phone',
    headerName: t('phone'),
  },
  {
    field: 'caseId',
    headerName: t('caseId'),
  },
  {
    field: 'mdiStatus',
    headerName: t('mdiStatus'),
    filterOperators: FilterOperator.getStringOrEmptyOperators(),
  },
  {
    field: 'pharmacyStatus',
    headerName: t('pharmacyStatus'),
    filterOperators: FilterOperator.getStringOrEmptyOperators(),
  },
  {
    field: 'orderCreated',
    headerName: t('orderCreated'),

    filterOperators: FilterOperator.getGridDateOperators(),
    valueFormatter: DateFormatter.normalize,
  },
  {
    field: 'orderCreatedWithTime',
    headerName: t('orderCreatedWithTime'),
    flex: 1,
    headerAlign: 'center',
    valueFormatter: DateFormatter.withTime,
    sortable: false,
    filterable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    getActions,
  },
];
interface Props {
  listId: string;
  defaultParams?: DefaultQueryParams;
  getOrders: (params: unknown) => Promise<RemoteData<Paginated<OrderModel>, unknown>>;
  orders: RemoteData<Paginated<OrderModel>, unknown>;
  hasToolbar?: boolean;
  checkboxSelection?: boolean;
}

function OrderList({ listId, getOrders, orders, hasToolbar = true, checkboxSelection = true }: Props) {
  const { t } = useTranslation('order');

  return (
    <DataTable
      id={listId}
      cols={getColumns(t)}
      fetcher={getOrders}
      datas={orders}
      toRow={(order: OrderModel) => ({
        ...order,
        orderCreatedWithTime: order.orderCreated,
        clinicianFullName: order.clinician?.fullName,
      })}
      initialColumnVisibilityModel={{ orderCreatedWithTime: false }}
      hasToolbar={hasToolbar}
      checkboxSelection={checkboxSelection}
    />
  );
}

export default OrderList;
