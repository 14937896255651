import { Tabs, Tab, SxProps, Theme, AppBar, Toolbar, Box } from '@mui/material';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

const LinkTab = ({
  label,
  href,
  sx,
  hidden,
}: {
  sx?: SxProps<Theme> | undefined;
  label: string;
  href: string;
  hidden?: boolean;
}) => <Tab sx={{ ...sx, display: hidden ? 'none' : undefined }} component={Link} label={label} to={href} />;
interface Props {
  currentRoute: string;
  routes: { label: string; href: string; hidden?: boolean; subNavigation?: JSX.Element }[];
  logoutRoute: { label: string; href: string };
}

const Navbar = ({ currentRoute, routes, logoutRoute }: Props) => {
  const currentRouteIndex = useMemo(() => routes.map((x) => x.href).indexOf(currentRoute), [currentRoute, routes]);
  const subNavigation = routes[currentRouteIndex]?.subNavigation;
  return (
    <Box sx={{ height: subNavigation ? '112px' : '64px' }}>
      <AppBar position="fixed" color="primary" sx={{ height: '64px' }}>
        <Toolbar>
          <Tabs
            value={currentRouteIndex}
            aria-label="nav tabs example"
            variant="scrollable"
            indicatorColor="secondary"
            sx={{ flexGrow: 1 }}
          >
            {routes.map(({ label, href, hidden }) => {
              return <LinkTab key={href} label={label} href={href} hidden={hidden} />;
            })}
          </Tabs>
          <LinkTab label={logoutRoute.label} href={logoutRoute.href} />
        </Toolbar>
      </AppBar>
      {subNavigation && (
        <AppBar
          position="fixed"
          color="secondary"
          sx={{ top: '64px', height: '48px', paddingLeft: '10rem', color: '#305769' }}
        >
          {subNavigation}
        </AppBar>
      )}
    </Box>
  );
};

export default Navbar;
