import { useCallback, useContext } from 'react';
import Auth, { AuthPayload, AuthState } from '../auth';
import AccessTokenContext from './AccessTokenContext';

const useAccessToken = (): [AuthState, (user: AuthState) => void] => {
  const [accessToken, _setAccessToken] = useContext(AccessTokenContext);
  if (_setAccessToken == null) throw Error('AccessTokenProvider must be init before using the hook');

  const setAccessToken = useCallback(
    (auth: AuthState) => {
      Auth.fold(
        () => {
          localStorage.removeItem('jwt_token');
          localStorage.removeItem('is_admin');
          localStorage.removeItem('jwt_refresh');
        },
        (_accessToken: AuthPayload) => {
          if (_accessToken) {
            _accessToken.access_token && localStorage.setItem('jwt_token', _accessToken.access_token);
            _accessToken.is_admin && localStorage.setItem('is_admin', `${_accessToken.is_admin}`);
            _accessToken.refresh_token && localStorage.setItem('jwt_refresh', _accessToken.refresh_token);
          } else {
            localStorage.removeItem('jwt_refresh');
            localStorage.removeItem('jwt_token');
            localStorage.removeItem('is_admin');
          }
        },
      )(auth);

      _setAccessToken(auth);
    },
    [_setAccessToken],
  );
  return [accessToken, setAccessToken];
};

export default useAccessToken;
