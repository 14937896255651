import axios from 'axios';
import { CredentialResponse } from '@react-oauth/google';
import useService from '../common/useService';
import { AuthPayload } from './auth';

export type EmailPassword = {
  _name: 'emailPassword';
  email: string;
  password: string;
};

type Google = {
  _name: 'google';
  authCode: CredentialResponse['credential'];
};
export type LoginForm = EmailPassword | Google;

export type CodeForm = {
  twoFactorAuthenticationCode: string;
};

const _isGoogle = (ma: LoginForm): ma is Google => ma._name === 'google';

export const isGoogle: (rd: LoginForm) => rd is Google = _isGoogle;

const useAuthenticate = () =>
  useService((body: LoginForm) => {
    if (isGoogle(body))
      return axios.post<AuthPayload>('/auth/google', {
        auth_code: body.authCode,
      });
    return axios.post<AuthPayload>('/auth/login', body);
  });

const useRefresh = () =>
  useService((refreshToken: string) => {
    return axios.post<AuthPayload>('/auth/refresh', {
      refreshToken,
    });
  });

const useLogout = () => useService(() => axios.get('/logout'));

const AuthenticateService = {
  useAuthenticate,
  useLogout,
  useRefresh,
};

export default AuthenticateService;
