import { useTranslation } from 'react-i18next';
import { useAccessToken } from '../authentication/AccessTokenProvider';
import { BtnPrimary, Centered, ErrorHandler, Loader, Title } from '../common/ui-component';
import { getUser, loggedIn } from '../authentication/auth';
import AuthService from './auth.service';
import { useEffect, useState } from 'react';
import { ConnectedForm, TextInput } from '../common/form-controller';
import { CodeForm } from '../authentication/authenticate.service';
import { fold, isSuccess } from '../utils/remote-data';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent';
import { BtnDanger } from '../common/ui-component/buttons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { SEARCH_ROUTE } from '../Router';

const SecondFactor = () => {
  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const [token, setAccessToken] = useAccessToken();
  const tokenPayload = getUser(token);
  const [displayCode, setDisplayCode] = useState<boolean>(
    (tokenPayload && tokenPayload?.isTwoFactorAuthenticationEnabled) || false,
  );
  const [get2faQrCode, rQrCode] = AuthService.useGet2faQrCode();
  const [validate2faCode, rAuthPayload] = AuthService.useValidate2faCode();
  const [turnOn2fa] = AuthService.useTurnOn2fa();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo');

  useEffect(() => {
    if (!displayCode) {
      get2faQrCode();
    }
  }, [get2faQrCode, displayCode]);

  useEffect(() => {
    if (isSuccess(rAuthPayload)) {
      setAccessToken(loggedIn(rAuthPayload.value));

      navigate(redirectTo || SEARCH_ROUTE);
    }
  }, [rAuthPayload, navigate, setAccessToken, redirectTo]);

  const defaultCodeForm: CodeForm = {
    twoFactorAuthenticationCode: '',
  };

  const submit = async (codeForm: CodeForm) => {
    if (tokenPayload && !tokenPayload.isTwoFactorAuthenticationEnabled) {
      await turnOn2fa(codeForm);
    }

    validate2faCode(codeForm);
  };

  function showQrCode() {
    return (
      <RemoteDataContent
        value={rQrCode}
        whenSuccess={(qrCode: string) => {
          return (
            <>
              <Title>{t('secondFactorTitle')}</Title>
              <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                <Grid item xs={3}>
                  <img src={qrCode.replace('"', '')} alt="qrcode" />
                </Grid>
              </Grid>
              <div>{t('secondFactorText')}</div>
              <BtnPrimary onClick={() => setDisplayCode(true)}>{t('next')}</BtnPrimary>
            </>
          );
        }}
      />
    );
  }

  function showCodeForm(e?: Error) {
    return (
      <ConnectedForm<CodeForm> defaultValues={defaultCodeForm} onSubmit={submit}>
        <Title>{t('secondFactorCodeTitle')}</Title>
        <ErrorHandler err={e} />
        <TextInput name="twoFactorAuthenticationCode" label={t('twoFactorAuthenticationCode')} />
        <BtnPrimary type="submit">{t('signIn')}</BtnPrimary>
        <BtnDanger onClick={() => setDisplayCode(false)}>{t('back')}</BtnDanger>
      </ConnectedForm>
    );
  }

  return (
    <Centered>
      {!displayCode
        ? showQrCode()
        : fold(
            () => showCodeForm(),
            () => <Loader />,
            () => <span>{t('successLogin')}</span>,
            (e) => showCodeForm(e as Error),
          )(rAuthPayload)}
    </Centered>
  );
};

export default SecondFactor;
