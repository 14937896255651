import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FormRow,
  Info,
  Section,
  SectionContent,
  FormSection,
  Header,
  BtnPrimary,
  BtnSuccess,
  Link,
} from '../common/ui-component';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent';
import DeadLetterService from './dead-letter.service';
import { TFunction, useTranslation } from 'react-i18next';
import { ConnectedForm, TextInput } from '../common/form-controller';
import { ObjectHelper } from '../utils/object';
import { splitAtEvery } from '../utils/array';
import { DeadLetterModel } from './dead-letter.model';
import { Alert, FormControl, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import { isSuccess } from '../utils/remote-data';
import { DEAD_LETTER_ROUTE } from '../Router';
import { RoutedModal } from '../common/ui-component/modals';
import FixClinicianMissing from './FixClinicianMissing';

const missingClinicianInformationHash = '#missingClinicianInformation';

const knowsIssueItems = <T,>(
  deadLetter: DeadLetterModel<T>,
  currentRoute: string,
  t: TFunction<'deadLetter', undefined>,
) => {
  const items = [];
  if (deadLetter.context === 'PharmacyOrderMapperConsumerController')
    items.push(
      <MenuItem key={t('missingClinicianInformation') + ` ${deadLetter.id}`}>
        <Link to={`${currentRoute}/${missingClinicianInformationHash}`}>{t('missingClinicianInformation')}</Link>
      </MenuItem>,
    );

  if (items.length) return items;
  return [<MenuItem key={t('noResolver') + ` ${deadLetter.id}`}>{t('noResolver')}</MenuItem>];
};

const DeadLetter = <T extends object>() => {
  const { id } = useParams();
  const currentRoute = `${DEAD_LETTER_ROUTE}/${id}`;
  const [getDeadLetter, rDeadLetter] = DeadLetterService.useGetDeadLetter<T>();
  const [updateDeadLetter, syncResponse] = DeadLetterService.useUpdateDeadLetter();

  const navigate = useNavigate();

  const [retry, retryResult] = DeadLetterService.useRetryDeadLetter();

  useEffect(() => {
    if (isSuccess(retryResult)) navigate(DEAD_LETTER_ROUTE);
  }, [retryResult, navigate]);

  const { t } = useTranslation('deadLetter');

  useEffect(() => {
    getDeadLetter(id);
  }, [id, getDeadLetter]);

  const onSubmit = (defaultDeadLetter: DeadLetterModel<T>) => (data: T) => {
    if (id) {
      updateDeadLetter({
        id,
        data: {
          ...defaultDeadLetter,
          queueMessage: { ...defaultDeadLetter.queueMessage, value: data },
        },
      });
    }
  };

  return (
    <>
      <RemoteDataContent
        value={rDeadLetter}
        whenSuccess={(deadLetter) => {
          const queueMessageValue =
            'value' in deadLetter.queueMessage ? deadLetter.queueMessage.value : deadLetter.queueMessage;

          return (
            <div>
              <Header title={''} details={[]}>
                <BtnSuccess type="submit" form="hook-form">
                  {t('save')}
                </BtnSuccess>
                <BtnPrimary onClick={() => retry(deadLetter.id)}>{t('syncAndExit')}</BtnPrimary>
                <FormControl fullWidth>
                  <InputLabel id="resolving-label-id">{t('ResolvingWith')}</InputLabel>
                  <Select labelId="resolving-label-id" label={t('ResolvingWith')} value={''}>
                    {knowsIssueItems(deadLetter, currentRoute, t)}
                  </Select>
                </FormControl>
              </Header>

              <Section title={t('deadLetter')}>
                <SectionContent>
                  <Info label={t('context')}>{deadLetter.context}</Info>
                  {/* <Info label="context">{deadLetter.id.toString()}</Info> */}
                  <Info label={t('queueKey')}>{deadLetter.queueKey}</Info>
                </SectionContent>
              </Section>
              <Section title={t('error')}>
                {typeof deadLetter.error === 'string' ? (
                  <span style={{ wordBreak: 'break-word' }}>{deadLetter.error}</span>
                ) : (
                  <pre style={{ wordBreak: 'break-word' }}>
                    {JSON.stringify(JSON.parse(deadLetter.error), undefined, 2)}
                  </pre>
                )}
              </Section>
              <ConnectedForm<T>
                id="hook-form"
                onSubmit={onSubmit(deadLetter) as never}
                defaultValues={queueMessageValue as never}
              >
                <FormSection title={t('details')}>
                  <FormSection.Grid>
                    {splitAtEvery(3, ObjectHelper.paths(queueMessageValue)).map((paths: string[], index: number) => (
                      <FormRow key={index}>
                        {paths.map((path: string) => (
                          <FormRow.Field md={2}>
                            <TextInput
                              key={
                                'value' in deadLetter.queueMessage
                                  ? `queueMessage.value.${path}`
                                  : `queueMessage.${path}`
                              }
                              name={path}
                              label={path}
                            />
                          </FormRow.Field>
                        ))}
                      </FormRow>
                    ))}
                  </FormSection.Grid>
                </FormSection>
              </ConnectedForm>
              <Section title={t('json')}>
                <pre>
                  <code>{JSON.stringify(queueMessageValue, undefined, 2)}</code>
                </pre>
              </Section>
              <RemoteDataContent
                value={syncResponse}
                whenSuccess={() => (
                  <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <Alert variant="filled" severity="success">
                      {t('success')}
                    </Alert>
                  </Snackbar>
                )}
                whenNotAsked={() => <></>}
              />
              <RoutedModal
                title={t('missingClinicianInformation')}
                predicatedHash={missingClinicianInformationHash}
                defaultUrl={currentRoute}
              >
                <FixClinicianMissing
                  deadLetter={deadLetter}
                  orderId={(queueMessageValue as { order: { id: string } }).order?.id}
                />
              </RoutedModal>
            </div>
          );
        }}
      />
    </>
  );
};

export default DeadLetter;
