import { useTranslation } from 'react-i18next';
import { isSuccess, notAsked } from '../utils/remote-data';
import { BtnPrimary, BtnSuccess, Header, Link } from '../common/ui-component';
import DeadLetterService from './dead-letter.service';
import SyncDeadLetterService from './SyncDeadLetter/sync-dead-letter.service';
import RoutedModal from '../common/ui-component/modals/RoutedModal';
import { DEAD_LETTER_ROUTE } from '../Router';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent';
import { SyncResponse } from './SyncDeadLetter';
import DeadLetterList from './DeadLetterList';

const DeadLetters = () => {
  const { t } = useTranslation('deadLetter');

  const responseModelHash = '#response';

  const [getDeadLetters, deadLetters] = DeadLetterService.useGetDeadLettersV2();

  const [syncCase, syncCaseResponse, setSyncCaseAs] = SyncDeadLetterService.useSyncCase();

  const [syncPatient, syncPatientResponse, setPatientCaseAs] = SyncDeadLetterService.useSyncPatient();

  const handleAnyRetryAll = (fn: () => void) => () => {
    setSyncCaseAs(notAsked());
    setPatientCaseAs(notAsked());
    fn();
  };

  return (
    <>
      <Header title={t('deadLetter')}>
        {isSuccess(syncCaseResponse) || isSuccess(syncPatientResponse) ? (
          <div style={{ marginRight: '1rem' }}>
            <Link to={`${DEAD_LETTER_ROUTE}${responseModelHash}`} replace>
              <BtnSuccess>{t('displayFailure')}</BtnSuccess>
            </Link>
          </div>
        ) : undefined}
        <div style={{ marginRight: '1rem' }}>
          <Link to={`${DEAD_LETTER_ROUTE}${responseModelHash}`} replace>
            <BtnPrimary onClick={handleAnyRetryAll(syncCase)}>{t('retryAll', { value: 'mdi.case.update' })}</BtnPrimary>
          </Link>
        </div>
        <div>
          <Link to={`${DEAD_LETTER_ROUTE}${responseModelHash}`} replace>
            <BtnPrimary onClick={handleAnyRetryAll(syncPatient)}>
              {t('retryAll', { value: 'mdi.patient.update' })}
            </BtnPrimary>
          </Link>
        </div>
      </Header>
      <DeadLetterList getDeadLetters={getDeadLetters} deadLetters={deadLetters} />
      <RoutedModal predicatedHash={responseModelHash} title={'Retry'} defaultUrl={DEAD_LETTER_ROUTE}>
        <>
          <RemoteDataContent
            value={isSuccess(syncCaseResponse) ? syncCaseResponse : syncPatientResponse}
            whenSuccess={(r) => <SyncResponse response={r} />}
          />
        </>
      </RoutedModal>
    </>
  );
};

export default DeadLetters;
