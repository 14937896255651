import { useTranslation } from 'react-i18next';
import { Info, SectionContent } from '../ui-component';

type AddressType = {
  address1: string;
  address2: string;
  city: string;
  country: string;
  state: string;
  postcode: string;
  phone: string;
};

const Address = ({ address }: { address: AddressType }) => {
  const { t } = useTranslation('address');
  return (
    <>
      <SectionContent>
        <Info label={t('address_1')}>{address.address1}</Info>
        <Info label={t('address_2')}>{address.address2}</Info>
      </SectionContent>
      <SectionContent>
        <Info label={t('city')}>{address.city}</Info>
        <Info label={t('country')}>{address.country}</Info>
        <Info label={t('state')}>{address.state}</Info>
        <Info label={t('postcode')}>{address.postcode}</Info>
      </SectionContent>
      <SectionContent>
        <Info label={t('phone')}>{address.phone}</Info>
      </SectionContent>
    </>
  );
};

export default Address;
