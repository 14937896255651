import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedForm, DropdownInput, TextInput } from '../../common/form-controller';
import { map, withDefault } from '../../utils/remote-data';
import { BtnDanger, BtnPrimary, FormRow, FormSection } from '../../common/ui-component';
import SubscriptionsService from '../subscriptions.service';
import { SubscriptionModel } from '../../models';

interface Props {
  subscription: SubscriptionModel | undefined;
  onSuccess: (subscription: SubscriptionModel) => void;
  close: () => void;
}

const BillingIntervalForm = ({ subscription, onSuccess, close }: Props) => {
  const { t } = useTranslation(['subscription', 'common']);
  const [updateSubscription, rSubscription] = SubscriptionsService.useUpdateSubscription();
  const onSubmit = useCallback(
    (subscriptionModel: SubscriptionModel, id: number) => {
      updateSubscription({ id, subscription: subscriptionModel });
    },
    [updateSubscription],
  );
  const billingPeriod = ['day', 'week', 'month', 'year'];
  const billingInterval = ['1', '2', '3', '4', '5', '6', '25', '26', '50', '75', '100', '125', '150'];

  useEffect(() => {
    const nothing = () => {};
    const rFn = map((s: SubscriptionModel) => () => onSuccess(s))(rSubscription);
    const fn = withDefault(nothing)(rFn);
    fn();
  }, [rSubscription, onSuccess]);

  return !subscription ? (
    <>
      <p>{t('common:weird')}</p>
      <BtnDanger sx={{ m: 0 }} onClick={close}>
        {t('subscription:close')}
      </BtnDanger>
    </>
  ) : (
    <ConnectedForm<SubscriptionModel>
      defaultValues={{
        id: subscription?.id,
        billingInterval: subscription?.billingInterval,
        billingPeriod: subscription?.billingPeriod,
      }}
      onSubmit={(formValue) => onSubmit(formValue, subscription.id)}
    >
      <FormSection title={t('subscription:billingDetails')}>
        <FormRow>
          <TextInput readOnly name="id" label={t('subscription:subscriptionId')} />
        </FormRow>
        <FormRow>
          <DropdownInput
            name="billingInterval"
            label={t('subscription:billingInterval')}
            items={billingInterval}
            toOption={(x) => ({
              displayText: x,
              value: x,
              id: x,
            })}
          />
          <DropdownInput
            name="billingPeriod"
            label={t('subscription:billingPeriod')}
            items={billingPeriod}
            toOption={(x) => ({
              displayText: x,
              value: x,
              id: x,
            })}
          />
        </FormRow>
      </FormSection>
      <BtnPrimary sx={{ m: 0 }} type="submit">
        {t('subscription:send')}
      </BtnPrimary>
    </ConnectedForm>
  );
};

export default BillingIntervalForm;
