import axios from 'axios';
import { OrderFileType } from './order-file-type.type';
import { OrderFile } from './order-file.model';
import useService from '../common/useService';
import { Paginated } from '../common/Paginated';
import { OrderFileModel } from '../models';

const useOrderFiles = () =>
  useService((woocommerceId: string | number) => axios.get<OrderFile[]>(`/order-files/${woocommerceId}`));

const useOrderFilesByCustomerId = () =>
  useService((customerId: string | number) => axios.get<OrderFile[]>(`/order-files/by-customer/${customerId}`));

const useDeletePhoto = () => useService((fileId: string | number) => axios.delete(`/order-files/files/${fileId}`));

const useUploadPictures = () =>
  useService((data: { woocommerceId: number; woocommerceCustomerId: string; type: OrderFileType; files: File[] }) => {
    const formData = new FormData();
    formData.append('type', data.type);
    formData.append('woocommerceCustomerId', data.woocommerceCustomerId);

    data.files.forEach((file) => {
      formData.append('file', file);
    });

    return axios.post<OrderFile[]>(`/order-files/${data.woocommerceId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  });

const useUploadPicturesByCustomer = () =>
  useService((data: { customerId: number; type: OrderFileType; files: File[] }) => {
    const formData = new FormData();
    formData.append('type', data.type);

    data.files.forEach((file) => {
      formData.append('file', file);
    });

    return axios.post<OrderFile[]>(`/order-files/by-customer/${data.customerId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  });

const useGetSignedUrls = () =>
  useService((ids: string[]) =>
    axios.get<{ id: string; url: string }[]>(`/order-files/signed-urls`, {
      params: { ids },
    }),
  );

const useSyncPhoto = () => useService((id: string | number) => axios.post(`/order-files/sync/${id}`));

const useUpdate = () =>
  useService((body: { id: number; customerId: number }) =>
    axios.put<OrderFileModel>(`/order-files/${body.id}`, {
      customerId: body.customerId,
    }),
  );

const useSearch = () =>
  useService((params: { customerId: number; orderIds: number[] }) =>
    axios.get<Paginated<OrderFileModel>>(`/order-files`, {
      params: {
        linkOperator: 'or',
        queries: [
          {
            customerId: [
              { operator: 'equals', value: params.customerId },
              { operator: 'equals', value: 0 },
            ],
          },
        ],
      },
    }),
  );

const useSyncPhotosByCustomerId = () =>
  useService((id: string | number) => axios.post(`/order-files/sync-by-customer/${id}`));

const OrderFileService = {
  useSearch,
  useOrderFiles,
  useOrderFilesByCustomerId,
  useUploadPicturesByCustomer,
  useDeletePhoto,
  useUploadPictures,
  useGetSignedUrls,
  useSyncPhoto,
  useSyncPhotosByCustomerId,
  useUpdate,
};

export default OrderFileService;
