import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SUBSCRIPTIONS_ROUTE } from '../Router';
import { useEffect, useState } from 'react';
import { BtnPrimary, Header, Section, SuccessRemoteDataToast } from '../common/ui-component';
import { Box, Container, Grid, List, ListItem, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent';
import SubscriptionsService from './subscriptions.service';
import CustomerInfo from './CustomerInfo';
import SubscriptionDetails from './SubscriptionDetails';
import MdiDetails from './MdiDetails';
import OrdersService from '../orders/orders.service';
import SubscriptionStatusService from '../subscription-status/subscription-status.service';
import { notAsked, unwrap } from '../utils/remote-data';
import { ConfirmRoutedModal } from '../common/ui-component/modals';
import MedicalCaseService from '../medical-case/medical-case.service';
import { SubscriptionModel, SubscriptionStatusEnum } from '../models';
import { FeatureToggleHelper } from '../utils/feature-toggle';
import SubscriptionStatusBadge from './SubscriptionStatusBadge';

const SubscriptionStatusChange = ({
  subscriptionStatus,
  status,
  setSubscriptionStatus,
  onSubmit,
}: {
  subscriptionStatus: SubscriptionStatusEnum | undefined;
  status: SubscriptionModel['status'];
  setSubscriptionStatus: (s: SubscriptionModel['status']) => void;
  onSubmit: () => void;
}) => {
  const { t } = useTranslation('subscription');
  return (
    <>
      <Box>
        <Typography mt={1}>{t('status')}</Typography>
        <Select
          labelId="simple-select-label"
          id="simple-select"
          sx={{ height: '36px', width: '309px' }}
          value={subscriptionStatus ?? status}
          onChange={(e) => setSubscriptionStatus(e.target.value as SubscriptionStatusEnum)}
        >
          {[
            'active',
            'pending-photos',
            'awaiting-doctor-review',
            'on-hold',
            'pending-cancel',
            'active-action-required',
            'paused-action_required',
          ].map((option: string) => {
            return (
              <MenuItem key={option.toString()} value={option.toString()}>
                {option === 'pending-cancel'
                  ? 'Pending cancelation'
                  : option === 'pending-photos'
                  ? 'Paused - Pending photos'
                  : option === 'paused-action_required'
                  ? 'Paused - Action Required'
                  : option === 'on-hold'
                  ? 'On Hold'
                  : option === 'active'
                  ? 'Active'
                  : option === 'awaiting-doctor-review'
                  ? 'Awaiting Doctor Review'
                  : option}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      <BtnPrimary
        type="button"
        disabled={subscriptionStatus === null}
        sx={{
          marginLeft: 4,
          marginTop: 3.5,
        }}
        fullWidth={false}
        onClick={onSubmit}
      >
        {'Submit'}
      </BtnPrimary>
    </>
  );
};

function Subscription() {
  const { t } = useTranslation('subscription');

  const { id } = useParams();
  const navigate = useNavigate();
  const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatusEnum>();

  const updateStatus = '#status';
  const syncSubscriptionHash = '#sync';

  const [getOrders, rOrders] = OrdersService.useGetOrdersV2();

  const [getSubscription, rSubscription] = SubscriptionsService.useGetSubscription();

  const [getSubscriptionStatuses, rSubscriptionStatuses] = SubscriptionStatusService.useGetSubscriptionStatuses();

  const [updateSubscriptionStatus, rUpdadateSubscriptionStatus, setUpdateSubscriptionStatus] =
    SubscriptionsService.useUpdateSubscription();

  const [getMedicalCase, rMedicalCase] = MedicalCaseService.useGetMedicalCaseByCustomerId();

  const [syncWithWoo, rSyncWithWoo, setSyncWithWooResult] = SubscriptionsService.useSyncSubscriptionWithWoo();

  useEffect(() => {
    (async () => {
      const rBackofficeSubscription = await getSubscription(id);
      const customerId = unwrap((s: SubscriptionModel) => s.customerId)(rBackofficeSubscription);
      if (customerId) {
        getMedicalCase(customerId);
      }
      getSubscriptionStatuses(id);
      getOrders({
        pageNumber: 0,
        pageSize: 100,
        queries: { customerId: [{ value: customerId, operator: 'equals' }] },
      });
    })();
  }, [id, getSubscription, getSubscriptionStatuses, getOrders, getMedicalCase]);

  const currentRoute = `${SUBSCRIPTIONS_ROUTE}/${id}`;

  return (
    <Container maxWidth="xl">
      <RemoteDataContent
        value={rSubscription}
        whenSuccess={(subscription: SubscriptionModel) => (
          <>
            <Header
              title={
                <div>
                  {`${t('subscription')} #${subscription.id}`} <SubscriptionStatusBadge status={subscription.status} />
                </div>
              }
              details={
                <span style={{ marginRight: '1rem' }} key={id}>
                  {subscription.customerName}
                </span>
              }
            >
              {FeatureToggleHelper.isEnable(process.env.REACT_APP_SUBSCRIPTION_STATUS_TOGGLER) && (
                <SubscriptionStatusChange
                  status={subscription.status}
                  setSubscriptionStatus={setSubscriptionStatus}
                  onSubmit={() => navigate(`${currentRoute}${updateStatus}`)}
                  subscriptionStatus={subscriptionStatus}
                />
              )}
            </Header>
            <Grid container spacing={2} maxWidth="xl">
              <Grid item xs={8}>
                <RemoteDataContent
                  value={rOrders}
                  whenSuccess={(allOrders) => (
                    <>
                      <SubscriptionDetails
                        subscription={subscription}
                        rMedicalCase={rMedicalCase}
                        rOrders={rOrders}
                        syncModalHref={`${currentRoute}${syncSubscriptionHash}`}
                      />
                      {subscription.customerId && <CustomerInfo customerId={subscription.customerId} />}
                      {allOrders.data.length > 0
                        ? subscription.customerId && (
                            <MdiDetails orders={allOrders} customerId={subscription.customerId} />
                          )
                        : subscription.customerId && <MdiDetails customerId={subscription.customerId} />}
                    </>
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Section title={t('statusHistory')}>
                  <RemoteDataContent
                    value={rSubscriptionStatuses}
                    whenSuccess={(statuses) => {
                      return (
                        <List
                          sx={{
                            height: 685,
                            overflowY: 'scroll',
                            width: '100%',
                          }}
                        >
                          {statuses?.map((status) => (
                            <ListItem key={status.id}>
                              <ListItemText
                                primary={status.status}
                                secondary={
                                  <span>
                                    <span style={{ display: 'block' }}>{`Source : ${status.source}`}</span>
                                    <span style={{ display: 'block' }}>{`Date : ${status.created}`}</span>
                                  </span>
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      );
                    }}
                  />
                </Section>
              </Grid>
            </Grid>

            <ConfirmRoutedModal
              predicatedHash={updateStatus}
              defaultUrl={currentRoute}
              title={t('updateStatusConfirmation')}
              value={subscription}
              onConfirm={(value, close) => {
                updateSubscriptionStatus({
                  id: value.id.toString(),
                  subscription: {
                    ...value,
                    status: subscriptionStatus as SubscriptionStatusEnum,
                  },
                });
                close();
              }}
            ></ConfirmRoutedModal>
            <ConfirmRoutedModal
              predicatedHash={syncSubscriptionHash}
              defaultUrl={currentRoute}
              title={t('syncSubscriptionWithWoo')}
              value="a"
              onConfirm={async (value, close) => {
                await syncWithWoo(subscription.id);
                close();
              }}
            ></ConfirmRoutedModal>
            <SuccessRemoteDataToast
              value={rUpdadateSubscriptionStatus}
              onClick={() => {
                setUpdateSubscriptionStatus(notAsked());
              }}
            ></SuccessRemoteDataToast>
            <SuccessRemoteDataToast
              value={rSyncWithWoo}
              onClick={() => {
                setSyncWithWooResult(notAsked());
              }}
            ></SuccessRemoteDataToast>
          </>
        )}
      />
    </Container>
  );
}

export default Subscription;
