type FilterOperator = 'contains' | 'equals' | 'is' | 'has' | 'null' | string | undefined;

export type ServerOperator = 'contains' | 'equals' | 'is';

type Query = { [key: string]: { value: string | number | null | undefined; operator: ServerOperator }[] };

const apply = ({
  columnField,
  value,
  operatorValue,
}: {
  columnField: string;
  value: string;
  operatorValue: FilterOperator;
}): Query => {
  switch (operatorValue) {
    case 'has': {
      if (value === '') {
        return { [columnField]: [] };
      } else if (value === 'true') {
        return { [columnField]: [{ value: '[{%]', operator: 'contains' }] };
      } else {
        return {
          [columnField]: [
            { value: '[]', operator: 'contains' },
            { value: null, operator: 'is' },
          ],
        };
      }
    }

    case 'null': {
      if (value === '') return { [columnField]: [] };
      else if (value === 'true') return { [columnField]: [{ value: '', operator: 'contains' }] };
      else return { [columnField]: [{ value: null, operator: 'is' }] };
    }

    case 'object': {
      if (value === '') {
        return { [columnField]: [] };
      } else if (value === 'true') {
        return { [columnField]: [{ value: '{"', operator: 'contains' }] };
      } else {
        return {
          [columnField]: [
            { value: '[]', operator: 'contains' },
            { value: '{}', operator: 'contains' },
            { value: null, operator: 'is' },
          ],
        };
      }
    }
    default:
      if (operatorValue) return { [columnField]: [{ value, operator: operatorValue as ServerOperator }] };
      else return { [columnField]: [] };
  }
};

export default apply;
