import { Grid } from '@mui/material';
import React, { Children, ReactNode } from 'react';

interface FieldProps {
  children?: ReactNode;
  md: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

const isFieldType = (node: ReactNode) => {
  if (typeof node === 'boolean') return false;
  if (typeof node === 'number') return false;
  if (typeof node === 'string') return false;
  if (node && 'type' in node) return node.type === Field;

  return false;
};

export const Field = ({ children, md }: FieldProps) => {
  return (
    <Grid item xs={12} md={md}>
      {children}
    </Grid>
  );
};

interface Props {
  children?: ReactNode;
  md?: number;
}

const FormRow = ({ children, md: overrideMd }: Props) => {
  if (children === undefined) throw new Error('FormRow need to receive at least one child');
  if (isFieldType(children)) return <>{children}</>;

  const a = Children.toArray(children);
  if (a.every(isFieldType)) {
    return <>{children}</>;
  }

  const count = Children.count(children);
  const md = () => {
    if (overrideMd) return overrideMd;

    if (count === 1) return 12;
    else if (count === 2) return 6;
    else if (count === 3) return 4;
    else if (count === 4) return 3;
    else if (count === 5) return 2.4;
    else if (count <= 6) return 2;
    throw new Error('FormRow cannot get more than 6 children');
  };

  const render = Children.map(children, (child, index) => (
    <Grid data-testid={`checking-width-${index}`} item xs={12} md={md()}>
      {child}
    </Grid>
  ));
  if (render === undefined) throw new Error('FormRow need to receive at least one child');
  return <>{render}</>;
};

FormRow.Field = Field;

export default FormRow;
